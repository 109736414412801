import React from "react";

const TrialComponent = () => {
    return (
        <div className="body text-warning">
                  <div className="text-center">
                    <h4 className="card-title">Trial Version</h4>
                  </div>
                  <p className="card-text">
                   Please click below button to upgrade your current plan to get the complete results.
                  </p>
                  <div className="text-center">
                    <a
                      href="/"
                      className="btn btn-info btn-round"
                      style={{ background: "#6574cd" }}
                    >
                      Upgrade Now
                    </a>
                  </div>
                </div>
    )
}

export default TrialComponent;