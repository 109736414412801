const initialState = {
  data: [],
};

const bucketDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BUCKET_DETAILS":
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default bucketDetails;
