import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport } from "../../actions/summary";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import Splinechart from "../common/splinechart";

var Model = require("./model");

const BrandDashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [riskScoreVA, setRiskScoreVa] = useState("");
  const [riskScoreExpiredSSL, setRiskScoreExpiredSSL] = useState("");
  const [riskScoreVulnerableSSL, setRiskScoreVulnerableSSL] = useState("");
  const [riskScoreDangling, setRiskScoreDangling] = useState("");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (id) fetchDashboardReport(id, "Brand", dispatch);
    else fetchDashboardReport(id, "Brand", dispatch);
  }, [id]);

  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  let summaryLoading = useSelector((state) => state?.summary?.loading);

  let securityGraphValue = summary?.Rating?.map(
    (row) => row?.brandSecuritySecurityImpact
  );
  let securityGraphDate = summary?.Rating?.map((row) =>
    moment(row?.createdAt).format("DD-MM-YYYY")
  );

  const generateReport = () => {
    if (loading) {
      return false;
    }
    setLoading(true);
    let data = {
      id: id,
      asm: "brand",
    };
    Model.default.generateReport(data).then((response) => {
      setLoading(false);
      if (!response.error) {
        window.open(response.data.docx);
      }
    });
  };

  const getArrow = (val) => {
    if (val > 0) return "fa fa-arrow-up fa-1x arrowRed";
    else return "fa fa-arrow-down fa-1x arrowGreen";
  };
  // End of generate grade for danglong domains

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      {(summaryLoading || loading) && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Summary</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Brand Security Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              style={{   fontWeight: "bold" }}
              onClick={() => generateReport()}
            >
              <i className="fa fa-file mr-1" style={{ }}></i>
              <span
                style={{   fontStyle: "bold" }}
              >
                Download Report
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-9">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "364px" }}>
              <div className="row">
                <div className="col-lg-12">
                  <h6>Brand Security Health Tracker</h6>
                  <div className="card">
                    {(securityGraphValue && securityGraphValue[0]) &&  (
                     
                      <Splinechart
                        value={securityGraphValue}
                        date={securityGraphDate}
                        name={"Brand Security Health Score"}
                        color="#DD3731"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card card-box mr-0">
            <div className="body" style={{   }}>
              <h6>Top Issues</h6>
              <div className="list-group">
                {summary?.issueArray?.map((row) => {
                  return (
                    <a
                      href={row?.link}
                      className="list-group-item list-group-item-action"
                      style={{  }}
                    >
                      {row?.msg}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {id && (
        <>
          <div className="row clearfix">
            <div className="col-sm-3">
              <span className="card  card-box  ">
                <Link
                  to={`/look-alike${vendorId ? "/vendor/" + vendorId : ""}`}
                >
                  
                  <div className="d-flex justify-content-between">
                <i className="fa fa-laptop icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {summary?.DomainSquatting || 0}
                </h4>
              </div>
              <label className="mb-0 text-right">Look-alike Domains</label>

              <div className="card-footer">
                 
                <span className="btn btn-border-footer"
                      >{!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.similarDomainNewAdded &&
                          summary?.similarDomainNewAdded !== 0 ? (
                            <i
                              className={getArrow(
                                summary?.similarDomainNewAdded
                              )}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.similarDomainNewAdded || 0)}{" "}
                          v/s last scan
                        </span>
                      ) : (
                        ""
                      )}
                      </span>
                 
              </div>
                   
                </Link>
              </span>
            </div>
            <div className="col-sm-3">
              <span className="card  card-box ">
                <Link
                  to={`/mobile-apps${vendorId ? "/vendor/" + vendorId : ""}`}
                >
                  <div className="d-flex justify-content-between">
                <i className="fa fa-mobile icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {summary?.FakeApps || 0}
                </h4>
              </div>
              <label className="mb-0 text-right">Rogue Mobile Applications</label>

              <div className="card-footer">
                 
                <span className="btn btn-border-footer">
                  {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.mobileApplicationNewAdded &&
                          summary?.mobileApplicationNewAdded !== 0 ? (
                            <i
                              className={getArrow(
                                summary?.mobileApplicationNewAdded
                              )}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(
                            summary?.mobileApplicationNewAdded || 0
                          )}{" "}
                          v/s last scan
                        </span>
                      ) : (
                        ""
                      )}
                      </span>
                 
              </div>
 
                </Link>
              </span>
            </div>
            <div className="col-sm-3">
              <span className="card  card-box ">
                <Link
                  to={`/social-media${vendorId ? "/vendor/" + vendorId : ""}`}
                >

<div className="d-flex justify-content-between">
                <i className="fa fa-facebook icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {summary?.SocialMedia || 0}
                </h4>
              </div>
              <label className="mb-0 text-right">Social Media Profiles</label>

              <div className="card-footer">
                 
                <span className="btn btn-border-footer">
                  {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {/* <i className="fa fa-arrow-up fa-1x"
                      style={{ color: "#ff0000",fontSize:"20px" }}></i>    */}
                          {0} v/s last scan
                        </span>
                      ) : (
                        ""
                      )}
                      </span>
                 
              </div>
 
                </Link>
              </span>
            </div>
            <div className="col-sm-3">
              <span className="card  card-box mr-0">
                <Link
                  to={`/brand-mentions${vendorId ? "/vendor/" + vendorId : ""}`}
                >
                  <div className="d-flex justify-content-between">
                <i className="fa fa-usb icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {summary?.brandMention || 0}
                </h4>
              </div>
              <label className="mb-0 text-right">Brand Mentions</label>

              <div className="card-footer">
                 
                <span className="btn btn-border-footer">
                {!(summaryLoading || loading) ? (
                            <span className="dashboardFirstHeading">
                              {/* {summary?.webTechNewAdded && summary?.webTechNewAdded !== 0 ? (
                                <i className={getArrow(summary?.webTechNewAdded)}></i>
                              ) : ""} */}
                              {Math.abs(0 || 0)} v/s last scan
                            </span>
                          ) : (
                            ""
                          )}
                      </span>
                 
              </div>

                  
                </Link>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default BrandDashboard;
