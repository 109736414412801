import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDnsDetails } from "../../actions/sub-domain";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import { Link, useParams } from "react-router-dom";

const Domain = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(20);

  const [filterData, setfilterData] = useState("all");
  const [domainFilter, setDomainFilter] = useState("all");
  const [search, setSearch] = useState("");

  const queryParameters = new URLSearchParams(window.location.search)
  const latest = queryParameters.get("latest");
  //console.log(latest,'props.location.search')

  if (latest && filterData == "all")
    setfilterData("Latest")

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});  let dnsDetailsArray = useSelector(
    (state) => state.subDomain?.dnsDetails?.data
  );
  let oldDnsDetails = useSelector(
    (state) => state.subDomain?.dnsDetails?.scanDetailArray
  );

  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }
  let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

  //console.log(dnsDetailsArray, "result value");

  useEffect(() => {
    if(orgId){
      getDnsDetails(orgId, false, dispatch);
    }else getDnsDetails(id, false, dispatch);
  }, [id]);

  // let filterEntry = ["all",  "Last 30 days"];

  const filterValue = dnsDetailsArray?.map((row) => row?.recordType);
  let distinctFilter = filterValue?.filter(
    (item, index, self) => self.indexOf(item) === index
  );

  distinctFilter?.unshift("all");
  distinctFilter?.push("Latest");

  const issuesFound = dnsDetailsArray?.filter(
    (row) =>
      row?.recordType == "spf" &&
      !row?.valid &&
      row?.record?.indexOf("SPF TXT record") == -1 &&
      row?.record?.indexOf("does not exist") == -1
  );
  const warningFound = dnsDetailsArray?.filter(
    (row) =>
      (row?.recordType == "dmarc" && !row?.valid) ||
      (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)
  );

  // const compliantData = dnsDetailsArray?.filter((row) => row?.valid);

  // let compliantDataPercentage = compliantData?.length / dnsDetailsArray?.length;

  //console.log(issuesFound, "compliantDataPercentage", dnsDetailsArray);

  let openPortArray = [];
  let ipArray = [];
  // if (oldDnsDetails?.scanDetails) {
  //   const compliantDataOld = oldDnsDetails?.scanDetails?.filter(
  //     (row) => row?.valid
  //   );
  //   let compliantDataOldPercentage =
  //     (compliantDataOld?.length / oldDnsDetails?.scanDetails?.length) * 100;
  //   ipArray.push(oldDnsDetails?.scanDetails?.length || 0);
  //   openPortArray.push(compliantDataOldPercentage?.toFixed(0));
  // }
  for (let count = 0; count < oldDnsDetails?.scanDetails?.length; count++) {
    const compliantDataOld = oldDnsDetails?.scanDetails?.[count]?.filter(
      (row) => row?.valid
    );
    let compliantDataOldPercentage =
      (compliantDataOld?.length / oldDnsDetails?.scanDetails?.[count]?.length) * 100;
    ipArray.push(oldDnsDetails?.scanDetails?.[count]?.length || 0);
    openPortArray.push(compliantDataOldPercentage?.toFixed(0));
  }
  // if (dnsDetailsArray) {
  //   ipArray.push(dnsDetailsArray?.length);
  //   openPortArray.push((compliantDataPercentage * 100).toFixed(0));
  // }

  //console.log(dnsDetailsArray, "dnsDetails");

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const downloadCsvData = dnsDetailsArray?.map((res) => ({
    DomainName: res?.subDomain,
    RecordType: res?.recordType,
    Record: JSON.stringify(
      res?.recordType == "MX"
        ? res.valid
          ? res?.hosts?.map((row) => row[0]?.hostname)
          : res?.warnings?.map((row) => row?.hostname)
        : res?.record
    )?.replace(/[\[\]']+/g, "")?.replace(/['"]+/g, ""),
    LastUpdated: moment(res?.createdAt).format("DD-MM-YYYY"),
  }));

  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };


  let newAddedDomain = [];
  if (client?.scanCounter > 0) {
    let oldScanData = oldDnsDetails?.scanDetails[client?.scanCounter - 1];
    for (let vuln = 0; vuln < dnsDetailsArray?.length; vuln++) {
      let flag = true;
      for (let oldVuln = 0; oldVuln < oldScanData?.length; oldVuln++) {
        if (dnsDetailsArray[vuln].subDomain == oldScanData[oldVuln].subDomain && dnsDetailsArray[vuln].recordType == oldScanData[oldVuln].recordType) {
          flag = false;
          break;
        }
      }
      if (flag) {
        newAddedDomain.push(dnsDetailsArray[vuln])
      }
    }
    //console.log(newAddedDomain,'scanDetails');
  }

  if (domainFilter !== "all") {
    dnsDetailsArray = dnsDetailsArray?.filter((val) => val?.subDomain.indexOf(domainFilter) != -1)
  }

  let mapData =
    filterData === "all"
      ? dnsDetailsArray
      : filterData === "Issues"
        ? issuesFound
        : filterData === "Warnings"
          ? warningFound
          : filterData === "Last 30 days"
            ? recordAddedInLast30Days
            : filterData === "Latest"
              ? newAddedDomain
              : dnsDetailsArray?.filter((row) => row?.recordType == filterData);

  // mapData = mapData?.filter((row) => ((row?.recordType == "spf" && (row?.record?.indexOf("SPF TXT record") == -1 && row?.record?.indexOf("does not exist") == -1)) || (((row?.recordType == "dmarc" && !row?.valid) || (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)))))

  const recordAddedInLast30Days = mapData?.filter(
    (row) =>
      moment(row?.createdAt).isBefore(moment()) &&
      moment(row?.createdAt).isAfter(moment().subtract(90, "days"))
  );

  mapData = mapData?.filter((res) => {
    if (search === "") {
      return res;
    } else if (
      res?.recordType?.toLowerCase()?.indexOf(search.toLowerCase()) != -1 ||
      res?.subDomain?.toLowerCase()?.indexOf(search.toLowerCase()) != -1
    ) {
      // (res?.records?.map((row) => row.indexOf(search) != -1)) )
      return res;
    }
  });

  let currentTableData = [];
  //console.log(mapData, "dnsDetailsArray");
  if (mapData && mapData.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>DNS Records</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {downloadCsvData?.length === 0 ? null : (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      exportToCSV(downloadCsvData, "DNS Records");
                    }}
                    href="/add-report"
                    style={{ float: "right" }}
                    className="btn btn-primary mr-1"
                  >
                    <i className="fa fa-file-excel-o mr-1"></i> Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-4 col-md-12">
            <div className="card">
              {/* <div className="header">
                <h2>Details </h2>
              </div> */}
              <div className="body" style={{ minHeight: "410px" }}>
                <div>
                  <div className="row text-center">
                    <div className="col-12 pb-4 pt-12"></div>
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <label className="d-block">
                      Records found{" "}
                      <span className="float-right">
                        {mapData?.length || 0}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-danger"
                        role="progressbar"
                        aria-valuenow={(
                          ((issuesFound?.length || 0) /
                            (dnsDetailsArray?.length || 0)) *
                          100
                        ).toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width:
                            (
                              ((issuesFound?.length || 0) /
                                (dnsDetailsArray?.length || 0)) *
                              100
                            ).toFixed(0) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12">
            <div className="card">
              <div className="body" style={{ minHeight: "410px" }}>
                <span>Previous scan details</span>

                {/* <small className="text-muted">
                  Previous scan details between 
                </small> */}
                {/* <SalesChart /> */}
                {oldDnsDetails && (
                  <BasicLineColumn
                    dates={oldDnsDetails?.dates}
                    // openPortArray={openPortArray}
                    ipArray={ipArray}
                    color={["#ffd861", "#FF5A65"]}
                    yAxis={"IP Assets"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-12 col-sm-12">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Details</h2>
                </div>
                <div className="row" ref={myRef}>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      {distinctFilter?.map((row) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={domainFilter}
                      onChange={(e) => setDomainFilter(e.target.value)}
                    >
                      <option value='all'>All</option>
                      {domain?.map((row, index) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>


                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>

                        <th style={{ width: "100px" }}>Domain Name</th>
                        <th>Record Type</th>
                        <th style={{ width: "200" }}>Record</th>
                        <th>Last modified on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        //console.log(row, "rowaa");
                        return (
                          <tr>
                            <td>{index + 1}</td>

                            {/* <td style={{ whiteSpace: "unset" }}>
                              <a
                                href={`${row?.subDomain || row?.subDomain}`}
                                target="_blank"
                              >
                                {row?.subDomain || row?.subDomain}
                              </a>
                            </td> */}
                            <td style={{ whiteSpace: "unset" }}>
                              <a href={`https://${row?.subDomain}`} target="_blank">
                                {`${row?.subDomain}`}
                              </a>
                            </td>
                            <td>{row?.recordType}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.recordType == "MX"
                                ? row.valid
                                  ? row?.hosts?.map((req) => {
                                    return (
                                      <>
                                        <span>{req[0]?.hostname}</span>
                                        <br />
                                      </>
                                    );
                                  })
                                  : row?.warnings?.map((req) => {
                                    return (
                                      <>
                                        <span>{req?.hostname}</span>
                                        <br />
                                      </>
                                    );
                                  })
                                : row?.record}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span className="badge badge-success">
                                {moment(row?.updatedAt || row?.createdAt).format("DD-MM-YYYY")}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {mapData && mapData.length > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={mapData?.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domain;
