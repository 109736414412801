import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class ApexChart extends Component {
    constructor(props) {
        super(props);
        console.log(props, "pie props")
        let highRisk = 0, medRisk = 0, lowRisk = 0;
        Object?.keys(props.value || {})?.map((key) => {
            let value = props.value[key];
            if (value?.length > 0) {
                let high = 0, med = 0, low = 0;
                if (props.type == "predictor") {
                    for (let i = 0; i < value?.length / 2; i++) {
                        if (parseInt(value[i].rating.breach_predeiction_score / 2) > 80) {
                            high = high + parseInt((value[i].rating.breach_predeiction_score || 0) / 2)
                        } else if (parseInt(value[i].rating.breach_predeiction_score / 2) < 80 && (parseInt(value[i].rating.breach_predeiction_score / 2) > 40)) {
                            med = med + parseInt((value[i].rating.breach_predeiction_score || 0) / 2)
                        } else {
                            low = low + parseInt((value[i].rating.breach_predeiction_score || 0) / 2)
                        }
                    }
                } else {
                    for (let i = 0; i < value?.length / 2; i++) {
                        if (parseInt(value[i].rating.breach_detection_score / 2) > 80) {
                            high = high + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                        } else if (parseInt(value[i].rating.breach_detection_score / 2) < 80 && (parseInt(value[i].rating.breach_detection_score / 2) > 40)) {
                            med = med + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                        } else {
                            low = low + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                        }
                    }
                }
                highRisk = highRisk + high;
                medRisk = medRisk + med;
                lowRisk = lowRisk + low;
                // highRisk.push(high);
                // medRisk.push(med);
                // lowRisk.push(low);
            }
        })

        // let _highValue = 0;

        this.state = {

            series: [highRisk, medRisk, lowRisk],
            options: {
                chart: {
                    width: 280,
                    type: 'pie',
                },
                labels: ['High Risk (>80)', 'Med Risk (<80 & >40)', 'Low Risk (<40)'],
                responsive: [{
                    breakpoint: 380,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: ['#FF5A65', '#3C89DA', '#05C168'],
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 0
                    }
                }
            },


        };
    }



    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={280} />
        );
    }
}

export default ApexChart;
