import {
  LEAKEDCREDS_REQUESTS_FETCHED,
  GET_POST_MAN_DETAILS,
  TELEGRAM_REQUESTS_FETCHED_TOTAL,
  LEAKED_SESSION_FETCHED_GRAPH,
  PII_DETAILS_GRAPH,
  PII_DETAILS_TOTAL,
  MALWARE_CREDENTIALS_FETCHED_GRAPH,
  MALWARE_CREDENTIALS_FETCHED_TOTAL,
  LEAKEDCREDS_REQUESTS_FETCHED_TOTAL,
  LEAKEDCREDS_REQUESTS_FETCHED_GRAPH,
  TELEGRAM_REQUESTS_FETCHED,
  GET_POST_MAN_TOTAL,
  GET_POST_MAN_GRAPH,
  GET_POST_MAN,
  MALWARE_CREDENTIALS_FETCHED,
  LEAKED_SESSION_FETCHED,
  GET_PHISHING_PAGE,
  PII_DETAILS,
} from "../actions";

export default function (
  state = {
    loading: true,
    leakedtotal: [],
    leakedGraph: [],
    stolenGraph: null,
    postman: [],
    postmanGraph:[],
    postmanTotal:[],
    postmandetails: [],
    telegramData: null,
    piiGraph: null,
    piiTotal: null,
    stolenTotal: null,
    malwareGraph: null,
    malwareTotal: null,
    response: null,
    list: null,
    malware: null,
    sessions: null,
    scanCompleted: true,
    phishingPage: null,
    piidetails: null,
  },
  action
) {
  switch (action.type) {
    case TELEGRAM_REQUESTS_FETCHED_TOTAL:
      return {
        ...state,
        leakedres: action.payload,
      };
    case LEAKED_SESSION_FETCHED_GRAPH:
      return {
        ...state,
        leakedGraph: action.payload,
      };
    case LEAKEDCREDS_REQUESTS_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case LEAKEDCREDS_REQUESTS_FETCHED_GRAPH:
      return {
        ...state,
        stolenGraph: action.payload,
      };
    case LEAKEDCREDS_REQUESTS_FETCHED_TOTAL:
      return {
        ...state,
        stolenTotal: action.payload,
      };
    case PII_DETAILS_TOTAL:
      return {
        ...state,
        piiTotal: action.payload,
      };
    case PII_DETAILS_GRAPH:
      return {
        ...state,
        piiGraph: action.payload,
      };
    case MALWARE_CREDENTIALS_FETCHED_TOTAL:
      return {
        ...state,
        malwareTotal: action.payload,
      };
    case MALWARE_CREDENTIALS_FETCHED_GRAPH:
      return {
        ...state,
        malwareGraph: action.payload,
      };
    case MALWARE_CREDENTIALS_FETCHED:
      return {
        ...state,
        malware: action.payload,
        loading: false,
      };
    case LEAKED_SESSION_FETCHED:
      return {
        ...state,
        sessions: action.payload,
        loading: false,
      };
    case GET_PHISHING_PAGE:
      return {
        ...state,
        phishingPage: action.payload || [],
        loading: false,
      };
    case PII_DETAILS:
      return {
        ...state,
        piidetails: action.payload || [],
        loading: false,
      };
    case TELEGRAM_REQUESTS_FETCHED:
      return {
        ...state,
        telegramData: action.payload || [],
        loading: false,
      };
    case GET_POST_MAN:
      return {
        ...state,
        postman: action.payload || [],
        loading: false,
      };
      case GET_POST_MAN_GRAPH:
      return {
        ...state,
        postmanGraph: action.payload || [],
        loading: false,
      };
      case GET_POST_MAN_TOTAL:
      return {
        ...state,
        postmanTotal: action.payload || [],
        loading: false,
      };
    case GET_POST_MAN_DETAILS:
      return {
        ...state,
        postmandetails: action.payload || [],
        loading: false,
      };
    default:
      return state;
  }
}
