import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDnsDetails } from "../../actions/sub-domain";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";

const Domain = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(20);
  const [domainFilter, setDomainFilter] = useState([]);

  const [filterData, setfilterData] = useState("all");
  const [search, setSearch] = useState("");

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const org = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const vendorName = sessionStorage.getItem("vendorName");

  //console.log(org,'organ')
  let dnsDetailsArray = useSelector(
    (state) => state?.subDomain?.dnsDetails?.data
  );

  let domain = org?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain?.length - 1) == "/") {
    domain = [domain?.substr(0, domain?.length - 1)];
  } else {
    domain = [domain];
  }

  if (org?.domains && org?.domains?.length > 0) {
    domain = org?.domains;
  }

  // console.log(domain, "domain")

  // dnsDetailsArray = dnsDetailsArray?.filter((row) => domain.includes(row?.subDomain) && (row.recordType == "spf" || row.recordType == "dmarc"))

  // console.log(dnsDetailsArray, 'dnsDetailsArray', org?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
  // let oldDnsDetails = useSelector(
  //   (state) => state?.subDomain?.dnsDetails?.scanDetailArray
  // );
  let scanCompleted = useSelector((state) => state?.subDomain?.scanCompleted);

  //console.log(dnsDetailsArray, "result value");

  useEffect(() => {
    if (orgId) {
      getDnsDetails(orgId, true, dispatch);
    } else {
      getDnsDetails(id, true, dispatch);
    }
  }, [id]);

  let distinctArray = [];
  dnsDetailsArray?.reduce((arr, curr) => {
    let _key = curr?.subDomain + "-" + curr?.recordType;
    if (!arr[_key]) {
      arr[_key] = _key;
      distinctArray.push(curr);
    }
    return arr;
  }, {});

  dnsDetailsArray = distinctArray;
  console.log(distinctArray, "dnsDetailsArray");

  const filterValue = dnsDetailsArray?.map((row) => row?.recordType);
  let distinctFilter = filterValue?.filter(
    (item, index, self) => self.indexOf(item) === index
  );

  distinctFilter?.unshift("all");

  const issuesFound = dnsDetailsArray?.filter(
    (row) =>
      row?.recordType == "spf" &&
      !row?.valid &&
      row?.record?.indexOf("SPF TXT record") == -1 &&
      row?.record?.indexOf("does not exist") == -1
  );
  const warningFound = dnsDetailsArray?.filter(
    (row) =>
      (row?.recordType == "dmarc" && !row?.valid) ||
      (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)
  );

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const downloadCsvData = dnsDetailsArray?.map((res) => ({
    // Status: res.valid ? "OK" : "ERROR",
    DomainName: res?.subDomain,
    RecordType: res?.recordType,
    Record: JSON.stringify(
      res?.recordType == "MX"
        ? res.valid
          ? res?.hosts?.map((row) => row[0]?.hostname)
          : res?.warnings?.map((row) => row?.hostname)
        : res?.record
    )
      ?.replace(/[\[\]']+/g, "")
      ?.replace(/['"]+/g, ""),
    LastUpdated: moment(res?.createdAt).format("DD-MM-YYYY"),
  }));

  // if (domainFilter !== "all") {
  //   // domains = domains?.filter((val) => val?.lookalike.indexOf(domainFilter?.split(".")[0]) != -1)
  //   dnsDetailsArray = dnsDetailsArray?.filter((val) => val?.subDomain == domainFilter)
  // }

  let domainSearch = [];
  domainFilter?.reduce((arr, curr) => {
    dnsDetailsArray?.map((res) => {
      if (res.subDomain == curr) domainSearch.push(res);
    });
  }, {});
  // console.log(countrySearch, "countrySearch")
  dnsDetailsArray = domainFilter?.length > 0 ? domainSearch : dnsDetailsArray;
  console.log(dnsDetailsArray, "dnsDetailsArray");

  let mapData =
    filterData === "all"
      ? dnsDetailsArray
      : filterData === "Issues"
      ? issuesFound
      : filterData === "Warnings"
      ? warningFound
      : filterData === "Last 30 days"
      ? recordAddedInLast30Days
      : dnsDetailsArray?.filter((row) => row?.recordType == filterData);

  // mapData = mapData?.filter((row) => ((row?.recordType == "spf" && (row?.record?.indexOf("SPF TXT record") == -1 && row?.record?.indexOf("does not exist") == -1)) || (((row?.recordType == "dmarc" && !row?.valid) || (row?.recordType == "MX" && row?.hosts?.length > 0 && !row?.valid)))))

  const recordAddedInLast30Days = mapData?.filter(
    (row) =>
      moment(row?.createdAt).isBefore(moment()) &&
      moment(row?.createdAt).isAfter(moment().subtract(90, "days"))
  );

  mapData = mapData?.filter((res) => {
    if (search === "") {
      return res;
    } else if (
      res?.recordType?.toLowerCase()?.indexOf(search.toLowerCase()) != -1 ||
      res?.subDomain?.toLowerCase()?.indexOf(search.toLowerCase()) != -1
    ) {
      // (res?.records?.map((row) => row.indexOf(search) != -1)) )
      return res;
    }
  });

  let currentTableData = [];
  //console.log(mapData, "dnsDetailsArray");
  if (mapData && mapData?.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Email security</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                {/* <span><b>Rating : {org?.currentRating?.asm_impact_spf}</b></span> */}
              </nav>
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-12 col-sm-12">
            <div className="card">
              <div className="body">
                <h6>Details</h6>
                <div className="row" ref={myRef}>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      {distinctFilter?.map((row) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setDomainFilter(newValue);
                      }}
                      value={domainFilter}
                      options={domain || []}
                      getOptionLabel={(option) => option?.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Domain"
                        />
                      )}
                    />
                    {/* <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={domainFilter}
                      onChange={(e) => setDomainFilter(e.target.value)}
                    >
                      <option value='all'>All</option>
                      {domain?.map((row, index) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select> */}
                  </div>
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-1">
                    {downloadCsvData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          exportToCSV(downloadCsvData, "DNS Records");
                        }}
                        href="/add-report"
                        style={{ float: "right" }}
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o" style={{}}></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>
                        <th>Status</th>
                        <th style={{ width: "100px" }}>Domain Name</th>
                        <th>Record Type</th>
                        <th style={{ width: "200" }}>Record</th>
                        <th>Last modified on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        //console.log(row, "rowaa");
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <span
                                className={
                                  row?.valid
                                    ? "badge badge-success"
                                    : "badge badge-danger"
                                }
                              >
                                {row?.valid ? "Valid" : "Invalid"}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <a
                                href={`${row?.subDomain || row?.subDomain}`}
                                target="_blank"
                                style={{  }}
                              >
                                {row?.subDomain || row?.subDomain}
                              </a>
                            </td>
                            <td>{row?.recordType}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.recordType == "MX"
                                ? row.valid
                                  ? row?.hosts?.map((req) => {
                                      return (
                                        <>
                                          <span>{req[0]?.hostname}</span>
                                          <br />
                                        </>
                                      );
                                    })
                                  : row?.warnings?.map((req) => {
                                      return (
                                        <>
                                          <span>{req?.hostname}</span>
                                          <br />
                                        </>
                                      );
                                    })
                                : row?.record}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span className="badge badge-success">
                                {moment(row?.createdAt).format("DD-MM-YYYY")}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {mapData && mapData.length > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={mapData?.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domain;
