import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  postManDelta,
  postManAction,
  postManGraph,
  postManTotal,
} from "../../actions/leakedCredentials";
import Pagination from "../Pagination/Pagination";
import BasicLineColumn from "../common/apexChart/lineChart";
import * as FileSaver from "file-saver";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import moment from "moment";

const PostManMonitoring = () => {
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const dispatch = useDispatch();
  const [toggleArray, setToggleArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [isModalTrue, setModalSet] = useState(false);
  const [detail, setdetail] = useState([]);
  const state = useSelector((state) => state?.leakedCredential?.postman?.data);
  // const totalCount = useSelector((state) => state?.leakedCredential?.postmanTotal);
  // console.log(totalCount,'totalCount')
  const totalItemCount = useSelector(
    (state) => state?.leakedCredential?.postman?.totalCount
  );
  const oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.postmanGraph?.data
  );
  console.log(oldDnsDetails, "olddns");
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [totalItem, setTotalItem] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  let ipArray = [];

  if (state) {
    ipArray.push(state?.length);
  }

  useEffect(() => {
    postManDelta(id, dispatch, page, category);
  }, [id, dispatch, page, category]);

  useEffect(() => {
    postManGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItem(totalItemCount);
  }, [totalItemCount]);

  let mapData = state;
  let currentTableData = mapData;

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        postmanRecordIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      postManAction(id, dispatch, page, data, category);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      postmanRecordIds: selectedIds == 0 ? ids : selectedIds,
    };
    postManAction(id, dispatch, page, data, category);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      postmanRecordIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    postManAction(id, dispatch, page, data, category);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/postmanRecord/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Postman Monitoring.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Postman monitoring</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Postman Monitoring
                </li>
              </ol>
              <br />
              <span>
                <span>
                  <b>Rating : {oldDnsDetails?.individualRating}</b>
                </span>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {mapData?.length === 0 ? null : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                  }}
                  href="/add-report"
                  style={{  fontWeight: "bold" }}
                  className="btn btn-primary mr-1"
                >
                  <i
                    className="fa fa-file-excel-o mr-1"
                    style={{   }}
                  ></i>{" "}
                  Download Details
                </a>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card card-box">
          <div className="d-flex justify-content-between">
              <i className="font-50 fa fa-database icon-dashboard"></i>
              <h4 className="font-44 total-h4">
              {totalItemCount || 0}
              </h4>
              </div>
              <label className="mb-0 text-right">Records Found</label>
              <hr className="border-red"></hr>

            <div className="body pt-1" style={{   }}>
              {/* <div className="header">
                  <h2>Details</h2>
                </div> */}
               
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "440px" }}>
              <h6>Previous scan Details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates || []}
                  ipArray={oldDnsDetails?.scanDetails || []}
                  color={["#DD3731"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${
              category === "all" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setSelectedIds([]);
              setSearch("");
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "risk-accepted" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "false-positive" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "remidiated" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            <div className="col-sm-4">
              {selectedIds.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
            <div className="col-sm-4">
              {/* <div
                data-test="datatable-res-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div> */}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      {(category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedIds == 0 ? false : multiCheckTrue}
                            onClick={() => {
                              multicheck(currentTableData);
                            }}
                            className="checkUncheck"
                          />
                        </th>
                      )}
                      <th>Workspace Url</th>
                      <th>User Detail</th>
                      <th>Updated At</th>
                      {(category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th style={{ width: "80" }}>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      return (
                        <tr>
                          {(category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <input
                                onClick={() => singleClick(row?._id)}
                                checked={selectedIds?.includes(row?._id)}
                                type="checkbox"
                                className="checkUncheck"
                              />
                            </td>
                          )}
                          <td>
                            <Link to={`postman-monitoring/${row?._id}`}>
                              {row?.request_url ? row?.request_url : "N/A"}
                            </Link>
                          </td>
                          <td>
                            {row?.user_details ? row?.user_details : "N/A"}
                          </td>
                          <td>
                            {row?.last_updated_at
                              ? moment(row?.last_updated_at).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : "NA"}
                          </td>
                          {category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {(category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed(row?._id)}
                              >
                                Unsnooze
                              </button>
                            </td>
                          )}
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItem > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItem}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModalTrue == true ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalSet(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="table-responsive">
                      <table className="table table-custom spacing5 mb-0 p-10">
                        <thead>
                          <tr>
                            <th>key</th>
                            <th>value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.map((row, index) => {
                            return (
                              <tr>
                                <td>{row?.key}</td>
                                <td>{row?.value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className=" ">
                <div className="card mb-0">
                  <div className="row clearfix">
                     
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                       
                    </div>
                  </div>
                    
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                          setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PostManMonitoring;
