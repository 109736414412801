import React from 'react';
import Chart from 'react-apexcharts';


class LineBarChartBrandSecurity extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        options: {
          chart: {
            id: 'line-bar-chart'
          },
          xaxis: {
            categories: [
              'Fake Social Medias',
              'Look A like Domains',
              'Rouge Mobile Apps',
            ]
          },
          colors: ['#347BB2', '#DD3731'],
          yaxis: [{
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#347BB2'
            },
            labels: {
              style: {
                colors: '#347BB2',
              }
            },
            title: {
              // text: 'No. of Instance',
              style: {
                color: '#347BB2',
              },
            },
            min: 0,  // Minimum value for the left side
            // max: 4000, // Maximum value for the left side
          }, {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#DD3731'
            },
            labels: {
              style: {
                colors: '#DD3731',
              }
            },
            title: {
              // text: 'No. of Companies',
              style: {
                color: '#DD3731',
              },
            },
            min: 0,  // Minimum value for the right side
            // max: 60, // Maximum value for the right side
          }],          
        },
        series: [
          {
            name: 'No. of Instance',
            type: 'column',
            data: [
              props?.brandsecurity?.totalInstancesOfFakeSocialMediaProfiles,
              props?.brandsecurity?.totalInstancesOfLookAlikeDomains,
              props?.brandsecurity?.totalInstancesOfRogueMobileApplications
            ]
          },
          {
          name: 'No. of Companies',
          type: 'line',
          data: [
            props?.brandsecurity?.totalCompaniesWithFakeSocialMediaProfiles,
            props?.brandsecurity?.totalCompaniesWithLookAlikeDomains,
            props?.brandsecurity?.totalCompaniesWithRougeMobileApplications,

          ]
        }],
      };
    }
  
    render() {
      return (
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="line" height={400} />
        </div>
      );
    }
  }
  
  export default LineBarChartBrandSecurity;
  