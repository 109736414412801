import React, { Component } from 'react';
import Chart from "react-apexcharts";
import moment from 'moment';

class Barchart extends Component {
    constructor(props) {
        super(props);
        //console.log(props,'props here')
        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 280,
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        width: 10,
                        columnWidth: "10%"
                    },
                },
                fill: {
                    colors: ['#008ffb', '#DD3731', '#feb019']
                },
                colors: ['#008ffb', '#DD3731', '#feb019'],
                yaxis: {
                    show: true
                },
                xaxis: {
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                },
                labels:  props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                // legend: {
                //     show: true
                // }
            },
            series: [
                {
                    name: "Android",
                    data: props?.androidApps
                },
                {
                    name: "IOS",
                    data: props?.iosApps
                },
                {
                    name: "Third Party",
                    data: props?.thirdPartyApps
                }
            ]
        };
    }
    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    // width="100%"
                    height="280px"
                />
            </div>
        );
    }
}

export default Barchart;
