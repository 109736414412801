import axios from "axios";
import { API_URL } from "../utils/constant";
import { SUBDOMAIN_FETCHED, SUBDOMAIN_FETCHED_SSL_TOTAL, FETCH_VULNERABLE_SSL_GRAPH, FETCH_VULNERABLE_SSL,FETCH_VULNERABLE_SSL_TOTAL, SUBDOMAIN_FETCHED_SSL_GRAPH, SUBDOMAIN_FETCHED_SSL, FETCH_SUCCESS_DNS_DETAILS_GRAPH, FETCH_SUBDOMAIN_DETAIL, FETCH_SUCCESS_DNS_DETAILS_TOTAL, SUBDOMAIN_FETCHED_TOTAL, SUBDOMAIN_FETCHED_GRAPH , SUBDOMAIN_SCAN_STARTED, LOOK_ALIKE_FETCHED, INVENTORY_DETAILS_FETCHEDV1, FETCH_DNS_DETAILS, FETCH_SUCCESS_DNS_DETAILS, SECURITY_HEADER } from "./index";
import { toastr } from "react-redux-toastr";
import { data } from "jquery";
// import { toastr } from 'react-redux-toastr';

export const fetchSubDomains = (props, dispatch ,page , category ,domain_search_query,status) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/expired-ssl-certificates/${props}/delta?page=${page}&category=${category}&domain_search_query=${domain_search_query}&status=${status}`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsAction = (props, dispatch ,page , category ,domain_search_query,status ,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/expired-ssl-certificates/${props}/action?page=${page}&category=${category}&domain_search_query=${domain_search_query}&status=${status}`,data)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/expired-ssl-certificates/${props}/totals`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/expired-ssl-certificates/${props}/graph`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchVulnerableSsl = (props, dispatch ,page , category ,domain_search_query,status) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/vulnerable-ssl/${props}/delta?page=${page}&category=${category}&domain_search_query=${domain_search_query}&status=${status}`)
      .then((response) => {
        dispatch({
          type: FETCH_VULNERABLE_SSL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_VULNERABLE_SSL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchVulnerableSslAction = (props, dispatch ,page , category ,domain_search_query,status,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/vulnerable-ssl/${props}/action?page=${page}&category=${category}&domain_search_query=${domain_search_query}&status=${status}`,data)
      .then((response) => {
        dispatch({
          type: FETCH_VULNERABLE_SSL,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_VULNERABLE_SSL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchVulnerableTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/vulnerable-ssl/${props}/totals`)
      .then((response) => {
        dispatch({
          type: FETCH_VULNERABLE_SSL_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_VULNERABLE_SSL_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchVulnerableGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/vulnerable-ssl/${props}/graph`)
      .then((response) => {
        dispatch({
          type: FETCH_VULNERABLE_SSL_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_VULNERABLE_SSL_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchWebTech = (props, page, dispatch , category , filterData, searchBy , searchValue) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/webtech_analysis/${props}/delta?pageno=${page}&category=${category}&searchBy=${searchBy}&q=${searchValue}&type=${filterData}`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchWebTechAction = (props, page, dispatch , category , filterData, searchBy , searchValue ,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/webtech_analysis/${props}/action?pageno=${page}&category=${category}&searchBy=${searchBy}&q=${searchValue}&type=${filterData}`,data)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
};

export const fetchWebTechGraph = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/webtech_analysis/${props}/graph`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchWebTotal = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/webtech_analysis/${props}/totals`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const addSubDmoan = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .post(`${API_URL}/createSubDomain`, { ...props })
      .then((response) => {
        if(response.data.error){
          toastr.error("Error", response.data.userMessage);
          return false;
        }else{
          toastr.success("Success", response.data.userMessage);
        }
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        toastr.error("Error", message);
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const startScanSubdomain = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/start-scan/${props.subdomain}`)
      .then((response) => {
        if(response.data.error){
          toastr.error("Error", response.data.userMessage);
        }else{
          toastr.success("Success", response.data.userMessage);
        }
        dispatch({
          type: SUBDOMAIN_SCAN_STARTED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        toastr.error("Error", message);
        dispatch({
          type: SUBDOMAIN_SCAN_STARTED,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDetails = (props, dispatch) => {
  //console.log(props);
  if (props !== undefined) {
    axios.get(`${API_URL}/dns-details/${props}`).then((res) => {
      if (res.data.error) {
        toastr.error("ERROR", "Something went wrong");
      } else {
        toastr.success("SUCCESS", "Data Fetched.");
      }
      dispatch({
        type: FETCH_SUBDOMAIN_DETAIL,
        subDomainDetail: res.data,
      });
    });
  }
};

export const subDomainScan = (props, dispatch) => {
  //console.log(props);
  if (props !== undefined) {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    axios.post(`${API_URL}/start-scan`, props ).then((res) => {
      if (res.data.error) {
        toastr.error("ERROR", res.data.userMessage);
        return false;
      } else {
        toastr.success("SUCCESS", "Scan started.");
      }
      if(props.isVulnerabilities){
        dispatch({
          type: INVENTORY_DETAILS_FETCHEDV1,
          data: res.data.data,
        });
      }else{
       dispatch({
          type: LOOK_ALIKE_FETCHED,
          payload: res.data,
        });
      }
    });
  }
}

export const getDnsDetails = (props, flag, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getDnsDetails/${props}?filter=${flag}`)
      .then((response) => {
        dispatch({
          type: FETCH_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetails = (props, dispatch , page ,category ,status ,domainquery , domain) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/delta?page=${page}&category=${category}&status=${status}&domainquery=${domainquery}&domain=${domain}`)
      .then((response) => {
        console.log(response,'response')
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsAction = (props, dispatch , page ,category ,status ,domainquery , domain ,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/getSubDomainDetails/${props}/action?page=${page}&category=${category}&status=${status}&domainquery=${domainquery}&domain=${domain}`,data)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/totals`)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/graph`)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const fetchSecurityHeader = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSecurityHeader/${props}`)
      .then((response) => {
        // console.log(response.data,"response.data")
        dispatch({
          type: SECURITY_HEADER,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SECURITY_HEADER,
          payload: { error: true, userMessage: message },
        });
      });
  }
};