import React from 'react';
import { connect } from 'react-redux';
import  '../../loader.css';

const Loader = ({ loading }) => {
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
