import axios from "axios";
import { API_URL } from "../utils/constant";
import { USER_LOGGED_IN, USER_LOGGED_OUT, DASHBOARD_FETCHED, PASSWORD_RESET_REQUESTED, PASSWORD_RESET_DONE, TWO_FACTOR_ENABLED } from "./index";
import { toastr } from "react-redux-toastr";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import 'firebase/auth';

import firebase from "../firebase";
// import firebase from "../firebase";

// import { toastr } from 'react-redux-toastr';

export const pingServer = (props,type) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  if (session) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(`${API_URL}/ping`, {})
      .then((response) => {
        if (response.data.error) {
          dispatch({
            type: USER_LOGGED_OUT,
            payload: null,
          });

          toastr.error("Error", response?.data?.userMessage);
        } else {
          dispatch({
            type: USER_LOGGED_IN,
            payload: response.data.data,
          });
          localStorage.setItem("user", JSON.stringify(response.data.data));
          if(type == "checkToken"){
            window.location.replace("/");
          }
          // toastr.success("Success", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err?.response?.data?.userMessage;
        }
        toastr.error("Error", message);

        dispatch({
          type: USER_LOGGED_OUT,
          payload: null,
        });
        localStorage.clear();
        window.location.replace("/login");
      });
  } else {
    dispatch({
      type: USER_LOGGED_OUT,
      payload: null,
    });
  }
};

export const dashboardAnalytics = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/dashboard`)
    .then((response) => {
      dispatch({
        type: DASHBOARD_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }

      dispatch({
        type: DASHBOARD_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const login = (props) => (dispatch) => {
  axios
    .post(`${API_URL}/login`, { ...props })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // const cookie = new Cookies();
        // cookie.set("session", response.data.data.token);
        if (response.data.twoFactor) {
          toastr.success("Success", response.data.otpMessage);
          dispatch({
            type: TWO_FACTOR_ENABLED,
            payload: response.data.data
          })
        } else {
          toastr.success("Success", response.data.userMessage);
          localStorage.setItem("session", response.data.data.token);
          localStorage.setItem("refreshToken", response.data.data.refreshToken)
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.data.token}`;
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          //console.log("logged in", response.data.data)
          dispatch({
            type: USER_LOGGED_IN,
            payload: response.data.data.user,
          });
        }
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);
    });
};

export const verifyOTP = (props) => (dispatch) => {

  //console.log(props,'verifyOTP');
  // const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${props.token}`;
  axios
    .post(`${API_URL}/verifyOTP`, { authToken: props.token, otp: props.otp })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // const cookie = new Cookies();
        // cookie.set("session", response.data.data.token);
        localStorage.setItem("session", response.data.data.token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        //console.log("logged in", response.data.data)
        dispatch({
          type: USER_LOGGED_IN,
          payload: response.data.data.user,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);
    });
}

export const forgotPassword = (props) => (dispatch) => {
  axios
    .post(`${API_URL}/forgot-password`, { ...props })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        toastr.success("Success", response.data.userMessage);
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);
    });
};

export const resetPassword = (props, dispatch) => {
  axios
    .post(`${API_URL}/${props.resetCode ? ("reset-password/" + props.resetCode) : "reset-password"}`, { ...props })
    .then((response) => {
      //console.log("resetPassword")
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // const cookie = new Cookies();
        // cookie.set("session", response.data.data.token);
        localStorage.setItem("session", response.data.data.token);
        if (response.data.data.refresh_token) {
          localStorage.setItem("refreshToken", response.data.data.refresh_token)
        }
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        toastr.success("Success", "Password updated.");
        dispatch({
          type: PASSWORD_RESET_DONE,
          payload: response.data.data.user,
        });
        if (props.redirect) {
          props.propRedirect.history.push("/");
        }
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);
    });
};

export const resetPasswordNew = (props) => (dispatch) => {
  axios
    .post(`${API_URL}/${props.resetCode ? ("reset-password/" + props.resetCode) : "reset-password"}`, { ...props })
    .then((response) => {
      //console.log("resetPassword")
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // const cookie = new Cookies();
        // cookie.set("session", response.data.data.token);
        localStorage.setItem("session", response.data.data.token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        if(props.resetCode){
          localStorage.setItem("refreshToken", response.data.data.refresh_token)
        }
        toastr.success("Success", "Password updated.");
        dispatch({
          type: PASSWORD_RESET_DONE,
          payload: response.data.data.user,
        });
        if (props.redirect) {
          props.propRedirect.history.push("/");
        }
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);
    });
};

// const signInUserWithGoogleRequest = async () =>
//   await auth
//     .signInWithPopup(googleAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

export const googleSignIn = (props) => (dispatch) => {
  const auth = getAuth(firebase.getApp());
  const provider = new GoogleAuthProvider();

  console.log(firebase.getApp())
  // const provider = new firebase.auth.GoogleAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result, 'result')
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log(credential, 'cred')
      const token = credential.idToken;
      // The signed-in user info.
      // const user = result.user;
      //console.log(token,"token")

      axios
        .post(`${API_URL}/google/login`, { token: token })
        .then((response) => {
          if (response.data.error == true) {
            console.log('error', response.data.userMessage)
            toastr.error("Error", response.data.userMessage);
          } else {
            // if(user.email != props){
            //   toastr.error("Error", `Please use ${props} to login.`);
            // }else{
            // const cookie = new Cookies();
            // cookie.set("session", response.data.data.token);
            localStorage.setItem("session", response.data.data.token);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.data.token}`;
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            //console.log("logged in", response.data.data)
            dispatch({
              type: USER_LOGGED_IN,
              payload: response.data.data.user,
            });
            // }
          }
        })
        .catch((err) => {
          var message = "Some server error has occurred.";
          if (err.response) {
            message = err?.response?.data?.userMessage;
          }
          toastr.error("Error", message);
        });
    }).catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });


};

export const logout = (dispatch) => {
  dispatch({
    type: USER_LOGGED_OUT,
    payload: null,
  });
}

export const checkToken = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  // const session = localStorage.getItem("session");
  // if (session) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${props.token}`;

  axios
    .get(`${API_URL}/ping`, {})
    .then((response) => {
      debugger;
      if (response.data.error) {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: null,
        });

        toastr.error("Error", response?.data?.userMessage);
      } else {
        localStorage.setItem("session", props.token);
        localStorage.setItem("refreshToken", props.refreshToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${props.token}`;
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        dispatch({
          type: USER_LOGGED_IN,
          payload: response.data.data,
        });
        window.location.replace("/");
        // toastr.success("Success", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err?.response?.data?.userMessage;
      }
      toastr.error("Error", message);

      dispatch({
        type: USER_LOGGED_OUT,
        payload: null,
      });
      localStorage.clear();
      window.location.replace("/login");
    });
  // } else {
  //   dispatch({
  //     type: USER_LOGGED_OUT,
  //     payload: null,
  //   });
  // }
};