import axios from "axios";
import { API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import {
  LOOK_ALIKE_FETCHED,
  DOMAIN_SQUATING,
  LOOK_ALIKE_FETCHED_BY_ID,
  DOMAIN_SQUATING_TOTAL,
  DOMAIN_SQUATING_GRAPH,
  DOMAIN_SQUATING_DOMAIN
} from "./index";
import { toastr } from "react-redux-toastr";



export const fetchDomainsById = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);

  if (props !== undefined) {
    axios
      .get(`${API_URL}/getScanDetailsById/${props}`)
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: LOOK_ALIKE_FETCHED_BY_ID,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: LOOK_ALIKE_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const updateDomainStatus = (status, _id, index, paramId, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/updateDomainStatus`, {
      _id: _id,
      status: status,
      index: index,
      paramId: paramId,
    })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.userMessage);
      } else {
        // toastr.success("Success", "Record updated.");
        dispatch({
          type: LOOK_ALIKE_FETCHED_BY_ID,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: LOOK_ALIKE_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const domainSquating = (props, dispatch ,page ,category ,subdomain , type) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);
  if (props !== undefined) {
    axios
    .get(`${API_URL}/organisations/${props}/domainsquatting/delta?page=${page}&category=${category}&subdomain=${subdomain}&type=${type}`)
    .then((response) => {
        //console.log(response.data);
        dispatch({
          type: DOMAIN_SQUATING,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: DOMAIN_SQUATING,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const domainSquatingAction = (props, dispatch, data, page ,category, subdomain ) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);
  if (props !== undefined) {
    axios
    .post(`${API_URL}/organisations/${props}/domainsquatting/action?page=${page}&category=${category}&subdomain=${subdomain}`,data)
    .then((response) => {
        //console.log(response.data);
        dispatch({
          type: DOMAIN_SQUATING,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: DOMAIN_SQUATING,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const domainSquatingDomain = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);
  if (props !== undefined) {
    axios
    .get(`${API_URL}/organisations/${props}/domainsquatting/domains`)
    .then((response) => {
        //console.log(response.data);
        dispatch({
          type: DOMAIN_SQUATING_DOMAIN,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: DOMAIN_SQUATING_DOMAIN,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const domainSquatingTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);
  if (props !== undefined) {
    axios
    .get(`${API_URL}/organisations/${props}/domainsquatting/totals`)
    .then((response) => {
        //console.log(response.data);
        dispatch({
          type: DOMAIN_SQUATING_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: DOMAIN_SQUATING_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const domainSquatingExcel = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);
  if (props !== undefined) {
    axios
    .get(`${API_URL}/organisations/${props}/domainsquatting/graph`)
    .then((response) => {
        //console.log(response.data);
        dispatch({
          type: DOMAIN_SQUATING_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: DOMAIN_SQUATING_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};


export const takeDown = (props, dispatch) => {
  const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    axios
      .post(`${API_URL}/takeDown`, { ...props })
      .then((response) => {
        // window.location.href = '/customers'
        if(response.data.error)
          toastr.error("Error", response.data.userMessage);
        else
          toastr.success("Success", response.data.userMessage);
        dispatch({
          type: DOMAIN_SQUATING,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
  
        dispatch({
          type: DOMAIN_SQUATING,
          payload: { error: true, userMessage: message },
        });
      });
}