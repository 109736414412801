import { SOURCE_CODE_FETCHED } from "../actions";

const initialState = {
  loading: true,
  sourceCode: [],
};

const sourceCode = (state = initialState, action) => {
  switch (action.type) {
    case SOURCE_CODE_FETCHED:
      return {
        ...state,
        sourceCode: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default sourceCode;
