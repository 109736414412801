import {SEARCH_PRDOUCT_VENDOR , SEARCH_PRDOUCT_PRODUCT} from "../actions";

export default function (
  state = { loading: true, response: null ,  responseProduct:null },
  action
) {
  switch (action.type) {
    case SEARCH_PRDOUCT_VENDOR:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };
      case SEARCH_PRDOUCT_PRODUCT:
      return{
        ...state,
        responseProduct: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}
