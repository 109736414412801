import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

var  config = { 
  apiKey: "AIzaSyCI_HUmP-iKGhEjtRRFqR00sNOFcAzk0F8",
  authDomain: "brandsek-1c5ed.firebaseapp.com",
  projectId: "brandsek-1c5ed",
  storageBucket: "brandsek-1c5ed.appspot.com",
  messagingSenderId: "56657639165",
  appId: "1:56657639165:web:16840e9459549145427094",
  measurementId: "G-CSBT92KZNZ"
};

const authOptions = {
  // Add your authentication options here
  // For example, you might set persistence:
  persistence: "session" // or "local" or "none"
};

firebase.initializeApp(config);
// const auth = getAuth(app);
const db = getFirestore();

export default firebase;
