import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class RealtimeChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                data: [
                    [0, 53.08330533680049],
                    [1, 50.33339517545416],
                    [2, 49.4029746664779],
                    [3, 47.791939081203566],
                    [4, 49.09471219192674],
                    [5, 50.66529743518582],
                    [6, 48.749718825997206],
                    [7, 48.84333276982059],
                    [8, 53.51394720398375],
                    [9, 52.93467940905747],
                    [10, 49.083909652316756],
                    [11, 50.27480737843102],
                    [12, 48.37957308101624],
                    [13, 44.84022012471776],
                    [14, 40.71830916489318],
                    [15, 41.24962375997834],
                    [16, 45.63889630450356],
                    [17, 44.66117259629492],
                    [18, 41.393918522372914],
                    [19, 38.20495807999945],
                    [20, 39.68970488580452],
                    [21, 41.02366924388095],
                    [22, 39.41137193753915],
                    [23, 35.66049049363585],
                    [24, 38.5316402746093],
                    [25, 38.536952802123125],
                    [26, 40.69853423533536],
                    [27, 38.79970643855877],
                    [28, 42.98845795943349],
                    [29, 46.360136088412915],
                    [30, 43.5528691841886],
                    [31, 40.65605934650181],
                    [32, 36.5040222131244],
                    [33, 31.79517009935011],
                    [34, 28.913911507798105],
                    [35, 29.681580006957674],
                    [36, 29.57017024157237],
                    [37, 33.13695968240512],
                    [38, 37.084637076369454],
                    [39, 35.86922272605444],
                    [40, 37.60007436604805],
                    [41, 39.6599902960551],
                    [42, 39.01855935146662],
                    [43, 34.101066517369006],
                    [44, 37.486228204869676],
                    [45, 39.29733687111992],
                    [46, 38.46411897069526],
                    [47, 37.71927995665536],
                    [48, 40.15208911247334],
                    [49, 35.897096450476575],
                    [50, 31.505997358944384],
                    [51, 31.816999110802946],
                    [52, 30.50460962834996],
                    [53, 25.741310049337464],
                    [54, 28.23602445151448],
                    [55, 28.48317685385772],
                    [56, 30.001070495921475],
                    [57, 32.164958534602505],
                    [58, 32.99295659942683],
                    [59, 37.68193430054417],
                    [60, 35.24212764591677],
                    [61, 39.18772362995824],
                    [62, 41.376347845481895],
                    [63, 41.45950716612605],
                    [64, 43.78985456358012],
                    [65, 39.416694565047884],
                    [66, 39.32972776309515],
                    [67, 43.80480524720717],
                    [68, 42.434410137245514],
                    [69, 43.67300580223356],
                    [70, 38.79887604059381],
                    [71, 43.570128406921526],
                    [72, 41.81988828932836],
                    [73, 44.829528785933896],
                    [74, 46.19223595854988],
                    [75, 47.69550173883899],
                    [76, 49.010522215031536],
                    [77, 46.40480781018069],
                    [78, 51.28051836395483],
                    [79, 50.158430192052556],
                    [80, 53.60466613842059],
                    [81, 56.08734803007076],
                    [82, 52.72459300615355],
                    [83, 56.601951946760394],
                    [84, 60.26245067204903],
                    [85, 58.36945168202019],
                    [86, 56.59491823723127],
                    [87, 55.755294545253776],
                    [88, 54.74810139653445],
                    [89, 54.27203682664068],
                    [90, 58.659985887413185],
                    [91, 57.00658547275452],
                    [92, 60.52029839853601],
                    [93, 57.6015284629649],
                    [94, 56.48890586246457],
                    [95, 55.10455188969404],
                    [96, 54.357265081931686],
                    [97, 52.394359471010326],
                    [98, 54.52899302331695],
                    [99, 54.16762513026156],
                    [100, 51.95657669321307],
                    [101, 51.19677107897459],
                    [102, 46.35100350085707],
                    [103, 48.33623433000422],
                    [104, 45.84986413510889],
                    [105, 48.22054173701362],
                    [106, 43.30402458869659],
                    [107, 45.823705773087944],
                    [108, 43.48498341409474],
                    [109, 41.32116785138174],
                    [110, 40.99342590634263],
                    [111, 38.496913606221845],
                    [112, 40.10010461807938],
                    [113, 44.861885054292394],
                    [114, 44.03401133327108],
                    [115, 41.41251651321317],
                    [116, 37.800397369625514],
                    [117, 39.295001424962734],
                    [118, 35.24310363081255],
                    [119, 32.125154958611844],
                    [120, 35.68772234352005],
                    [121, 38.00169527592055],
                    [122, 37.960866448524754],
                    [123, 38.702527394097245],
                    [124, 37.457771477588224],
                    [125, 37.51129389195443],
                    [126, 33.108727543689724],
                    [127, 35.09710598798716],
                    [128, 33.11742126933996],
                    [129, 31.873922447406848],
                    [130, 29.18642792871095],
                    [131, 31.91579925678714],
                    [132, 34.370661166914054],
                    [133, 32.91433174216821],
                    [134, 33.17197835246117],
                    [135, 37.16446574836367],
                    [136, 32.60291809386715],
                    [137, 36.94627368938524],
                    [138, 35.9869296328639],
                    [139, 38.12898104938889],
                    [140, 42.55368007736426],
                    [141, 41.57493569939069],
                    [142, 45.54394197350075],
                    [143, 46.30674824728742],
                    [144, 45.73213644396193],
                    [145, 45.42768540578047],
                    [146, 42.52964420434585],
                    [147, 44.44398524408891],
                    [148, 39.74894644038498],
                    [149, 44.71669577260144]
                ]
            }],
            options: {
                chart: {
                    height: 300,
                    type: 'area',
                    stacked: false,
                    animations: {
                        enabled: true,
                        easing: 'linear',
                        dynamicAnimation: {
                            speed: 1000
                        }
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    show: false,
                    labels: { show: false }
                },
                yaxis: {
                    max: 90,
                    min: 0,
                    tickAmount: 6,
                    labels: {
                        formatter: function (value) {
                            return parseInt(value);
                        }
                    },
                },
                legend: {
                    show: false
                },
            },
        };
    }

    // componentDidMount() {
    //     window.setInterval(() => {
    //         getNewSeries(lastDate, {
    //             min: 10,
    //             max: 90
    //         })
    //         ApexCharts.exec('realtime', 'updateSeries', [{
    //             data: data
    //         }])
    //     }, 1000)
    // }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={300}
                />
            </div>
        );
    }
}

export default RealtimeChart;
