import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link, useParams } from "react-router-dom";
import SubscriberChart from "../common/dashboard9/subscribersChart";
import {
  fetchFakeSocialMedia,
  fetchFakeSocialMediaTotal,
  fetchFakeSocialMediaGraph,
  fetchSocailRemidiatedFalseTrue,
} from "../../actions/fakeMobileApp";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Verified } from "@mui/icons-material";
import { API_URL } from "../../utils/constant";
import axios from "axios";

const SocialMediaProfile = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const [category, setCategory] = useState("all");
  const [type, settype] = useState("all");
  const [platform, setPlatform] = useState("all");
  const [status, setStatus] = useState("all");

  const state = useSelector(
    (state) => state?.fakeMobileApp?.fakeSocialMedia?.data
  );

  const totalCount = useSelector(
    (state) => state?.fakeMobileApp?.totalSocialCount?.data
  );

  const scanCompleted = useSelector(
    (state) => state?.fakeMobileApp?.scanCompleted
  );

  let objArray = {};
  for (let count = 0; count < state?.length; count++) {
    let createdAt = state[count].createdAt.split("T")[0];
    if (objArray[createdAt]) {
      objArray[createdAt].push(state[count]);
    } else {
      objArray[createdAt] = [state[count]];
    }
  }

  const scanDetails = useSelector(
    (state) => state?.fakeMobileApp?.fakeGraphSocial?.data
  );

  useEffect(() => {
    fetchFakeSocialMedia(id, dispatch, page, category, platform, status);
  }, [id, dispatch, page, category, platform, status]);

  useEffect(() => {
    fetchFakeSocialMediaGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchFakeSocialMediaTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(state?.totalProfiles);
  }, [state?.totalProfiles]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        fakeSocialMediaIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      fakeSocialMediaIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      fakeSocialMediaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, category, type);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  let mapData = state;
  mapData = state?.data ? state?.data : null;
  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/getFakeSocialMediav2/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Social Media Page.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Social Media Profiles</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalCount?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && state?.length !== 0 && (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    style={{
                      float: "right",
                       
                      fontWeight: "bold",
                    }}
                    href="/add-report"
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o mr-1"
                      style={{  }}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        {/* <div className="row clearfix">
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-indigo text-white rounded-circle">
                    <i className="fa fa-briefcase"></i>
                  </div>
                  <div className="ml-4">
                    <span>Total Profiles</span>
                    <h4 className="mb-0 font-weight-medium">12</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-azura text-white rounded-circle">
                    <i className="fa fa-credit-card"></i>
                  </div>
                  <div className="ml-4">
                    <span>Detected as Issues</span>
                    <h4 className="mb-0 font-weight-medium">12</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-orange text-white rounded-circle">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="ml-4">
                    <span>Change in last 30 days</span>
                    <h4 className="mb-0 font-weight-medium">$25,805</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-pink text-white rounded-circle">
                    <i className="fa fa-life-ring"></i>
                  </div>
                  <div className="ml-4">
                    <span>Change in last 90 days</span>
                    <h4 className="mb-0 font-weight-medium">$13,651</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row clearfix">
          <div
            className="col-md-4 col-sm-12"
            style={{  }}
          >
            <div className="card card-box">
              <div className="body" style={{ }}>
                <div className="row text-center">
                  {/* <div className="col-lg-12 col-sm-12 border-right pb-4 pt-4">
                    <label className="mb-20">Impact Score</label>
                    <h4 className="font-30 font-weight-bold text-col-blue">
                      78
                    </h4>
                  </div> */}

                  {/* <div className="col-lg-6 col-sm-12 pb-4 pt-4">
                    <label className="mb-0">Registered in last 30 days</label>
                    <h4 className="font-30 font-weight-bold text-col-blue">
                      {registerInLast30?.length || 0}
                    </h4>
                  </div> */}
                </div>
                
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    // onClick={() => setfilterData("active")}
                  >
                    Fake Facebook Profiles
                    <span className="float-right">
                      {totalCount?.totalFacebookProfiles || 0}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-success"
                      role="progressbar"
                      // aria-valuenow={(activePercent || 0)?.toFixed(0)}
                      // aria-valuemin="0"
                      // aria-valuemax="100"
                      // style={{ width: (activePercent || 0)?.toFixed(0) + "%" }}
                    ></div>
                  </div>
                </div>
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    // onClick={() => setfilterData("60days")}
                  >
                    Fake LinkedIn Profiles
                    <span className="float-right">
                      {totalCount?.totalLiknedInProfiles || 0}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      // aria-valuenow={(last60DaysPercent || 0)?.toFixed(0)}
                      // aria-valuemin="0"
                      // aria-valuemax="100"
                      // style={{
                      //   width: (last60DaysPercent || 0)?.toFixed(0) + "%",
                      // }}
                    ></div>
                  </div>
                </div>
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    // onClick={() => setfilterData("60days")}
                  >
                    Fake Twitter Profiles
                    <span className="float-right">
                      {totalCount?.totalTwitterProfiles || 0}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-info"
                      role="progressbar"
                      // aria-valuenow={(last60DaysPercent || 0)?.toFixed(0)}
                      // aria-valuemin="0"
                      // aria-valuemax="100"
                      // style={{
                      //   width: (last60DaysPercent || 0)?.toFixed(0) + "%",
                      // }}
                    ></div>
                  </div>
                </div>
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    // onClick={() => setfilterData("30days")}
                  >
                    Fake Instagram Profiles
                    <span className="float-right">
                      {totalCount?.totalInstagramProfiles || 0}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-warning"
                      role="progressbar"
                      // aria-valuenow={(last30DaysPercent || 0)?.toFixed(0)}
                      // aria-valuemin="0"
                      // aria-valuemax="100"
                      // style={{
                      //   width: (last30DaysPercent || 0)?.toFixed(0) + "%",
                      // }}
                    ></div>
                  </div>
                </div>

                {client?.subscriptionType == "trial" && (
                  <div className="card border-warning">
                    <TrialComponent />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="card  white-box-dashboard">
              <div className="body" style={{ minHeight: "400px" }}>
                <h6>Previous scan details</h6>
                {scanDetails && (
                  <SubscriberChart
                    dates={scanDetails?.dates}
                    data={scanDetails?.scanDetails}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-table-tab nav-link ${
                category === "all" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("all");
                settype("all");
                setPage(1);
                setStatus("all");
                setPlatform("all");
                setSelectedIds([]);
              }}
            >
              Current
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "risk-accepted" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("risk-accepted");
                settype("all");
                setPage(1);
                setStatus("all");
                setPlatform("all");
                setSelectedIds([]);
              }}
            >
              Risk Accepted
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "false-positive" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("false-positive");
                settype("all");
                setPage(1);
                setStatus("all");
                setPlatform("all");
                setSelectedIds([]);
              }}
            >
              False Positive
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "remidiated" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("remidiated");
                settype("all");
                setPage(1);
                setStatus("all");
                setPlatform("all");
                setSelectedIds([]);
              }}
            >
              Remediated
            </a>
          </li>
        </ul>
        <div className="card">
          <div className="body">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={platform}
                      onChange={(e) => setPlatform(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="facebook">Facebook</option>
                      <option value="linkedin">Linkedin</option>
                      <option value="twitter">Twitter</option>
                      <option value="instagram">Instagram</option>
                    </select>
                    {/* <Autocomplete
                      // multiple
                      style={{ width: "100%" }}
                      id="tags-standard"
                      options={platform || []}
                      value={filterData}
                      onChange={(event, newValue) => {
                        setfilterData(newValue);
                      }}
                      getOptionLabel={(option) => option?.toUpperCase()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          // label="Multiple values"
                          placeholder="Platform"
                        />
                      )}
                    /> */}
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="verified">Verified</option>
                      <option value="unverified">Unverified</option>
                      <option value="linkdown">Takedown</option>
                    </select>
                    {/* <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      id="tags-standard"
                      options={statusFilter || []}
                      value={statusFilterVal}
                      onChange={(event, newValue) => {
                        setStatusFilter(newValue);
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          // label="Multiple values"
                          placeholder="Status"
                        />
                      )}
                    /> */}
                  </div>
                  <div className="col-lg-4 col-md-12">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>
                <div className="table-responsive" ref={myRef}>
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {(category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th>#No</th>
                        <th>Status</th>
                        <th>Platform</th>
                        <th>Page URL</th>
                        <th>Last Updated at</th>
                        {(category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={5}>
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((res, index) => {
                          let source =
                            res?.source || res?.socialMediaPlatform || "NA";
                          return (
                            <tr key={index}>
                              {(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(res?._id)}
                                    checked={selectedIds?.includes(res?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                              <td>
                                <span>{index + 1}</span>
                              </td>
                              <td>
                                {" "}
                                <span
                                  className={
                                    res?.isSuspicious
                                      ? "badge badge-success"
                                      : "badge badge-danger"
                                  }
                                >
                                  {res?.isSuspicious
                                    ? "Verified"
                                    : "Unverified"}
                                </span>
                                {res?.isLinkDown && (
                                  <span className="badge badge-warning">
                                    Take down
                                  </span>
                                )}
                              </td>
                              <td>
                                {source.charAt(0).toUpperCase() +
                                  source.slice(1)}
                              </td>
                              {/* <td>{res.name}</td> */}
                              {/* <td>{res.subTitle}</td> */}

                              <td style={{ whiteSpace: "unset" }}>
                                <a
                                  href={`${res?.link || res?.fakePageUrl}`}
                                  target="_blank"
                                  style={{  }}
                                >
                                  {res?.link || res?.fakePageUrl}
                                </a>
                              </td>
                              {/* <td>{res.followers}</td> */}

                              <td>
                                {res?.updatedAt
                                  ? moment(res?.updatedAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : res?.createdAt
                                  ? moment(res?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : "NA"}
                              </td>
                              {category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(res?._id, event.target.value);
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([res._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([res._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {(category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(res?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className=" ">
                  <div className="card mb-0">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            className="form-control"
                            value={actionNote}
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaProfile;
