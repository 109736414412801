import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCVEDetails,
  fetchCVEDetailsGraph,
  fetchCVEDetailsTotal,
  fetchCVEDetailsAction,
} from "../../actions/vulnerability";
// import { Link  , useParams} from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import BasicLineColumn from "../common/apexChart/lineChart";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Donut } from "react-dial-knob";
import GaugeChart from "react-gauge-chart";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KnownsubscribersChart from "../common/dashboard9/knownsubscribersChart";

const KnownVulnerability = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [search, setsearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let table = useSelector(
    (state) => state.vulnerability?.cveDetails?.data?.data
  );

  let totalCount = useSelector((state) => state.vulnerability?.cveTotal?.data);

  const pagination = useSelector(
    (state) => state.vulnerability?.cveDetails?.data
  );

  let scanCompleted = useSelector(
    (state) => state.vulnerability?.scanCompleted
  );
  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const scanDetails = useSelector(
    (state) => state?.vulnerability?.cveGraph?.data
  );
  console.log(scanDetails, "scan");

  const iPDetails = useSelector(
    (state) => state?.vulnerability?.cveDetails?.scanDetailArray?.ipDetails
  );

  useEffect(() => {
    if (orgId) {
      fetchCVEDetails(orgId, dispatch, page, PageSize, search, category);
    } else {
      fetchCVEDetails(id, dispatch, page, PageSize, search, category);
    }
  }, [id, dispatch, page, PageSize, search, category]);

  useEffect(() => {
    setTotalItems(pagination?.cveDetailsRecord);
  }, [pagination?.cveDetailsRecord]);

  useEffect(() => {
    fetchCVEDetailsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchCVEDetailsGraph(id, dispatch);
  }, [id, dispatch]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        pvaIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchCVEDetailsAction(
        id,
        dispatch,
        page,
        PageSize,
        search,
        category,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      pvaIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      pvaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails?.[details]?.length || 0);
  }

  let mapData = table;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/cve-details/${id}/excel`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "IP Vulnerability Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const severity = (val) => {
    if (val < 4) {
      return "Low";
    }
    if (val >= 4 && val < 7) {
      return "Medium";
    }
    if (val >= 7 && val < 9) {
      return "High";
    }
    if (val >= 9) {
      return "Critical";
    }
  };

  const severityBatch = (val) => {
    if (val < 4) {
      return "badge badge-low";
    }
    if (val >= 4 && val < 7) {
      return "badge badge-med";
    }
    if (val >= 7 && val < 9) {
      return "badge badge-high";
    }
    if (val >= 9) {
      return "badge badge-critical";
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Passive Vulnerability Assessment</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{  }}
                    >
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalCount?.individualRating}</b>
                </span>
              </nav>
              {/* <p>Grade : {riskScore || "Calculating..."}</p> */}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-12 col-md-8">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("critical");
                      setsearch("critical");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={totalCount?.totalCriticalVulnerabilities}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#DC2B2B",
                          centerColor: "#f7dad7",
                          bgrColor: "#DC2B2B",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      {/* <h4 className="mb-0">34.80%</h4> */}
                      <h4 className="mb-0">Critical</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("high");
                      setsearch("high");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={totalCount?.totalHighVulnerabilities}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#EE4B2B",
                          centerColor: "#f7dad7",
                          bgrColor: "#EE4B2B",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">High</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("medium");
                      setsearch("medium");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={totalCount?.totalMediumVulnerabilities}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#FF8C00",
                          centerColor: "#f7dad7",
                          bgrColor: "#FF8C00",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">Medium</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box mr-0">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("low");
                      setsearch("low");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={totalCount?.totalLowVulnerabilities}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#48B461",
                          centerColor: "#f7dad7",
                          bgrColor: "#48B461",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">Low</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card  white-box-dashboard">
              <div className="body">
                <div className="header">
                  <h6>Previous scan details</h6>
                </div>
                {scanDetails && (
                  <KnownsubscribersChart
                    dates={scanDetails?.dates}
                    data={scanDetails?.scanDetails}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-table-tab nav-link ${
                    category === "all" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("all");
                    setPage(1);
                    setsearch("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  Current
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "risk-accepted" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("risk-accepted");
                    setPage(1);
                    setsearch("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  Risk Accepted
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "false-positive" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("false-positive");
                    setPage(1);
                    setfilterData("all");
                    setsearch("all");
                    setSelectedIds([]);
                  }}
                >
                  False Positive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "remidiated" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("remidiated");
                    setPage(1);
                    setfilterData("all");
                    setsearch("all");
                    setSelectedIds([]);
                  }}
                >
                  Remediated
                </a>
              </li>
            </ul>
            <div className="card">
              <div className="body">
                <div className="header" ref={myRef}>
                  <div className="row" style={{}}>
                    <div className="col-sm-6" style={{ marginTop: "10px" }}>
                      <h6>Vulnerability Details</h6>
                    </div>
                    <div className="col-sm-3 text-right">
                      <select
                        className="custom-select custom-select-sm form-control form-control-sm"
                        value={filterData}
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setfilterData(e.target.value);
                          setPage(1);
                        }}
                      >
                        <option value="all">All</option>
                        <option value="critical">Critical</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </select>
                    </div>
                    <div className="col-sm-1 d-flex justify-content-end">
                      {mapData?.length === 0 ? null : (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          style={{ float: "right" }}
                          href="/add-report"
                          className="btn btn-primary mr-1"
                        >
                          <i className="fa fa-file-excel-o" style={{}}></i>
                        </a>
                      )}
                    </div>
                    <div className="col-sm-2">
                      {selectedIds.length > 1 && category === "all" && (
                        <Box sx={{ width: 200 }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedValue}
                              onChange={(event) => {
                                setAction(event.target.value);
                                snooze("", event.target.value);
                              }}
                            >
                              <MenuItem value="pleaseSelect">
                                Snooze Options
                              </MenuItem>
                              <MenuItem
                                value="riskAccepted"
                                onClick={() => {
                                  addModal();
                                }}
                              >
                                Risk Accepted
                              </MenuItem>
                              <MenuItem
                                value="markAsFalsePositive"
                                onClick={() => {
                                  addModal();
                                }}
                              >
                                False Positive
                              </MenuItem>
                              <MenuItem value="markAsRemidiated">
                                Remediated
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {selectedIds.length > 1 &&
                        (category == "false-positive" ||
                          category == "risk-accepted") && (
                          <button
                            className="btn btn-primary btn-sm mr-1 Unsnooze"
                            onClick={() => Unsnoozed("")}
                          >
                            Unsnooze
                          </button>
                        )}
                    </div>
                    <div className="col-lg-12">
                      <div className="table-responsive invoice_list mb-4">
                        <table className="table table-custom spacing5 mb-0">
                          <thead>
                            <tr>
                              {(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedIds == 0 ? false : multiCheckTrue
                                    }
                                    onClick={() => {
                                      multicheck(currentTableData);
                                    }}
                                    className="checkUncheck"
                                  />
                                </th>
                              )}
                              <th style={{ width: "80px" }}>IP Address</th>
                              {/* <th style={{ width: "150px" }}>Name</th> */}

                              <th style={{ width: "140px" }}>CVE Name</th>
                              <th style={{ width: "140px" }}>Severity</th>
                              <th style={{ width: "140px" }}>Ageing</th>
                              <th style={{ width: "200" }}>Description</th>
                              {(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th style={{ width: "80" }}>Action</th>
                              )}
                              {/* <th style={{ width: "500px" }}>Solutions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData?.map((row) => {
                              return (
                                <tr>
                                  {(category === "all" ||
                                    category === "risk-accepted" ||
                                    category === "false-positive") && (
                                    <td>
                                      <input
                                        onClick={() => singleClick(row?._id)}
                                        checked={selectedIds?.includes(
                                          row?._id
                                        )}
                                        type="checkbox"
                                        className="checkUncheck"
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.ip}
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.cveId}
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    <span
                                      className={
                                        row?.severity === "medium"
                                          ? "badge badge-med"
                                          : row?.severity === "low"
                                          ? "badge badge-low"
                                          : row?.severity === "high"
                                          ? "badge badge-high"
                                          : row?.severity === "critical"
                                          ? "badge badge-critical"
                                          : "N/A"
                                      }
                                    >
                                      {row?.severity}
                                    </span>
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {moment().diff(
                                      moment(row?.cvePublishedAt),
                                      "days"
                                    )}{" "}
                                    days
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.description}
                                  </td>

                                  {/* <td style={{ whiteSpace: "unset" }}>
                            Integer overflow in the Internet Printing Protocol
                            (IPP) ISAPI extension in Microsoft Internet
                            Information Services (IIS) 5.0 through 7.0 on
                            Windows 2000 SP4, XP SP2 and SP3, Server 2003 SP1
                            and SP2, and Server 2008 allows remote authenticated
                            users to execute arbitrary code via an HTTP POST
                            request."{" "}
                          </td> */}
                                  {category === "all" && (
                                    <td>
                                      <Box sx={{ width: 200 }}>
                                        <FormControl fullWidth>
                                          {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={(event) => {
                                              setAction(event.target.value);
                                              snooze(
                                                row?._id,
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value="pleaseSelect">
                                              Snooze Options
                                            </MenuItem>
                                            <MenuItem
                                              value="riskAccepted"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              Risk Accepted
                                            </MenuItem>
                                            <MenuItem
                                              value="markAsFalsePositive"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              False Positive
                                            </MenuItem>
                                            <MenuItem value="markAsRemidiated">
                                              Remediated
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </td>
                                  )}
                                  {(category === "risk-accepted" ||
                                    category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() => Unsnoozed(row?._id)}
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {totalItems > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={totalItems}
                            pageSize={10}
                            onPageChange={(page) => {
                              setPage(page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row clearfix"></div>
            </div>
          </div>
        </div>
        <div className="row clearfix"></div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
              <div className="">
              <div className="card mb-0">
                    <div className="row clearfix">
                       
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                       
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnownVulnerability;
