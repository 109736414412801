import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';


class LineEventChart extends Component {
    constructor(props) {
        super(props);
        let expired = [],vuln=[];
        for(let count = 0 ; count < props?.graphObj?.length; count++){
            expired.push(props?.graphObj[count]?.expired)
            vuln.push(props?.graphObj[count]?.vulns)
        }
        this.state = {
            series: [
                {
                    name: "Expired",
                    data: expired
                },
                {
                    name: "Vulnerable",
                    data: vuln
                }
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#ff0000', '#FFA500'],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    width: 1
                },
                markers: {
                    size: 1
                },
                labels:  props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                xaxis: {
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    // categories: ['Feb 15', 'Feb 16', 'Feb 17', 'Feb 18', 'Feb 19', 'Feb 20', 'Feb 21', 'Feb 22', 'Feb 23', 'Feb 24']
                },
                yaxis: {
                    // min: 0,
                    // max: 500,

                },
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 0
                    }
                }
            },
        };
    }
    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={300} />
        );
    }
}

export default LineEventChart;
