import { FETCH_MOBILE_APP, FETCH_MOBILE_APP_TOTAL, FETCH_FAKE_SOCIAL_MEDIA_EXCEL, FETCH_FAKE_SOCIAL_MEDIA_TOTAL, FETCH_MOBILE_APP_EXCEL ,FETCH_FAKE_SOCIAL_MEDIA, FETCH_DARK_WEB_MENTION, FETCH_WEB_DEFACEMENT } from "../actions";
const initialState = {
  mobileData: [],
  fetchedMobileData: [],
  fakeSocialMedia: [],
  darkWebMention: [],
  webDefacement: [],
  totalCount:[],
  totalSocialCount:[],
  totalExcel:[],
  fakeGraphSocial:[],
  scanCompleted: true
};

const fakeMobileApp = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MOBILE_APP:
      return {
        ...state,
        mobileData: action.mobileData,
        scanCompleted: action.mobileData.scanCompletd
      };
      case FETCH_FAKE_SOCIAL_MEDIA_TOTAL:
        return {
          ...state,
          totalSocialCount: action.fakeSocialCount,
        }; 
    case FETCH_MOBILE_APP_TOTAL:
    return {
      ...state,
      totalCount:action.mobileData
    };
    case FETCH_MOBILE_APP_EXCEL:
      return{
      ...state,
      totalExcel:action.mobileData
      }
      case FETCH_FAKE_SOCIAL_MEDIA_EXCEL:
        return{
        ...state,
        fakeGraphSocial:action.mobileData
        }
    case "FETCHED_MOBILE_DATA":
      return {
        ...state,
        fetchedMobileData: action.fetchedMobileData,
      };
    case "FETCH_FAKE_SOCIAL_MEDIA":
      return {
        ...state,
        fakeSocialMedia: action.mobileData,
        scanCompleted: action.mobileData.scanCompletd
      };
    case "FETCH_DARK_WEB_MENTION":
      return {
        ...state,
        darkWebMention: action.data,
      };
    case FETCH_WEB_DEFACEMENT:
      return {
        ...state,
        webDefacement: action.data,
      };
    default:
      return state;
  }
};

export default fakeMobileApp;
