import {  PARTNER_REQUESTS_FETCHED } from "../actions/";

export default function(state = { loading: true, response: null, list: null }, action) {
  switch (action.type) {
    
    case PARTNER_REQUESTS_FETCHED:
      return {...state,response: action.payload, list: !action.payload.error ? action.payload.data : [], loading: false};
    default:
      return state;
  }
}
