import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { addRef } from "../../actions/cveInformation";
import { useSelector, useDispatch } from "react-redux";


const NewReference = ({ _id, cveId, onCancel }) => {
  const { cveId: cveID } = useParams();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [referenceUrl, setReferenceUrl] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [referenceSource, setReferenceSource] = useState("");
  const [referenceTags, setReferenceTags] = useState(["tag1", "tag2"]);
  const [newReferenceTag, setNewReferenceTag] = useState("");

  const addTag = (tag) => {
    setReferenceTags((prevTags) => [...prevTags, tag]);
  };

  const removeTag = (tag) => {
    setReferenceTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const saveReference = () => {
    addRef(dispatch, cveId , _id,referenceUrl,referenceName,referenceSource,referenceTags)
  };

  return (
    <div className="h-full w-full absolute flex items-center justify-center backdrop-blur-md z-10" 
    style={{width:'99% !important'}}
    >
      <div className="flex  flex-col gap-3 w-1/2 p-4  modalCustom">
        <h1>
          Add new reference to {cveId}
        </h1>
        <div className="flex flex-col gap-1">
          <label htmlFor="reference-url" className="font-bold mb-0">
            Reference url
          </label>
          <input
            name="reference-url"
            type="url"
            value={referenceUrl}
            onChange={(e) => {
              setReferenceUrl(e.target.value);
            }}
            className="p-2 rounded-md focus:outline-none form-control"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="reference-name" className="font-bold  mb-0">
            Reference name
          </label>
          <input
            name="reference-name"
            type="text"
            value={referenceName}
            onChange={(e) => {
              setReferenceName(e.target.value);
            }}
            className="p-2 rounded-md focus:outline-none form-control"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="reference-source" className="font-bold  mb-0">
            Reference source
          </label>
          <input
            name="reference-source"
            type="text"
            value={referenceSource}
            onChange={(e) => {
              setReferenceSource(e.target.value);
            }}
            className="p-2 rounded-md focus:outline-none form-control"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-bold  mb-0">Reference Tags</label>
          <div className="flex flex-row flex-wrap">
            {referenceTags.map((reference, i) => {
              return (
                <div
                  className="badge badge-cve"
                  key={i}
                  style={{}}
                >
                  <p className="mb-0 text-black mr-1 ml-0">{reference}</p>
                  <button
                    onClick={() => {
                      removeTag(reference);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 flex-nowrap p-1 bg-slate-300 rounded-md">
            <input
              value={newReferenceTag}
              onChange={(e) => {
                setNewReferenceTag(e.target.value);
              }}
              className="w-full p-2 rounded-md focus:outline-none form-control"
            />
            <button
            className="btn ml-auto flex-shrink-0 btn-primary"
              onClick={() => {
                addTag(newReferenceTag);
                setNewReferenceTag("");
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div className="flex flex-row flex-nowrap gap-3 justify-end py-2 mt-3">
          <button
            onClick={onCancel}
            className="btn  btn-primary btn-brder-detail pull-right"
          >
           <i class="fa-solid fa-xmark mr-1"></i> Cancel
          </button>
          <button
            onClick={saveReference}
            className="btn btn-primary mr-1 pull-right"
          >
           <i class="fa-solid fa-floppy-disk mr-1"></i> Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewReference;
