import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link  , useParams} from "react-router-dom";
import { fetchPhishingPage } from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import moment from "moment";
import AccessPage from "../Shared/accessPage";

const PhishingPage = () => {
const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const vendorName = sessionStorage.getItem('vendorName');  
const vendorId = sessionStorage.getItem('vendorId');
  const state = useSelector(
    (state) => state?.leakedCredential?.phishingPage?.data
  );
  const scanCompleted = useSelector(
    (state) => state?.phishingPage?.scanCompleted
  );

  let oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.phishingPage?.scanDetailArray
  );

  let ipArray = [];
  for (
    let details = 0;
    details < oldDnsDetails?.scanDetails?.length;
    details++
  ) {
    ipArray.push(oldDnsDetails?.scanDetails[details]?.length);
  }
  //console.log(state, "oldDnsDetails?.dates", oldDnsDetails, ipArray);
  let totalIp = 0,
    plainPasswords = 0;
  if (oldDnsDetails?.scanDetails) {
    totalIp = oldDnsDetails?.ipLength || 0;
    plainPasswords = oldDnsDetails?.plainPassword || 0;
  }
  //console.log("stolen");

  useEffect(() => {
    fetchPhishingPage(id, dispatch);
  }, [id]);

  let response = state;

  const plainPasswordPercent = ((plainPasswords || 0) / (totalIp || 0)) * 100;

  const userBreachedMultiple = response?.filter(
    (row) => row.sources?.length > 1
  );
  const breachedPercent =
    ((userBreachedMultiple?.length || 0) / (totalIp || 0)) * 100;

  let mapData =
    search == ""
      ? response
      : response?.filter(
          (row) =>
            row?.email?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
        );

  let currentTableData = [];
  //console.log(mapData, "mapDAta");
  if (mapData && mapData.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const downloadCsvData = response?.map((res) => {
    if (client?.subscriptionType == "trial") {
      return {
        BreachedCount: res?.sources?.length || 1,
        BreachedDatabase: JSON.stringify(
          res?.sources?.map((item) => item)
        )?.replace(/[\[\]']+/g, ""),
        EmailAddress: res?.line?.split(":")[0],
        Password: res?.line?.split(":")[1],
        LastBreach: res?.last_breach || "",
      };
    } else {
      return {
        PasswordType: res?.dec == 0 ? "Decrypted" : "Plain Password",
        EmailAddress: res?.eml || "NA",
        Password: res?.pwd || "NA",
        Source: res?.src || "NA",
        Description: res?.atr || "NA",
        Date: res?.imp
          ? res?.imp?.toString().substr(0, 4) +
            "-" +
            res?.imp?.toString().substr(4, 2) +
            "-" +
            res?.imp?.toString().substr(6, 2)
          : "NA",
      };
    }
  });

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Phishing URLs</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor">Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{  }}
                    >
                      {vendorName}
                    </li> : ''
                }

                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card w_card3">
            <div className="body" style={{ minHeight: "460px" }}>
              <div className="row text-center">
                <div className="col-12 pb-4 pt-4">
                  <label className="mb-0">Records Found</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {state?.length || 0}
                  </h4>
                </div>
              </div>

      
              {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card">
            <div className="body" style={{ minHeight: "460px" }}>
              <div>Previous scan details</div>
              {/* <p className="text-muted">
                  The global and country campaign stats show how popular your ad
                  is relative to your other ads.
                </p> */}
              {/* <BasicLineColumn /> */}
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={ipArray}
                  color={["#6574cd"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4"></div>
        <div className="col-sm-3"></div>
        <div className="col-sm-4">
          <div
            data-test="datatable-input"
            class="mdb-datatable-filter flex-row"
          >
            <input
              class="form-control form-control-sm ml-0 my-1"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-1">
          {client?.subscriptionType != "trial" &&
            downloadCsvData?.length !== 0 && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  exportToCSV(downloadCsvData, "PhishingDetails");
                }}
                href="/add-report"
                className="btn btn-primary mr-1"
              >
                <i className="fa fa-file-excel-o"></i>
              </a>
            )}
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-12 col-sm-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>URL</th>
                    <th>IP</th>
                    <th>ASN</th>
                    <th>ISP</th>
                    <th>Country</th>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Open Ports</th>
                  </tr>
                </thead>
                <tbody>
                  {client?.subscriptionType == "trial" ? (
                    <tr>
                      <td colSpan={8}>
                        <NoData />
                      </td>
                    </tr>
                  ) : (
                    currentTableData?.map((row, index) => {
                      let _ip = row?.result?.url?.split("") || ["N", "A"];
                      let ports =
                        row?.result?.ports &&
                        row?.result?.ports?.indexOf(",") != -1
                          ? row?.result?.ports.split(",")
                          : [row?.result?.ports];
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <span className={"badge badge-danger"}>
                             Unverified
                            </span>
                          </td>
                          <td>
                            {row?.result?.url ? (
                              <a href={row?.result?.url} target="_blank">
                                <span
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {_ip?.map((record, indexs) => {
                                    if ((indexs + 1) % 50 == 0)
                                      return (
                                        <>
                                          <span>{record}</span>
                                          <br />
                                        </>
                                      );
                                    else
                                      return (
                                        <>
                                          <span>{record}</span>
                                        </>
                                      );
                                  })}
                                </span>
                              </a>
                            ) : (
                              <span>NA</span>
                            )}
                          </td>
                          <td>{row?.result?.ip}</td>
                          <td>{row?.result?.asn}</td>
                          <td>{row?.result?.isp || "NA"}</td>
                          <td>{row?.result.countryname || "NA"}</td>

                          <td>{row?.result?.title || "NA"}</td>
                          <td>
                            {row?.result?.date
                              ? moment(row?.result?.date).format("YYYY-MM-DD")
                              : "NA"}
                          </td>
                          <td>
                            {/* {row?.result?.ports || "NA"} */}
                            {ports?.map((vul, index) => {
                              return (
                                <>
                                  <span
                                    id={`ports${index}`}
                                    data-tip={vul}
                                    className={"badge badge-success"}
                                  >
                                    {vul}
                                  </span>
                                </>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="dataTables_paginate">
              {mapData && mapData?.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={mapData?.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PhishingPage;
