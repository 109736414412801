import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import { SEARCH_CVE, CVE_DATA, CVE_SEARCH_MONITORING, CVE_MONITORING_TECH, CVE_MONITORING_TECH_SUBS } from "./index";
import { toastr } from "react-redux-toastr";
// import { useHistory } from "react-router-dom";
// import { toastr } from 'react-redux-toastr';



export const searchCVE = (dispatch, send, page, limit) => {
  // if(props){
  axios
    .post(`${API_URL}/search-vulnerabilities?page=${page}&limit=${limit}`,
      send)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // clear();
        toastr.success("Success", response.data.userMessage);
      }
      dispatch({
        type: SEARCH_CVE,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
}

export const CVEDetail = (dispatch, cveId) => {
  // if(props){
  axios
    .get(`${API_URL}/cve-info/${cveId}`)
    .then((res) => {
      dispatch({
        type: CVE_DATA,
        payload: res.data,
      });
    });
}


export const cveMonitoringSearch = (dispatch, search) => {
  // if(props){
  axios
    .get(`${API_URL}/tech-products?search=${search}`)
    .then((res) => {
      dispatch({
        type: CVE_SEARCH_MONITORING,
        payload: res.data,
      });
    });
}

export const cveMonitoringSubscribed = (data) => {
  // if(props){
  axios
    .post(`${API_URL}/tech-cve-subs`, data)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // clear();
        toastr.success("Success", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
}

export const cveMonitoringTech = (dispatch, id, page, filterData, search, techsearch, date) => {
  axios
    .get(`${API_URL}/${id}/tech-cves-info?page=${page}&limit=10&hasKnownExploits=${filterData}&minCvssScore=${search}&tech=${techsearch}`)
    .then((res) => {
      dispatch({
        type: CVE_MONITORING_TECH,
        payload: res.data,
      });
    });
}

export const cveMonitoringTechSubs = (dispatch, id, page) => {
  axios
    .get(`${API_URL}/tech-cve-subs/${id}/delta?page=${page}&limit=10`)
    .then((res) => {
      dispatch({
        type: CVE_MONITORING_TECH_SUBS,
        payload: res.data,
      });
    });
}

export const cveMonitoringTechSubsUpdate = (dispatch, id, data, orgId) => {
  axios
    .patch(`${API_URL}/tech-cve-subs/${id}`, data)
    .then((res) => {
      if (res.data.error) {
        toastr.error("Error", res.data.userMessage);
      } else {
        toastr.success("Success", res.data.userMessage);
      }
      cveMonitoringTechSubs(dispatch, orgId, 1)
      dispatch({
        type: CVE_MONITORING_TECH_SUBS,
        payload: res.data,
      });
    });
}

export const cveMonitoringTechSubscribeUnSubscribe = (dispatch, id, data) => {
  // if(props){
  axios
    .post(`${API_URL}/tech-cve-subs/${id}/action`, data)
    .then((res) => {
      if (res.data.error) {
        toastr.error("Error", res.data.userMessage);
      } else {
        toastr.success("Success", res.data.userMessage);
      }
      dispatch({
        type: CVE_MONITORING_TECH_SUBS,
        payload: res.data,
      });
    });
}

export const cveMonitoringTechDelete = (dispatch, id, data) => {
  // if(props){
  axios
    .post(`${API_URL}/tech-cve-subs/${id}/delete`, data)
    .then((res) => {
      if (res.data.error) {
        toastr.error("Error", res.data.userMessage);
      } else {
        toastr.success("Success", res.data.userMessage);
      }
      dispatch({
        type: CVE_MONITORING_TECH_SUBS,
        payload: res.data,
      });
    });
}


export const EditDes = (dispatch, cveId, _id, editedValue, fieldType) => {
  axios
    .post(`${API_URL}/update-cve`, {
      _id: _id,
      cveId: cveId,
      operationType: "update",
      field: fieldType,
      value: editedValue,
    })
    .then((res) => {
      dispatch({
        type: CVE_DATA,
        payload: res.data,
      });
      CVEDetail(dispatch, cveId)
      // window.location.reload();
    });
}

export const addRef = (dispatch, cveId, _id, referenceUrl, referenceName, referenceSource, referenceTags) => {
  axios
    .post(`${API_URL}/update-cve`, {
      _id,
      operationType: "add",
      field: "references",
      value: {
        cveId: cveId,
        url: referenceUrl,
        name: referenceName,
        refsource: referenceSource,
        tags: referenceTags,
      },
    })
    .then((res) => {
      dispatch({
        type: CVE_DATA,
        payload: res.data,
      });
      CVEDetail(dispatch, cveId)
    });
}

export const deleteReferenceApi = (dispatch, _id, referenceId) => {
  axios
    .post(`${API_URL}/update-cve`, {
      _id: _id,
      operationType: "delete",
      field: "references",
      value: referenceId,
    })
    .then((res) => {
      dispatch({
        type: CVE_DATA,
        payload: res.data,
      });
    });
}
