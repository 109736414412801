import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { PostManDetails } from "../../actions/leakedCredentials";
// import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $, { data } from "jquery";

const PostManMonitoringDetail = () => {
  const { routeId } = useParams();
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const dispatch = useDispatch();
  const [toggleArray, setToggleArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [isModalTrue, setModalSet] = useState(false);
  const [detail, setdetail] = useState([]);
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");
  const state = useSelector(
    (state) => state?.leakedCredential?.postmandetails?.data
  );
  const [category, setCategory] = useState(
    "interesting_values_found_in_authentication"
  );

  useEffect(() => {
    PostManDetails(routeId, id, dispatch);
  }, [routeId, id]);
  let mapData = [];
  mapData = state;

  mapData =
    search == ""
      ? mapData
      : mapData?.filter(
          (row) =>
            row?.chat_name?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
        );
  let filter = [];
  filter = mapData?.value?.map((row) => {
    let filteredRow = {};

    // Filter the keys based on the 'category' array
    if (row) {
      Object.keys(row).forEach((key) => {
        if (category.includes(key)) {
          filteredRow[key] = row[key];
        }
      });
    }

    return filteredRow;
  });

  // Check if filter is an array and has elements before accessing [0]
  let currentTableData =
    filter && filter?.length > 0 ? filter[0]?.[category] : [];

  if (currentTableData && currentTableData?.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = currentTableData?.slice(firstPageIndex, lastPageIndex);
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  // const downloadCsvData = state?.map((res) => ({
  //   // BreachedCount: res.count,
  //   // database_name: JSON.stringify(
  //   //   res?.database_name?.map((item) => item)
  //   // )?.replace(/[\[\]']+/g, "")?.replace(/['"]+/g, ""),
  //   chat_name: res.chat_name,
  //   first_name: res?.author?.first_name,
  //   text: res?.text,
  //   datetime: res?.datetime,
  // }));

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Postman monitoring</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Postman Monitoring
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {client?.currentRating?.dark_web_personalInfo}</b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {/* {downloadCsvData?.length === 0 ? null : ( */}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  // exportToCSV(downloadCsvData, "Personal Information");
                }}
                href="/add-report"
                style={{  fontWeight: "bold" }}
                className="btn btn-primary mr-1"
              >
                <i
                  className="fa fa-file-excel-o  mr-1"
                  style={{  }}
                ></i>{" "}
                Download Details
              </a>
              {/* )} */}
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card">
            <div className="body" style={{ minHeight: "440px" }}>
              {/* <div className="header">
                  <h2>Details</h2>
                </div> */}
              <div className="row text-center">
                <div className="col-12 pb-4 pt-4">
                  <label className="mb-0">Records Found</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {filter && (filter[0]?.[category]?.length || 0)}
                  </h4>
                </div>
              </div>

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("plain")}>
                  Plain Text Password{" "}
                  <span className="float-right">
                    {plainPasswordPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={plainPasswordPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: plainPasswordPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("multiple")}>
                  Users Breached Multiple Times{" "}
                  <span className="float-right">
                    {breachedPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-warning"
                    role="progressbar"
                    aria-valuenow={breachedPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: breachedPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("contact")}>
                  Contact Number/ address found{" "}
                  <span className="float-right">
                    {phonePasswordPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={phonePasswordPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: phonePasswordPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          {/* <div className="card">
            <div className="body" style={{ minHeight: "440px" }}>
              <h6>Previous scan Details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={dateArray}
                  ipArray={ipArray}
                  color={["#DD3731"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
      <ul className="nav nav-tabs mb-2 w-100" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab nav-table-tab-big ${
              category === "interesting_values_found_in_authentication"
                ? "active"
                : ""
            }`}
            onClick={() => {
              setCategory("interesting_values_found_in_authentication");
              setPage(1);
            }}
          >
            Details found in authentication
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab nav-table-tab-big ${
              category === "interesting_values_found_in_body" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("interesting_values_found_in_body");
              setPage(1);
            }}
          >
            Details found in body
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab nav-table-tab-big ${
              category === "interesting_values_found_in_headers" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("interesting_values_found_in_headers");
              setPage(1);
            }}
          >
            Details found in headers
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              {/* <div
                data-test="datatable-res-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div> */}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>Request url</th>
                      <th>url</th>
                      <th>Values</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      return (
                        <tr>
                          <td>
                            <a href={row?.request_url} target="_blank">
                              {row?.request_url ? row?.request_url : "N/A"}
                            </a>
                          </td>
                          <td>{row?.url}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {filter &&
                  filter?.length > 0 &&
                  filter[0]?.[category]?.length > 10 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={filter[0]?.[category]?.length || 0}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModalTrue == true ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalSet(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="table-responsive">
                      <table className="table table-custom spacing5 mb-0 p-10">
                        <thead>
                          <tr>
                            <th>key</th>
                            <th>value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.map((row, index) => {
                            return (
                              <tr>
                                <td>{row?.key}</td>
                                <td>{row?.value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PostManMonitoringDetail;
