import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import {
  LEAKEDCREDS_REQUESTS_FETCHED,
  LEAKEDCREDS_REQUESTS_FETCHED_TOTAL,
  LEAKEDCREDS_REQUESTS_FETCHED_GRAPH,
  TELEGRAM_REQUESTS_FETCHED,
  GET_POST_MAN,
  MALWARE_CREDENTIALS_FETCHED,
  LEAKED_SESSION_FETCHED,
  GET_PHISHING_PAGE,
  PII_DETAILS,
  MALWARE_CREDENTIALS_FETCHED_TOTAL,
  MALWARE_CREDENTIALS_FETCHED_GRAPH,
  PII_DETAILS_TOTAL,
  PII_DETAILS_GRAPH,
  LEAKED_SESSION_FETCHED_GRAPH,
  TELEGRAM_REQUESTS_FETCHED_TOTAL,
  GET_POST_MAN_DETAILS,
  GET_POST_MAN_GRAPH,
  GET_POST_MAN_TOTAL
} from "./index";
import { toastr } from "react-redux-toastr";

export const fetchLeakedCredentials = (
  props,
  dispatch,
  page,
  category,
  type,
  emailquery
) => {
  if (props) {
    axios
      .get(
        `${API_URL}/dehashed/search/${props}/delta?page=${page}&category=${category}&type=${type}&emailquery=${emailquery}`,
        {}
      )
      .then((res) => {
        dispatch({
          type: LEAKEDCREDS_REQUESTS_FETCHED,
          payload: res.data,
        });
      });
  }
};

export const fetchLeakedCredentialsAction = (
  props,
  dispatch,
  page,
  category,
  type,
  emailquery,
  data
) => {
  if (props) {
    axios
      .post(
        `${API_URL}/dehashed/search/${props}/action?page=${page}&category=${category}&type=${type}&emailquery=${emailquery}`,
        data
      )
      .then((res) => {
        dispatch({
          type: LEAKEDCREDS_REQUESTS_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchLeakedCredentialsGraph = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/dehashed/search/${props}/graph`).then((res) => {
      dispatch({
        type: LEAKEDCREDS_REQUESTS_FETCHED_GRAPH,
        payload: res.data,
      });
    });
  }
};

export const fetchLeakedCredentialsTotal = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/dehashed/search/${props}/totals`, {}).then((res) => {
      dispatch({
        type: LEAKEDCREDS_REQUESTS_FETCHED_TOTAL,
        payload: res.data,
      });
    });
  }
};

export const telegram = (props, dispatch, page,category ) => {
  if (props) {
    axios.get(`${API_URL}/telegram-monitoring/${props}/delta?page=${page}&category=${category}`,).then((res) => {
      dispatch({
        type: TELEGRAM_REQUESTS_FETCHED,
        payload: res.data,
      });
    });
  }
};

export const telegramTotal = (props, dispatch ) => {
  if (props) {
    axios.get(`${API_URL}/telegram-monitoring/${props}/totals`,).then((res) => {
      dispatch({
        type: TELEGRAM_REQUESTS_FETCHED_TOTAL,
        payload: res.data,
      });
    });
  }
};

export const telegramAction = (props, dispatch, page, category, data ) => {
  if (props) {
    axios.post(`${API_URL}/telegram-monitoring/${props}/delta?page=${page}&category=${category}`,).then((res) => {
      dispatch({
        type: TELEGRAM_REQUESTS_FETCHED,
        payload: res.data,
      });
      if (res.data.status == 200) {
        toastr.success("Success", res.data.userMessage);
      } else {
        toastr.error("Error", res.data.userMessage);
      }
    });
  }
};

export const fetchMalwareCredentials = (props, dispatch,page,category,userquery) => {
  if (props) {
    axios
      .get(`${API_URL}/getMalwareCredentials/${props}/delta?page=${page}&category=${category}&userquery=${userquery}`, {})
      .then((res) => {
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED,
          payload: res.data,
        });
      });
  }
};

export const fetchMalwareCredentialsAction = (props, dispatch,page,category,userquery,data) => {
  if (props) {
    axios
      .post(`${API_URL}/getMalwareCredentials/${props}/action?page=${page}&category=${category}&userquery=${userquery}`, data)
      .then((res) => {
        console.log(res,'res')
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchMalwareCredentialsTotal = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/getMalwareCredentials/${props}/totals`, {})
      .then((res) => {
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED_TOTAL,
          payload: res.data,
        });
      });
  }
};

export const fetchMalwareCredentialsGraph = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/getMalwareCredentials/${props}/graph`, {})
      .then((res) => {
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED_GRAPH,
          payload: res.data,
        });
      });
  }
};


export const fetchLeakedSession = (props, dispatch ,page, status, name ,category) => {
  if (props) {
    axios
      .get(`${API_URL}/leaked-sessions/${props}/delta?page=${page}&status=${status}&name=${name}&category=${category}`,)
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED,
          payload: res.data,
        });
      });
  }
};

export const fetchLeakedSessionAction = (props, dispatch ,page, status, name ,category,data) => {
  if (props) {
    axios
      .post(`${API_URL}/leaked-sessions/${props}/action?page=${page}&status=${status}&name=${name}&category=${category}`,data)
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchLeakedSessionGraph = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/leaked-sessions/${props}/graph`,)
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED_GRAPH,
          payload: res.data,
        });
      });
  }
};



export const fetchPhishingPage = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/getPhishingPage/${props}`, {}).then((res) => {
      dispatch({
        type: GET_PHISHING_PAGE,
        payload: res.data,
      });
    });
  }
};

export const fetchPiiDetails = (props, dispatch, page, category , email_search_query) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/delta?page=${page}&category=${category}&email_search_query=${email_search_query}`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS,
          payload: res.data,
        });
      });
  }
};

export const fetchPiiDetailsTotal = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/totals`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS_TOTAL,
          payload: res.data,
        });
      });
  }
};

export const fetchPiiDetailsGraph = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/graph`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS_GRAPH,
          payload: res.data,
        });
      });
  }
};

export const fetchPiiDetailsAction = (props, dispatch, page, category, email_search_query, data) => {
  if (props) {
    axios
      .post(`${API_URL}/pii-details/${props}/action?page=${page}&category=${category}&email_search_query=${email_search_query}`,data)
      .then((res) => {
        dispatch({
          type: PII_DETAILS,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.success("Error", res.data.userMessage);
        }
      });
  }
};

export const PostMan = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/postmanRecord/${props}`, {}).then((res) => {
      dispatch({
        type: GET_POST_MAN,
        payload: res.data,
      });
    });
  }
};

export const postManDelta = (props,dispatch, page, category) => {
  if(props){
    axios
      .get(`${API_URL}/postmanRecord/${props}?page=${page}&category=${category}`)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: GET_POST_MAN,
           payload: response.data,
        });
      })
  }
};

export const postManAction = (props,dispatch, page, data, category) => {
  if(props){
    axios
      .post(`${API_URL}/postmanRecord/${props}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: GET_POST_MAN,
           payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
};

export const postManGraph = (props,dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/postmanRecord/${props}/graph`)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: GET_POST_MAN_GRAPH,
           payload: response.data,
        });
      })
  }
};

export const postManTotal = (props,dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/postmanRecord/${props}/totals`)
      .then((response) => {
        //console.log(response);
        dispatch({
          type: GET_POST_MAN_TOTAL,
           payload: response.data,
        });
      })
  }
};

export const PostManDetails = (props,id, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/postmanRecordDetail/${props}?organisationId=${id}`, {}).then((res) => {
      dispatch({
        type: GET_POST_MAN_DETAILS,
        payload: res.data,
      });
    });
  }
};
