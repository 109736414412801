import {
  API_SCAN_CREATED,
  API_SCAN_FETCHED,
  API_SCAN_DETAILS_FETCHED
} from "../actions/";

const initialState = {
  loading: true,
  response: null,
  list: null,
  data: null,
  fetchedData: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case API_SCAN_FETCHED:
      return {
        ...state,
        apiScanResult: action.payload,
        loading: true
      };
    case API_SCAN_CREATED:
      return {
        ...state,
        response: action.payload,
        loading: true,
      };
    case API_SCAN_DETAILS_FETCHED:
      return {
        ...state,
        apiScanDetail: action.payload,
        loading: true,
      }

    default:
      return state;
  }
}
