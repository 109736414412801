import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import Dropzone from "../common/DropzoneExample";
import ReduxToastr, { toastr } from "react-redux-toastr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createdLeakedCodeRecord,
  createDarkWebRecord,
  createAppSToreRecord,
  createSocialMediaProfile,
  createPastbinSearch
} from "../../actions/leakedCode";
var Model = require("./model");

const FeedInformation = () => {
  const dispatch = useDispatch();

  // const orgId = JSON.parse(localStorage.getItem("user")).organisationId;
  // const orgId = useSelector(
  //   (state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  // );
  const { orgId } = useParams();


  //Mobile App
  const [appId, setAppId] = useState("");
  const [appName, setAppName] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [appStoreResource, setAppStoreResource] = useState("");
  const [appStoreSeverity, setAppStoreSeverity] = useState("");
  const [appSummary, setAppStoreSummary] = useState("");
  const [appStoreDetails, setAppStoreDetails] = useState("");
  const [appStoreAttachment, setAppStoreAttachment] = useState("");
  const [appStoreComments, setAppStoreComments] = useState("");
  const [appDeveloperWebsite, setAppDeveloperWebsite] = useState("");
  const [appDeveloperEmail, setAppDeveloperEmail] = useState("");

  const [repoUrl, setRepoUrl] = useState("");
  const [programmingLanguage, setProgrammingLanguage] = useState("");
  const [severityLevel, setSourceCodeSeverity] = useState("");
  const [leakedCode, setLeakedCode] = useState("");
  const [comments, setSourceCodeComments] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [upload, setupload] = useState("");
  const [isSuspicious, setSourceCodeSuspicious] = useState("");

  const [fakePageUrl, setFakePageUrl] = useState("");
  const [socialMediaPlatform, setSocialMediaPlatform] = useState("");
  const [socialSeverity, setSocialSeverity] = useState("");
  const [socialComments, setSocialComments] = useState("");
  const [socialAttachment, setSocialAttachment] = useState("");

  const [darkWebUrl, setDarkWebUrl] = useState("");
  const [darkWebMentionType, setDarkWebMentionType] = useState("");
  const [darkWebSeverity, setDarkWebSeverity] = useState("");
  const [darkWebDetails, setDarkWebDetails] = useState("");
  const [darkWebComments, setDarkWebComments] = useState("");
  const [darkWebAttachmentUrl, setDarkWebAttachmentUrl] = useState("");


  const [title, setTitle] = useState("");
  const [snippet, setSnippet] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");


  //console.log(socialAttachment,'socialAttachment')
  const clearAppStore = () => {
    setAppName("");
    setAppId("");
    setAppStoreUrl("");
    setAppStoreResource("");
    setAppStoreSeverity("");
    setAppStoreSummary("");
    setAppStoreDetails("");
    setAppStoreAttachment("");
    setAppStoreComments("");
    setAppDeveloperWebsite("");
    setAppDeveloperEmail("");
  };

  const clearSourceCode = () => {
    setRepoUrl("");
    setProgrammingLanguage("");
    setSourceCodeSeverity("");
    setLeakedCode("");
    setSourceCodeComments("");
    setAttachmentUrl("");
    setSourceCodeSuspicious("");
  };

  const clearSocialMedia = () => {
    setFakePageUrl("");
    setSocialMediaPlatform("");
    setSocialSeverity("");
    setSocialComments("");
    setSocialAttachment("");
  };
  const clearDarkWeb = () => {
    setDarkWebUrl("");
    setDarkWebMentionType("");
    setDarkWebSeverity("");
    setDarkWebDetails("");
    setDarkWebComments("");
    setDarkWebAttachmentUrl("");
  };

  const clearPastbin = () => {
    setLink("");
    setSnippet("");
    setDate("");
    setTitle("");
  };

  toast.configure();

  const submitAppStore = () => {
    // console.log(setDarkWebAttachmentUrl,'set dark web')
    // if (appId == "") {
    //   toast("Application ID is missing");
    //   return false;
    // }
    if (appName == "") {
      toast("Application Name is missing");
      return false;
    }

    if (appStoreUrl == "") {
      toast("Please enter app store url.");
      return false;
    }

    if (appStoreResource == "") {
      toast("Select Application Source");
      return false;
    }
    if (appStoreSeverity == "") {
      toast("Please select severity.");
      return false;
    }
    // if (appSummary == "") {
    //   toast("Application summary is missing.");
    //   return false;
    // }
    // if (appDeveloperWebsite == "") {
    //   toast("Developer website is missing.");
    //   return false;
    // }
    // if (appDeveloperEmail == "") {
    //   toast("Developer email is missing.");
    //   return false;
    // }
    // if (appStoreDetails == "") {
    //   toast("Please enter application details.");
    //   return false;
    // }
    // if (appStoreAttachment == "") {
    //   toast("Please upload attachment.");
    //   return false;
    // }

    // if (appStoreComments == "") {
    //   toast("Please enter comments.");
    //   return false;
    // }

    createAppSToreRecord(
      {
        appId: appId,
        appName: appName,
        url: appStoreUrl,
        appStoreResource: appStoreResource,
        appStoreSeverity: appStoreSeverity,
        appSummary: appSummary,
        appDeveloperWebsite: appDeveloperWebsite,
        appDeveloperEmail: appDeveloperEmail,
        icon: appStoreAttachment,
        organisationId: orgId,
        description: appStoreDetails,
        comments: appStoreComments,
      },
      clearAppStore,
      dispatch
    );
  };

  const submitSourceCode = () => {
    if (repoUrl == "") {
      toast("Repository URL is missing");
      return false;
    }
    if (programmingLanguage == "") {
      toast("Please select Programming Language");
      return false;
    }

    if (severityLevel == "") {
      toast("Please select Severity");
      return false;
    }

    if (leakedCode == "") {
      toast("Leaked Code is missing");
      return false;
    }
    if (comments == "") {
      toast("Comments missing");
      return false;
    }

    // if (attachmentUrl == "") {
    //   toast("Please upload attachment.");
    //   return false;
    // }

    createdLeakedCodeRecord(
      {
        repoUrl: repoUrl,
        programmingLanguage: programmingLanguage,
        severityLevel: severityLevel,
        leakedCode: leakedCode,
        comments: comments,
        attachmentUrl: attachmentUrl,
        organisationId: orgId,
        isSuspicious: true,
      },
      clearSourceCode,
      dispatch
    );
  };
  const submitSocialMedia = () => {
    console.log()
    if (fakePageUrl == "") {
      toast("Page URL is missing");
      return false;
    }
    if (socialMediaPlatform == "") {
      toast("Please select platform");
      return false;
    }

    if (socialSeverity == "") {
      toast("Please select Severity");
      return false;
    }

    if (socialComments == "") {
      toast("Comments missing");
      return false;
    }

    // if (socialAttachment == "") {
    //   toast("Please upload attachment.");
    //   return false;
    // }

    createSocialMediaProfile(
      {
        fakePageUrl: fakePageUrl,
        socialMediaPlatform: socialMediaPlatform,
        socialSeverity: socialSeverity,
        socialComments: socialComments,
        socialAttachment: socialAttachment,
        organisationId: orgId,
        isSuspicious: true,
      },
      clearSocialMedia,
      dispatch
    );
  };
  const submitDarkWeb = () => {
    if (darkWebUrl == "") {
      toast("Dark Web URL is missing");
      return false;
    }
    if (darkWebMentionType == "") {
      toast("Please select mention type");
      return false;
    }

    if (darkWebSeverity == "") {
      toast("Please select Severity");
      return false;
    }

    if (darkWebDetails == "") {
      toast("Details missing");
      return false;
    }
    if (darkWebComments == "") {
      toast("Comments missing");
      return false;
    }

    if (darkWebAttachmentUrl == "") {
      toast("Please upload attachment.");
      return false;
    }

    createDarkWebRecord(
      {
        darkWebUrl: darkWebUrl,
        darkWebMentionType: darkWebMentionType,
        darkWebSeverity: darkWebSeverity,
        darkWebDetails: darkWebDetails,
        darkWebComments: darkWebComments,
        organisationId: orgId,
        isSuspicious: true,
        WebAttachmentUrl: darkWebAttachmentUrl
      },
      clearDarkWeb,
      dispatch
    );
    setDarkWebAttachmentUrl("NO");
  };

  const submitPastbinSearch = () =>{
    if(title == ""){
      toast("Please Enter the Title");
      return false;
    }
    if(link == ""){
      toast("Please Enter the link");
      return false;
    }
    if(date == ""){
      toast("Please Enter the date");
      return false;
    }
    if(snippet == ""){
      toast("Please Enter the Snippet");
      return false;
    }
    createPastbinSearch(
      {
        title:title,
        snippet:snippet,
        link:link,
        organisationId:orgId,
        date:date
      },
      clearPastbin,
      dispatch
    )
  } 

  const handleLogoChange = (type) => (e) => {
    //console.log(e, type, 'handleLogoChange');
    const fileName = e.target.files[0].name;
    setupload(fileName)

    Model.default.saveOrgLogo(e.target.files[0]).then((response) => {
      console.log(e, 'response');
      if (!response.error) {
        //console.log(response.data,'data')
        let orgLogo = response.data;
        if (type === "sourceCode")
          setAttachmentUrl(orgLogo);
        else if (type === "mobileApp")
          setAppStoreAttachment(orgLogo);
      }
    });
  }
  
  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Feed Information</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Summary
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Feed Information</h2>
              </div>
              <div className="body">
                <div id="wizard_horizontal">
                  <Tabs
                    defaultActiveKey="First Step"
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      eventKey="First Step"
                      title="Mobile Application"
                      tabClassName="form_wizard_tab form_wizard_width"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <div className="row clearfix">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={appId}
                                      onChange={(e) => setAppId(e.target.value)}
                                      placeholder="Application ID"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={appName}
                                      onChange={(e) =>
                                        setAppName(e.target.value)
                                      }
                                      placeholder="Application Name *"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      placeholder="Application URL *"
                                      className="form-control"
                                      value={appStoreUrl}
                                      onChange={(e) =>
                                        setAppStoreUrl(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={appStoreResource}
                                      onChange={(e) =>
                                        setAppStoreResource(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Source *--
                                      </option>
                                      <option value="google">
                                        Google Play Store
                                      </option>
                                      <option value="apple">Apple Store</option>
                                      <option value="others">
                                        Third Party Store
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={appStoreSeverity}
                                      onChange={(e) =>
                                        setAppStoreSeverity(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Severity *--
                                      </option>
                                      <option value="critical">Critical</option>
                                      <option value="high">High</option>
                                      <option value="medium">Medium</option>
                                      <option value="low">Low</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Summary"
                                      value={appSummary}
                                      onChange={(e) =>
                                        setAppStoreSummary(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={appDeveloperWebsite}
                                      onChange={(e) =>
                                        setAppDeveloperWebsite(e.target.value)
                                      }
                                      placeholder="Developer Website"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={appDeveloperEmail}
                                      onChange={(e) =>
                                        setAppDeveloperEmail(e.target.value)
                                      }
                                      placeholder="Developer email"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Upload Logo
                                      </span>
                                    </div>
                                    <div className="custom-file">
                                      <input
                                        onChange={handleLogoChange('mobileApp')}
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="inputGroupFile01"
                                      >
                                        {upload ? `Upload: ${upload}` : "Choose file"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Description"
                                      value={appStoreDetails}
                                      onChange={(e) =>
                                        setAppStoreDetails(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Comments"
                                      value={appStoreComments}
                                      onChange={(e) =>
                                        setAppStoreComments(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                                onClick={(e) => {
                                  //console.log("click here", " button");
                                  // e.preventDefault();
                                  submitAppStore();
                                }}
                              >
                                Submit
                              </button>{" "}
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Second Step"
                      title="Source Code"
                      tabClassName="form_wizard_tab form_wizard_width"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <div className="row clearfix">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Repository URL"
                                      value={repoUrl}
                                      onChange={(e) =>
                                        setRepoUrl(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={programmingLanguage}
                                      onChange={(e) =>
                                        setProgrammingLanguage(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Programming Language --
                                      </option>
                                      <option value="java">Java</option>
                                      <option value="php">PHP</option>
                                      <option value="python">Python</option>
                                      <option value="c++">C++</option>
                                      <option value="c#">C#</option>
                                      <option value="r">R</option>
                                      <option value="js">Java Script</option>
                                      <option value="golang">Go Lang</option>
                                      <option value="ts">Type Script</option>
                                      <option value="ruby">Ruby</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={severityLevel}
                                      onChange={(e) =>
                                        setSourceCodeSeverity(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Severity --
                                      </option>
                                      <option value="critical">Critical</option>
                                      <option value="high">High</option>
                                      <option value="medium">Medium</option>
                                      <option value="low">Low</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        Upload Screenshot
                                      </span>
                                    </div>
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        onChange={handleLogoChange('sourceCode')}
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="inputGroupFile01"
                                      >
                                        {upload ? `Upload: ${upload}` : "Choose file"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Leaked Code"
                                      value={leakedCode}
                                      onChange={(e) =>
                                        setLeakedCode(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Comments"
                                      value={comments}
                                      onChange={(e) =>
                                        setSourceCodeComments(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  submitSourceCode();
                                }}
                              >
                                Submit
                              </button>{" "}
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Third Step"
                      title="Social Media Profile"
                      tabClassName="form_wizard_tab form_wizard_width"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <div className="row clearfix">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Page/Profile URL"
                                      value={fakePageUrl}
                                      onChange={(e) =>
                                        setFakePageUrl(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={socialMediaPlatform}
                                      onChange={(e) =>
                                        setSocialMediaPlatform(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Source --
                                      </option>
                                      <option value="linkedin">LinkedIn</option>
                                      <option value="facebook">Facebook</option>
                                      <option value="instagram">
                                        Instagram
                                      </option>
                                      <option value="twitter">Twitter</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={socialSeverity}
                                      onChange={(e) =>
                                        setSocialSeverity(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Severity --
                                      </option>
                                      <option value="critical">Critical</option>
                                      <option value="high">High</option>
                                      <option value="medium">Medium</option>
                                      <option value="low">Low</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Comments"
                                      value={socialComments}
                                      onChange={(e) =>
                                        setSocialComments(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="card">
                                    <div className="header">
                                      <h2>Upload Evidence</h2>
                                    </div>
                                    <div className="body">
                                      <Dropzone 
                                      variable={setSocialAttachment}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                                onClick={(e) => {
                                  //console.log("click here", " button");
                                  // e.preventDefault();
                                  submitSocialMedia();
                                }}
                              >
                                Submit
                              </button>{" "}
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Forth Step"
                      title="Dark Web"
                      tabClassName="form_wizard_tab form_wizard_width"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <div className="row clearfix">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="URL"
                                      value={darkWebUrl}
                                      onChange={(e) =>
                                        setDarkWebUrl(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={darkWebMentionType}
                                      onChange={(e) =>
                                        setDarkWebMentionType(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Mention Type --
                                      </option>
                                      <option value="market">Market</option>
                                      <option value="forum">Forum</option>
                                      <option value="gang">Gang</option>
                                      <option value="malware">Malware</option>
                                      <option value="request">
                                        Dark Web Request
                                      </option>
                                      <option value="mention">
                                        Dark Web Mention
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={darkWebSeverity}
                                      onChange={(e) =>
                                        setDarkWebSeverity(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        -- Select Severity --
                                      </option>
                                      <option value="AF">Critical</option>
                                      <option value="AX">High</option>
                                      <option value="AX">Medium</option>
                                      <option value="AX">Low</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div
                                    className="form-group"
                                    value={darkWebDetails}
                                    onChange={(e) =>
                                      setDarkWebDetails(e.target.value)
                                    }
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Details"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                  <div
                                    className="form-group"
                                    value={darkWebComments}
                                    onChange={(e) =>
                                      setDarkWebComments(e.target.value)
                                    }
                                  >
                                    <textarea
                                      rows="4"
                                      type="text"
                                      className="form-control"
                                      placeholder="Comments"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="card">
                                    <div className="header">
                                      <h2>Upload Evidence</h2>
                                    </div>
                                    <div className="body">
                                      <Dropzone
                                      variable={setDarkWebAttachmentUrl} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                                onClick={(e) => {
                                  //console.log("click here", " button");
                                  // e.preventDefault();
                                  submitDarkWeb();
                                }}
                              >
                                Submit
                              </button>{" "}
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Five Step"
                      title="Pastebin Search"
                      tabClassName="form_wizard_tab form_wizard_width"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <div className="row clearfix">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="title"
                                      value={title}
                                      onChange={(e) =>
                                        setTitle(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="snippet"
                                      value={snippet}
                                      onChange={(e) =>
                                        setSnippet(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="date"
                                      value={date}
                                      onChange={(e) =>
                                        setDate(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="link"
                                      value={link}
                                      onChange={(e) =>
                                        setLink(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                </div>
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                                onClick={(e) => {
                                  //console.log("click here", " button");
                                  // e.preventDefault();
                                  submitPastbinSearch();
                                }}
                              >
                                Submit
                              </button>{" "}
                              &nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedInformation;
