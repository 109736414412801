import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  API_SCAN_DETAILS_FETCHED,
  SCHEDULER_FETCHED,
  SCHEDULER_SUBDOMAIN_FETCHED,
} from "./index";
import { toastr } from "react-redux-toastr";

export const fetchSubDomains = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/fetchSubDomains/${props}`, {})
    .then((response) => {
      dispatch({
        type: SCHEDULER_SUBDOMAIN_FETCHED,
        payload: response.data.value,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: SCHEDULER_SUBDOMAIN_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};




export const fetchScheduler = (props, dispatch) => {
  //console.log("fetchScheduler")
  axios
    .get(`${API_URL}/subdomain_analysis?orgId=${props}&scheduler=true`)
    .then((res) => {
      dispatch({
        type: SCHEDULER_FETCHED,
        payload: res.data,
      });
    });
};


export const createApiScan = (props, dispatch) => {
  //console.log(props);
  if (props !== undefined) {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    axios.post(`${API_URL}/start-scan`, props).then((res) => {
      if (res.error) {
        toastr.error("ERROR", "Something went wrong");
      } else {
        toastr.success("SUCCESS", "Scan started.");
      }
      dispatch({
        type: SCHEDULER_FETCHED,
        payload: res.data,
      });
    });
  }
};

export const fetchApiResult = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  //console.log(props);

  if (props !== undefined) {
    axios
      .get(`${API_URL}/getApiScanDetails/${props}`)
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: API_SCAN_DETAILS_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: API_SCAN_DETAILS_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const updateApiStatus = (status, _id, index, paramId, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/updateApiStatus`, {
      _id: _id,
      status: status,
      index: index,
      paramId: paramId,
    })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.userMessage);
      } else {
        // toastr.success("Success", "Record updated.");
        dispatch({
          type: API_SCAN_DETAILS_FETCHED,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: API_SCAN_DETAILS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};
