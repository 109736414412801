import {
    CREATING_USER,
    USERS_FETCHED,
    USERS_CREATED,
    USER_DELETED,
    USER_UPDATED,
    DELETING_USER,
    EDIT_USER_FETCHED,
    FETCHING_USERS,
    UPDATING_USER,
    LOGED_IN_USER,
    FETCHING_ORGANISATION
  } from "../actions";
  
  const initialState = {
    loading: true,
    response: null,
    list: null,
    data: null,
    fetchedData: {},
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCHING_USERS:
        return {
          ...state,
          response: action.payload,
          fetchedData: null,
          user: null,
          loading: true,
        };
      case CREATING_USER:
        return {
          ...state,
          response: action.payload,
          loading: true,
        };
  
      case USERS_CREATED:
        return {
          ...state,
          response: action.payload,
          data: !action.payload.error ? action.payload.data : null,
          loading: false,
        };
  
      case USERS_FETCHED:
        return {
          ...state,
          response: action.payload,
          list: !action.payload.error ? action.payload.data : [],
          loading: false,
        };
      case EDIT_USER_FETCHED:
        return {
          ...state,
          fetchedData: action.fetchedData,
          user: !action.fetchedData.error ? action.fetchedData.data : null,
        };
  
      case UPDATING_USER:
        return {
          ...state,
          fetchedData: action.payload,
          loading: true,
        };
      case USER_UPDATED:
        return {
          ...state,
          fetchedData: action.payload,
          loading: false,
        };
  
      case DELETING_USER:
        return {
          ...state,
          response: action.payload,
          loading: true,
        };
      case USER_DELETED:
        return {
          ...state,
          response: action.payload,
          loading: false,
        };
      case LOGED_IN_USER:
        return {
          ...state,
          logedInUser: action.payload,
          loading: false,
        };
  
      case FETCHING_ORGANISATION:{
        return {
          ...state,
          organisation: action.payload,
          loading: false,
        };
      }
  
      default:
        return state;
    }
  }
  