import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link  , useParams} from "react-router-dom";
import { fetchAlerts } from "../../actions/integration";
import Pagination from "../Pagination/Pagination";


const Inbox = () => {
  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const dispatch = useDispatch();
  const orgId = useSelector(
    (state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  );

  //console.log("orgId=", orgId);

  const alertResp = useSelector(state=>state?.integration?.resalert);

  useEffect(()=>{

    if(loading && alertResp){
      //console.log('alertResp', alertResp);
      setLoading(false);
      setAlerts(alertResp.alerts);
      setAlertCount(alertResp.alertCount);
    }

  }, [loading, alertResp]);

  useEffect(() => {
    //console.log('loading',loading, orgId);
    const fetchData = async () => {
      await fetchAlerts({organisationId: orgId}, dispatch);
    }
    if(loading && orgId){
      fetchData();
    }
  }, [dispatch, loading, orgId]);

  let currentTableData = [];
  if (alerts && alerts.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = alerts?.slice(firstPageIndex, lastPageIndex);
  }
  
  console.log(currentTableData,'currentTableData')

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Inbox</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Email Logs
                  </li>
                </ol>
              </nav>
            </div>
           
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="mail-inbox">
                <div className="mobile-left">
                  <Link to="/" className="btn btn-primary toggle-email-nav">
                    <i className="fa fa-bars"></i>
                  </Link>
                </div>

                <div className="body mail-right check-all-parent">
                  <div className="mail-action clearfix m-l-15">
                    <div className="pull-left"></div>
                    <div className="pull-right ml-auto">
                      <div className="pagination-email d-flex">
                        
                      </div>
                    </div>
                  </div>
                  <div className="mail-list">
                    <ul className="list-unstyled">
                     {!loading && currentTableData?.map((alert, index)=>(
                       <li className="clearfix" key={alert?._id}>
                       <div className="md-left">{index+1}</div>
                       <div className="md-right">
                         {/* <img
                           className="rounded"
                           src="../assets/images/xs/avatar1.jpg"
                           alt="avatar"
                         /> */}
                         <p className="sub">
                           <Link
                             to={`/app-inbox/${alert._id}/details`}
                             className="mail-detail-expand"
                           >
                             {alert.alertTo}
                           </Link>
                         </p>
                         <p className="dep">
                          {alert.alertSubject}
                         </p>
                         <span className="time">{moment(alert.alertSentAt).format('LLL')}</span>
                       </div>
                     </li>
                     ))}

                     {(!loading && alerts.length===0) && (
                      <li className="clearfix">No alert found...</li>
                     )} 
                   
                    </ul>
                  </div>

                  {/* <ul className="pagination mb-0">
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        Previous
                      </Link>
                    </li>
                    <li className="page-item active">
                      <Link className="page-link" to="#">
                        1
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        2
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        3
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link className="page-link" to="#">
                        Next
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dataTables_paginate">
                    {alerts && alerts.length > 0 && (
                      <Pagination
                        className="pagination-bar"
                        currentPage={page}
                        totalCount={alerts?.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setPage(page)}
                      />
                    )}
                </div>
      </div>
    </>
  );
};
export default Inbox;
