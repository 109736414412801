import { FETCH_CLIENTS,  FETCH_CLIENTS_UPDATE ,GETING_DATA_BY_LOCALSTORAGE, SUMMARY_FETCHED, REPORT_DOWNLOAD, MAIN_SCREEN_SUMMARY_FETCHED, DASHBOARD_SUMMARY_FETCHED, VENDOR_SUMMARY_FETCHED } from "../actions";
const initialState = {
  clients: [],
  response: null,
  localClient: null,
  summary: null,
  mainSummary:null,
  reportDownload: false,
  totalClient:[],
  dashboardSummary: [],
  vendorSummary: [],
  loading: true
};

const summary = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        clients: action.clients,
        response: action.response,
        totalClient:action.res
      };
    case GETING_DATA_BY_LOCALSTORAGE:
      return {
        ...state,
        localClient: action.localClient,
      };
    case SUMMARY_FETCHED:
      return {
        ...state,
        summary: action.payload
      };
    case MAIN_SCREEN_SUMMARY_FETCHED:
      return {
        ...state,
        mainSummary: action.payload
      };
    case REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload
      }
    case DASHBOARD_SUMMARY_FETCHED:
      return {
        ...state,
        dashboardSummary: action.payload,
        loading: action.payload ? false : true
      }
    case VENDOR_SUMMARY_FETCHED:
      return {
        ...state,
        vendorSummary: action.payload,
        loading: action.payload ? false : true
      }
    default:
      return state;
  }
};

export default summary;
