import React from "react";
import { Link  , useParams} from "react-router-dom";
import Dropzone from "../common/DropzoneExample";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";

const PageProfile = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Settings</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <a href="/" className="btn btn-sm btn-primary btn-round" title="">
                Add New
              </a>
            </div> */}
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card social">
              <div className="profile-header d-flex justify-content-between justify-content-center">
                <div className="d-flex">
                  <div className="mr-3">
                    <img
                      src="../assets/images/user.png"
                      className="rounded"
                      alt="Avatar"
                    />
                  </div>
                  <div className="details">
                    <h5 className="mb-0">BrandSek LLC.</h5>
                    <span className="text-light">support@brandsek.com</span>
                  </div>
                </div>
                {/* <div>
                  <button className="btn btn-primary btn-sm mr-1">
                    Follow
                  </button>
                  <button className="btn btn-success btn-sm">Message</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-5">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Info</h2>
                </div>
                <small className="text-muted">Name: </small>
                <p>BrandSek LLC.</p>

                <hr />
                <small className="text-muted">Email address: </small>
                <p>louispierce@example.com</p>
                <hr />
                <small className="text-muted">Website: </small>
                <p>example.com</p>
                <hr />
                <small className="text-muted">Mobile: </small>
                <p>+ 202-222-2121</p>
                <hr />
                <small className="text-muted">Subscription Level: </small>
                <p className="m-b-0">Trial</p>
                <hr />
                <small className="text-muted">Social: </small>
                <p>
                  <i className="fa fa-twitter m-r-5"></i> twitter.com/example
                </p>
                <p>
                  <i className="fa fa-facebook  m-r-5"></i> facebook.com/example
                </p>
                <p>
                  <i className="fa fa-github m-r-5"></i> github.com/example
                </p>
                <p>
                  <i className="fa fa-instagram m-r-5"></i>{" "}
                  instagram.com/example
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-8 col-lg-8 col-md-7">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>
                    Manage
                    <small />
                  </h2>
                </div>
                <Tab.Container id="top-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first">Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Password</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Email Preference</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Full Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email Address"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Contact Number"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="inputGroupFile01"
                                >
                                  Update Profile Picture
                                </label>
                              </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="col-lg-12 col-md-12">
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                              >
                                Update
                              </button>{" "}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Current Password"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="New Password"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm Password"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <button
                                type="button"
                                className="btn btn-round btn-primary mr-1"
                              >
                                Update
                              </button>{" "}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <div className="col-lg-12 col-md-12">
                            <label for="html">Alert Frequency</label>
                            <br />
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <select className="form-control">
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <button
                              type="button"
                              className="btn btn-round btn-primary mr-1"
                            >
                              Update
                            </button>{" "}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageProfile;
