import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import Logout from './components/Authentication/logout';
import ForgotPassword from './components/Authentication/forgotpassword';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { pingServer } from "./actions/auth";
import { bindActionCreators } from "redux";
import { PrivateRoute, PublicRoute } from "./route.js";
import ResetPassword from "./components/Authentication/resetPassword";
import EmailVerfiy from './components/Authentication/email-verify';
import ProfileUpdate from './components/Authentication/profileUpdate';
import CheckToken from './components/Authentication/checkToken';
import ReduxToastr from "react-redux-toastr";

class App extends Component {
	constructor(props) {
		super(props);
		// console.log(this.props,'pingserver',window.location.href)

		if(window.location.href.indexOf("/checkToken") != -1){
			// console.log("here 24");
			const query = new URLSearchParams(window.location.search);
			const token = query.get('token')
			const refreshToken = query.get('refreshToken')
    		// console.log(token, refreshToken, 'token, refreshToken')
			localStorage.setItem("session", token);
        	localStorage.setItem("refreshToken", refreshToken);
			this.props.pingServer(this.props,'checkToken');
		}else{
			this.props.pingServer(this.props,'login');
		}

	}

	render() {
		const { loading } = this.props.auth;
		const { themeColor, fontStyle, lightVersion, RtlVersion, offcanvas, miniSidebar, horizontalMenu, miniHover } = this.props.settings
		document.getElementsByTagName('body')[0].className = `${themeColor} ${fontStyle}${lightVersion ? ' light_version' : ''}${RtlVersion ? ' rtl' : ''}${offcanvas ? ' offcanvas-active' : ''}${horizontalMenu ? ' h-menu' : ''}${miniSidebar ? ' mini_sidebar' : ''}${miniHover ? ' mini_hover' : ''}`;
		return (
			<div
				ref={leftSidebar => {
					this.leftSidebar = leftSidebar;
				}}
			>
				<ReduxToastr
					timeOut={4000} // Duration for which the toastr is visible (in milliseconds)
					newestOnTop={false} // Display newer toasts below older ones
					preventDuplicates
					position="top-right" // Position of the toastr container
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar // Show a progress bar
				/>
				<Router>
					{/* <Switch>
						<Route path="/signup" component={SignUp} />
						<Route path="/login" component={Login} />
						<Route path="/forgotpassword" component={ForgotPassword} />
						<Route path="/notfound" component={NotFound} />
						<Route path="/maintenance" component={Maintenance} />
						<Route component={Layout} />
					</Switch> */}
					{!loading && (
						<Switch>
							<PublicRoute
								auth={this.props.auth}
								restricted={true}
								path="/login"
								component={Login}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/new-login"
								component={Logout}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/forgotpassword"
								component={ForgotPassword}
								exact
							/>
							<PrivateRoute
								auth={this.props.auth}
								restricted={false}
								path="/reset-password"
								component={ResetPassword}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/reset-password/:reset_code"
								component={ResetPassword}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/email-update/:email"
								component={EmailVerfiy}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/profile-update/:email"
								component={ProfileUpdate}
								exact
							/>
							<PublicRoute
								auth={this.props.auth}
								restricted={false}
								path="/checkToken"
								component={CheckToken}
								exact
							/>
							<PrivateRoute
								auth={this.props.auth}
								path="/"
								component={Layout}
							/>
						</Switch>
					)}
				</Router>
			</div>
		);
	}
}

// const mapStateToProps = (state,auth) => ({
// 	themeColor: state.settings.themeColor,
// 	fontStyle: state.settings.fontStyle,
// 	lightVersion: state.settings.lightVersion,
// 	RtlVersion: state.settings.RtlVersion,
// 	offcanvas: state.settings.offcanvas,
// 	horizontalMenu: state.settings.horizontalMenu,
// 	miniSidebar: state.settings.miniSidebar,
// 	miniHover: state.settings.miniHover,
// 	auth
// })



function mapStateToProps({ auth, settings }) {
	return {
		// themeColor: state.settings.themeColor,
		// fontStyle: state.settings.fontStyle,
		// lightVersion: state.settings.lightVersion,
		// RtlVersion: state.settings.RtlVersion,
		// offcanvas: state.settings.offcanvas,
		// horizontalMenu: state.settings.horizontalMenu,
		// miniSidebar: state.settings.miniSidebar,
		// miniHover: state.settings.miniHover,
		auth,
		settings
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			pingServer,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
