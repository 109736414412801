import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import  {SEARCH_PRDOUCT_VENDOR , SEARCH_PRDOUCT_PRODUCT} from "./index";

export const searchVendor = ( dispatch , search , page , limit) => {
    axios
      .get(`${API_URL}/vendors?search=${search}&page=${page}&limit=${limit}`)
      .then((res) => {
        dispatch({
          type: SEARCH_PRDOUCT_VENDOR,
          payload: res.data,
        });
      });
}

export const vendorProduct = ( dispatch , search , pageProduct , limit , callApiUrl) => {
  if(callApiUrl == 'vendorProduct'){
    axios
    .get(`${API_URL}/vendors/${search}/products?page=${pageProduct}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: SEARCH_PRDOUCT_PRODUCT,
        payload: res.data,
      });
    });
  }else{
    axios
    .get(`${API_URL}/products?search=${search}&page=${pageProduct}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: SEARCH_PRDOUCT_PRODUCT,
        payload: res.data,
      });
    });
  }
}


