import React, { Component } from "react";
import { Link  , useParams} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import brandsek from "../../assets/images/brandsek-logo.png";
import { toastr } from "react-redux-toastr";

var Model = require("./model");

const styles = {
  position: "absolute",
  top: "19px",
  right: "15px",
  width: "34px",
  textAlign: "center",
  height: "34px",
  zIndex: "2",
  cursor: "pointer",
};
class Login extends Component {
  state = {
    user: null,
    email: '',
    visiblePassword: false,
    authMethod: null,
    loading: false,
    showPassword: false,
    setOtp: "",
  };

  componentDidMount() {
    if (!this?.props?.match?.params?.email) {
      window.location.replace("/login");
    } else {
      this.setState({
        email: atob(this.props.match.params.email)
      })
    }
  }

  updateProfileValue = (event) => {
    event.preventDefault();
    let name = $("#full_name").val();
    let email = this?.state?.email;
    let orgName = $("#org_name").val();
    let contact = $("#contact").val();
    this.setState({ loading: true });
    Model.default.updateProfile({
      email,
      name,
      orgName,
      contact
    }).then((response) => {
      console.log(response, 'updateProfile')
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        window.location.replace("/new-login");
        // $("#full_name").val("");
        // $("#org_name").val("");
        // $("#contact").val("");
        // toastr.success("Success", response.data.userMessage);

      }
    });
  };


  render() {
    // console.log(window.location.href, "this.props.auth");
    let browser = window.location.href.split("/");
    console.log(atob(this.props.match.params.email), "browser");
    return (
      <>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>

        <div className="page-wrapper page-wrapper_login">
          <div className="section bg-default wf-section">
            <div className="container-default position-relative w-container">
              <div className="inner-container _625px center">
                <div
                  data-w-id="af797e45-9721-3e02-9bca-78d7b063fc49"
                  className="card _24px sign-in-card"
                >
                  <div
                    data-w-id="742728b9-6335-1041-77e8-4ea5e5785154"
                    data-animation="default"
                    data-collapse="medium"
                    data-duration="400"
                    data-easing="ease"
                    data-easing2="ease"
                    role="banner"
                    class="header-wrapper w-nav"
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="container-default w-container">
                      <div className="header-content-wrapper">
                        <div
                          data-w-id="742728b9-6335-1041-77e8-4ea5e5785157"
                          className="header-middle"
                        >
                          <a className="header-logo-link center w-nav-brand text-center">
                            <img
                              src={brandsek}
                              style={{ maxWidth: "200px" }}
                              loading="eager"
                              className="header-logo"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sign-form w-form">
                    <form className="form-auth-small m-t-20">
                      <div className="sign-card-content">
                        <h1
                          className="display-2 text-center mg-bottom-24px keep"
                          style={{ marginBottom: "35px" }}
                        >
                          Update profile.
                        </h1>
                        <div className="mg-bottom-24px keep">
                          <div className="w-layout-grid grid-2-columns form clearfix">
                            <div class="grid-item-2fr">
                              <input
                                type="text"
                                className="input input-form top-left w-input"
                                name="Full Name"
                                data-name="FullName"
                                placeholder="Full Name"
                                required={true}
                                id="full_name"
                              ></input>
                            </div>
                            <div class="grid-item-2fr emailClass">
                              <input
                                type="email"
                                className="input input-form top-right w-input"
                                name="Email"
                                data-name="Email"
                                disabled={true}
                                value={this?.state?.email}
                                required={true}
                                placeholder="Email address"
                                id="email"
                              ></input>
                            </div>
                            <div class="grid-item-2fr">
                              <input
                                type="text"
                                className="input input-form top-left w-input"
                                name="Organization Name"
                                data-name="OrgName"
                                placeholder="Organization Name"
                                required={true}
                                id="org_name"
                              ></input>
                            </div>
                            <div class="grid-item-2fr">
                              {/* <input
                                type="email"
                                className="input input-form top-right w-input"
                                name="Website"
                                data-name="Website"
                                placeholder="Website"
                                id="website"
                              ></input> */}
                              <input
                                type="text"
                                name="phone"
                                placeholder="Contact number (Optional)"
                                class="input input-form left w-input"
                                id="contact"
                              ></input>
                            </div>

                            {/* <div
                              class="grid-item-1fr"
                              id="w-node-_111d0742-1c68-c3f8-2732-86d768991d27-42a43329090"
                            >
                              <input
                                type="text"
                                name="phone"
                                placeholder="Contact number (Optional)"
                                class="input input-form left w-input"
                              ></input>
                            </div> */}
                            <div
                              class="grid-item-2fr"
                              id="w-node-_111d0742-1c68-c3f8-2732-86d768991d27-42a43325"
                            >
                            </div>
                          </div>
                        </div>
                        <input
                          type="submit"
                          value="Update Profile"
                          style={{ color: "black" }}
                          className="loginButton btn-primary width-100 w-button"
                          onClick={this.updateProfileValue}
                        ></input>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {

    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
