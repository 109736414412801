import axios from "axios";
import { API_URL } from "../utils/constant";
import { CREATE_CLOUD_ACCOUNT, GET_RESULT_BY_ACCOUNT_ID, GET_SCAN_BY_ACCOUNT_ID, GET_SCAN_DETAILS } from "./index";
import { toastr } from "react-redux-toastr";



export const createScan = (props, dispatch) => {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: CREATE_CLOUD_ACCOUNT,
      payload: null,
    });
    axios
      .post(`${API_URL}/cloud-account/add/${props.organisationId}`, { ...props })
      .then((response) => {
        // window.location.href = '/customers'
        if(response.data.error)
          toastr.error("Error", response.data.userMessage);
        else
          toastr.success("Success", response.data.userMessage);
        dispatch({
          type: CREATE_CLOUD_ACCOUNT,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
  
        dispatch({
          type: CREATE_CLOUD_ACCOUNT,
          payload: { error: true, userMessage: message },
        });
      });
};

export const getScan = (props,dispatch) => {
  if(props){
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: CREATE_CLOUD_ACCOUNT,
      payload: null,
    });
    axios
      .get(`${API_URL}/cloud-account/add/${props}`, {})
      .then((response) => {
        dispatch({
          type: CREATE_CLOUD_ACCOUNT,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
        dispatch({
          type: CREATE_CLOUD_ACCOUNT,
          payload: { error: true, userMessage: message },
        });
      });
    }
}

export const startScan = (props,dispatch) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    // dispatch({
    //   type: CREATE_CLOUD_ACCOUNT,
    //   payload: null,
    // });
    axios
      .post(`${API_URL}/cloud-account/${props}/scan`, {})
      .then((response) => {
        // window.location.href = '/customers'
        if(response.data.error)
          toastr.error("Error", response.data.userMessage);
        else
          toastr.success("Success", "Background scan successfully triggered. The scan may take up to several minutes to complete, after which results will be available on the Scan Reports page.");
        // dispatch({
        //   type: CREATE_CLOUD_ACCOUNT,
        //   payload: response.data.data,
        // });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
  
        // dispatch({
        //   type: CREATE_CLOUD_ACCOUNT,
        //   payload: { error: true, userMessage: message },
        // });
      });
}
  
export const getScanByAccoutId = (props, dispatch) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: GET_SCAN_BY_ACCOUNT_ID,
      payload: null,
    });
    axios
      .get(`${API_URL}/cloud-account/${props}/scan`, {})
      .then((response) => {
        dispatch({
          type: GET_SCAN_BY_ACCOUNT_ID,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
        dispatch({
          type: GET_SCAN_BY_ACCOUNT_ID,
          payload: { error: true, userMessage: message },
        });
      });
}
export const getResultByAccoutId = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: GET_SCAN_BY_ACCOUNT_ID,
    payload: null,
  });
  axios
    .get(`${API_URL}/cloud-account/${props}/logs`, {})
    .then((response) => {
      dispatch({
        type: GET_RESULT_BY_ACCOUNT_ID,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_RESULT_BY_ACCOUNT_ID,
        payload: { error: true, userMessage: message },
      });
    });
}


export const getScanDetails = (props, dispatch) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: GET_SCAN_DETAILS,
      payload: null,
    });
    axios
      .get(`${API_URL}/scanDetails/${props}`, {})
      .then((response) => {
        dispatch({
          type: GET_SCAN_DETAILS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
  
        dispatch({
          type: GET_SCAN_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
}