import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAlertDetails } from "../../actions/integration";

const InboxDetails = () => {
  const { alertId } = useParams();
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState([]);

  const dispatch = useDispatch();
  const orgId = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  );

  //console.log("orgId=", orgId);

  const alertResp = useSelector((state) => state?.integration?.response);
  //console.log("alertResp", alertResp);

  useEffect(() => {
    if (loading && alertResp) {
      setLoading(false);
      setAlert(alertResp.alerts[0]);
    }
  }, [loading, alertResp]);

  useEffect(() => {
    //console.log("loading", loading, orgId);
    const fetchData = async (alertId) => {
      await fetchAlertDetails({ alertId }, dispatch);
    };
    if (loading && alertId && !alertResp) {
      fetchData(alertId);
    }
  }, [dispatch, loading, alertId]);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Inbox</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Log Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card">
              <div className="mail-inbox">
                <div className="mobile-left">
                  <Link to="/" className="btn btn-primary toggle-email-nav">
                    <i className="fa fa-bars"></i>
                  </Link>
                </div>

                <div className="body mail-right check-all-parent">
                  {!loading && alert ? (
                    <div className="mail-detail-full">
                      <div className="mail-action clearfix">
                        <Link
                          to="/app-inbox"
                          className="btn btn-primary btn-sm mr-1"
                        >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                      </div>
                      <div className="detail-header">
                        <div className="media">
                          {/* <div className="float-left">
                                <div className="m-r-20"><img src="../assets/images/sm/avatar1.jpg" className="rounded" alt="avatar" /></div>
                            </div> */}
                          <div className="media-body">
                            <p className="mb-0">
                              <strong className="text-muted m-r-5">
                                From :
                              </strong>
                              <a className="text-default" href>
                                support@brandsek.com
                              </a>
                              <span className="text-muted text-sm float-right">
                                {moment(alert.alertSentAt).format('LLL')}
                              </span>
                            </p>
                            <p className="mb-0">
                              <strong className="text-muted m-r-5">To :</strong>
                              {alert.alertTo}
                              <small className="text-muted float-right">
                                <i className="zmdi zmdi-attachment m-r-5"></i>
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mail-cnt"   dangerouslySetInnerHTML={{__html: alert.alertContent}}>
                        
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InboxDetails;
