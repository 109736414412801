import React, { useEffect, useState, useRef } from "react";
import MarketingChart from "../common/widgets/MarketingChart";
import { Link, useParams } from "react-router-dom";
import { fetchVendorSummary } from "../../actions/summary";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Widgets = () => {
  const dispatch = useDispatch();
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const [filterData, setfilterData] = useState("all");
  const { orgId } = useParams();
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  useEffect(() => {
    fetchVendorSummary(id, dispatch);
  }, [id]);

  let summary = useSelector((state) => state?.summary?.vendorSummary);

  summary = summary?.sort((a, b) =>
    (moment(a?.createdOn).isBefore(moment(b?.createdOn))) ? 1 : (moment(b?.createdOn).isBefore(moment(a?.createdOn))) ? -1 : 0
    // a.dec > b.dec ? -1 : b.dec > a.dec ? 1 : 0
  )

  const vendorWithA1andA2 = summary?.filter((row) =>
    row?.rowStatus == "A1" ||
    row?.rowStatus == "A2"
  )

  const vendorWithB1andB2 = summary?.filter((row) =>
    row?.rowStatus == "B1" ||
    row?.rowStatus == "B2"
  )

  const vendorWithC1andC2 = summary?.filter((row) =>
    row?.rowStatus == "C1" ||
    row?.rowStatus == "C2"
  )

  const vendorWithA1 = summary?.filter((row) =>
    row?.rowStatus == "A1"
  )

  const vendorWithA2 = summary?.filter((row) =>
    row?.rowStatus == "A2"
  )

  const vendorWithB1 = summary?.filter((row) =>
    row?.rowStatus == "B1"
  )

  const vendorWithB2 = summary?.filter((row) =>
    row?.rowStatus == "B2"
  )

  const vendorWithC1 = summary?.filter((row) =>
    row?.rowStatus == "C1"
  )

  const vendorWithC2 = summary?.filter((row) =>
    row?.rowStatus == "C2"
  )

  const vulnerableA1andA2Percent = ((vendorWithA1andA2?.length / summary?.length) * 100)?.toFixed(0);
  const vulnerableB1andB2Percent = ((vendorWithB1andB2?.length / summary?.length) * 100)?.toFixed(0);
  const vulnerableC1andC2Percent = ((vendorWithC1andC2?.length / summary?.length) * 100)?.toFixed(0);


  let mapData =
    filterData === "all"
      ? summary
      : filterData === "a1"
        ? vendorWithA1
        : filterData === "a2"
          ? vendorWithA2
          : filterData === "b1"
            ? vendorWithB1
            : filterData === "b2"
              ? vendorWithB2
              : filterData === "c1"
                ? vendorWithC1
                : filterData === "c2"
                  ? vendorWithC2
                  : summary;

  const downloadCsvData = summary?.map((res) => ({
    Vendor: res?.vendor + " " + moment(res?.createdOn).format("DD-MMM-YYYY hh:mm a"),
    OverallRating: res?.rowStatus,
    VulnerableIPs: res?.vulnerableIp,
    BlacklistedIPs: res?.blacklistedIPValue,
    SPFRecord: res?.spfRating,
    DMARCRecord: res?.dmarcRating,
    ExpiredSSL: res?.expiredSSLRating,
    VulnerableSSL: res?.vulnerableSSLRating,
    DanglingSubdomain: res?.danglingRating,
    // LastUpdated: res?.updatedAt
    //   ? moment(res?.updatedAt).format("DD-MM-YYYY HH:MM")
    //   : res?.createdAt
    //     ? moment(res?.createdAt).format("DD-MM-YYYY HH:MM")
    //     : "NA",
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const setBadge = (val) => {
    if (val == "Pending")
      return "font-12 mb-0 badge badge-info";
    else if (val == "A1" || val == "A2")
      return "font-12 mb-0 badge badge-success";
    else if (val == "B1" || val == "B2")
      return "font-12 mb-0 badge badge-warning";
    else if (val == "C1" || val == "C2")
      return "font-12 mb-0 badge badge-danger";
  }

  //console.log(summary, "summary", vendorWithA1andA2?.length, vendorWithB1andB2?.length, vendorWithC1andC2?.length);
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Vendor Summary</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                    <Link to="/vendor">Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{  }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li className="breadcrumb-item active" aria-current="page">
                  Summary
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span
              onClick={(e) => {
                e.preventDefault();
                exportToCSV(downloadCsvData, "Vendor Summary");
              }}
              // style={{ float: "right" }}
              // href="/add-report"
              className="btn btn-primary mr-1"
            >
              <i className="fa fa-file mr-1" style={{   }}></i> <span style={{  fontWeight: "bold" }}>Download Report</span>
            </span>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        {/* <div className="col-lg-4 col-md-12">
          <div className="card">
            <div className="body">
              <div className="row text-center">
                <div className="col-lg-12 col-sm-12 border-right pb-4 pt-4">
                  <label className="mb-0">Total Vendors</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {summary?.length || 0}
                  </h4>
                </div>
              </div>
              <hr />
              <div className="mb-4 mt-3">
                <label className="d-block">
                  Vendor with <strong>A (A1 & A2)</strong> rating.
                  <span className="float-right">{vulnerableA1andA2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={vulnerableA1andA2Percent || 0}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableA1andA2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>

              <div className="mb-4">
                <label className="d-block">
                  Vendor with <strong>B (B1 & B2)</strong> rating.
                  <span className="float-right">{vulnerableB1andB2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={vulnerableB1andB2Percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableB1andB2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>

              <div className="mb-4">
                <label className="d-block">
                  Vendor with <strong>C (C1 & C2)</strong> rating.
                  <span className="float-right">{vulnerableC1andC2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-danger"
                    role="progressbar"
                    aria-valuenow={vulnerableC1andC2Percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableC1andC2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="body">
              <div className="header">
                <h2>Security ratings over time </h2>
              </div>
              <MarketingChart />
            </div>
          </div>
        </div> */}

        <div className="col-12">
          <div className="card">
            <div className="body">
              <div className="header  pb-1">
                <div className="row">
                  <div className="col-sm-8">
                    <h6>Vendor wise exposure </h6>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="a1">A1</option>
                      <option value="a2">A2</option>
                      <option value="b1">B1</option>
                      <option value="b2">B2</option>
                      <option value="c1">C1</option>
                      <option value="c2">C2</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {" "}
                        <b>Vendor</b>
                      </th>{" "}
                      {/* <th>
                      Overall Rating
                    </th> */}
                      <th>
                        {" "}
                        <b>Vulnerable IPs</b>
                      </th>
                      <th>
                        {" "}
                        <b>Blacklisted IPs</b>
                      </th>
                      <th>
                        {" "}
                        <b>SPF Record</b>
                      </th>
                      <th>
                        {" "}
                        <b>DMARC Record</b>
                      </th>
                      <th>
                        {" "}
                        <b>Expired SSL</b>
                      </th>
                      <th>
                        {" "}
                        <b>Vulnerable SSL</b>
                      </th>
                      <th>
                        {" "}
                        <b>Dangling Subdomain</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mapData?.map((row, index) => {
                      return (
                        <tr>
                          <td>
                            <strong>
                              <span>{index + 1}</span>
                            </strong>
                          </td>
                          <td>
                            <a href={`/vendorDetails/${row?._id}`}>{row?.vendor}</a>
                            <p style={{  }}>
                              Added on{" "}
                              {moment(row?.createdOn).format(
                                "DD-MMM-YYYY hh:mm a"
                              )}
                            </p>
                          </td>
                          {/* <td className="text-right">
                          <h6 className={setBadge(row?.rowStatus)}>{row?.rowStatus}</h6>
                          <div className="d-flex bd-highlight mt-2">
                            
                              <div className="flex-fill bd-highlight">
                              <small> <br/>
                              </small>
                              </div>
                            </div>
                        </td> */}
                          <td className="text-medium">
                            <h6 className={setBadge(row?.vulnerableIp)}>{row?.vulnerableIp}</h6>
                            {row?.vulnerableIp == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.vulnerableIp != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small style={{  }}>
                                    {row?.totalVulnerableIp}/{row?.totalIp} found vulnearable
                                  </small>
                                </div>
                                {/* <div className="flex-fill bd-highlight">
                              <small>
                                {row?.highIp}/{row?.totalIp}
                              </small>
                            </div>
                            <div className="flex-fill bd-highlight">
                              <small>
                                {row?.mediumIp}/{row?.totalIp}
                              </small>
                            </div> */}
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.blacklistedIPValue)}>
                              {row?.blacklistedIPValue}
                            </h6>
                            {row?.blacklistedIPValue == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.blacklistedIPValue != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.blacklistedIp}/{row?.totalBlackListedIp}{" "}
                                    Blacklisted IPs
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.spfRating)}>{row?.spfRating}</h6>
                            {row?.spfRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.spfRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>{row?.spfNumber}/1 compliant</small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.dmarcRating)}>{row?.dmarcRating}</h6>
                            {row?.dmarcRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.dmarcRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>{row?.dmarcNumber}/1 compliant</small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.expiredSSLRating)}>
                              {row?.expiredSSLRating}
                            </h6>
                            {row?.expiredSSLRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.expiredSSLRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.expiredSSL}/{row?.subDomain} expired
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.vulnerableSSLRating)}>
                              {row?.vulnerableSSLRating}
                            </h6>
                            {row?.vulnerableSSLRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.vulnerableSSLRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.vulnerabbleSSL}/{row?.subDomain}{" "}
                                    vulnerable
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.danglingRating)}>{row?.danglingRating}</h6>
                            {row?.danglingRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.danglingRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.danglingValue}/
                                    {row?.totalDanglingSubdomains}{" "}
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row clearfix"> */}

      {/* </div> */}
    </div>
  );
};

export default Widgets;
