import {
  SUSPICIOUS_CLOUD_TOTAL,
  SUSPICIOUS_CLOUD_GRAPH
} from "../actions/";

const initialState = {
  data: [],
  suspicious: [],
  cloudbuckettotal:[],
  cloudbucketgraph:[]
};

const openCloud = (state = initialState, action) => {
  switch (action.type) {
    case SUSPICIOUS_CLOUD_GRAPH:
      return {
        ...state,
        cloudbucketgraph:action.data
      }
    case SUSPICIOUS_CLOUD_TOTAL:
      return {
        ...state,
        cloudbuckettotal:action.data
      }
    case "SET_OPEN_CLOUD":
      return {
        ...state,
        data: action.data,
      };
      case "SET_OPEN_CLOUD_ALL":
        return {
          ...state,
          data: action.data,
        };
      case "SET_SUSPICIOUS_CLOUD":
        return {
          ...state,
          suspicious: action.data,
        };
    default:
      return state;
  }
};

export default openCloud;
