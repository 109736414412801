import {
    FETCH_CVE_DETAILS ,FETCH_EXEL_DATA,FETCH_CVE_DETAILS_GRAPH , FETCH_CVE_DETAILS_TOTAL
  } from "../actions";
  
  const initialState = {
    loading: true,
    cveDetails: {},
    masterScan: [],
    masterScanData: [],
    scanCompleted: true,
    cveGraph:[],
    cveTotal:[]
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCH_CVE_DETAILS_GRAPH:
        return {
          ...state,
          cveGraph: action.payload,
        };
        case FETCH_CVE_DETAILS_TOTAL:
          return {
            ...state,
            cveTotal: action.payload,
          };
      case FETCH_CVE_DETAILS:
        return {
          ...state,
          cveDetails: action.payload,
          scanCompleted: action.payload.scanCompleted
        };
      case "MASTER_SCAN":
        return {
          ...state,
          masterScan: action.payload
        };
      case "MASTER_SCAN_DETAILS":
        return {
          ...state,
          masterScanData: action.payload
        };
        case FETCH_EXEL_DATA:
          return {
            ...state,
            exelData: action.payload
          };
      default:
        return state;
    }
  }
  