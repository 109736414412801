import React, { Component } from 'react';
import Chart from "react-apexcharts";
import moment from 'moment';
class KnownsubscribersChart extends Component {
    constructor(props) {
       console.log(props,'load')
        super(props);
        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 280,
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        width: 10,
                        columnWidth: "10%"
                    },
                },
                fill: {
                    colors: ['#dc2b2b', '#853120', '#ff8c00' , '#3e9552']
                },
                colors: ['#dc2b2b', '#853120', '#ff8c00' ,'#3e9552'],
                yaxis: {
                    show: true
                },
                xaxis: {
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                },
                labels:  props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                // legend: {
                //     show: true
                // }
            },
            series: [
                {
                    name: "Critical",
                    data: props?.data?.map((item) => item?.totalCriticalVulnerabilities),
                  },
                  {
                    name: "High",
                    data: props?.data?.map((item) => item?.totalHighVulnerabilities),
                  },
                  {
                    name: "Medium",
                    data: props?.data?.map((item) => item?.totalMediumVulnerabilities),
                  },
                  {
                    name: "Low",
                    data: props?.data?.map((item) => item?.totalLowVulnerabilities),
                  },
            ]
        };
    }
    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    // width="100%"
                    height="280px"
                />
            </div>
        );
    }
}

export default KnownsubscribersChart;


