import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { EditDes } from "../../actions/cveInformation";
import { useDispatch } from "react-redux";

const EditDescription = ({ cveId, description, fieldType , onEditCancel }) => {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [editedDescription, setEditedDescription] = useState(description);


  const saveEditedDescription = () => {
    EditDes( dispatch , cveId, fieldType, editedDescription )
  };

  return (
    <div className="h-full w-full backdrop-blur-sm z-10 absolute flex items-center justify-center">
      <div className="w-1/3 p-4 flex flex-col gap-3 bg-gray-200 rounded-md">
        <div className="font-bold">Edit Description</div>
        <textarea
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.target.value)}
          className="w-full rounded-md focus:outline-none p-2 h-40"
        />
        <div className="flex flex-row gap-3 justify-end">
          <button onClick={onEditCancel}>Cancel</button>
          <button onClick={saveEditedDescription}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditDescription;
