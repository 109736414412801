import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  deleteCustomerNew,
  fetchCustomersNew,
  refreshCustomer,
  createCustomer,
  updateCustomer,
  fetchThreatAnalyst,
} from "../../actions/customer";
import { IMAGE_URL } from "../../utils/constant";
import { Link, useParams } from "react-router-dom";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

var Model = require("../information/model");

const Clients = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.auth?.user);
  const [orgId, setOrgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setModal] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [services, setServices] = useState([]);
  var date = new Date();
  date = moment(date).add(1, "year").toDate();
  const [expiredAt, selectDate] = useState(date);
  const [status, setStatus] = useState("all");
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [scanType, setscanType] = useState("No");
  const [scanScheduleDay, setscanScheduleDay] = useState("Sunday");

  const [orgName, setOrgName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgContactName, setOrgContactName] = useState("");
  const [orgContact, setOrgContact] = useState("");
  const [website, setWebsite] = useState("");
  const [emailAccess, setEmailAccess] = useState(false);
  const [clientType, setClientType] = useState("1");
  const [plan, setPlan] = useState("basic");
  const [industryType, setindustryType] = useState("Government");
  const [domains, setDomain] = useState([]);
  const [authMethod, setAuthMethod] = useState("default");
  const [assignTo, setAssignTo] = useState("");
  const [serverType, setServerType] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [orgLogo, setOrgLogo] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [loginPage, setLoginPage] = useState("");

  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");

  const [keyWord, setKeyword] = useState("");
  const [license, setLicense] = useState("");

  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const isMSSP =
    JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP";

  let clientTypeData = [];
  if (JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP")
    clientTypeData = [{ id: 1, text: "Client" }];
  else
    clientTypeData = [
      { id: 1, text: "Client" },
      { id: 3, text: "MSSP" },
    ];

  let subscriptionTypeData = [];
  let subscriptionValueMSSP = [
    { val: "", text: "MSSP Subscription Type" },
    { val: "trial", text: "Trial" },
    { val: "regular", text: "Regular Client" },
  ];
  let subscriptionValueClient = [
    { val: "", text: "Subscription Type" },
    // {val: "trial", text: "Trial" },
    // {val: "one-time", text: "One time" },
    // {val: "gold", text: "Gold" },
    { val: "platinum", text: "Platinum" },
  ];
  if (JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP") {
    if (
      JSON.parse(localStorage.getItem("user")).organisationId
        ?.subscriptionType == "trial"
    ) {
      subscriptionValueMSSP = [
        { val: "", text: "Subscription Type" },
        { val: "trial", text: "Trial" },
      ];
    } else {
      subscriptionValueMSSP = [
        { val: "", text: "Subscription Type" },
        // {val: "trial", text: "Trial" },
        // {val: "one-time", text: "One time" },
        // {val: "gold", text: "Gold" },
        { val: "platinum", text: "Platinum" },
      ];
    }
  }

  // console.log(auth, "auth");
  let customers = useSelector((state) => state?.customer?.response?.data);
  const threatAnalyist = useSelector(
    (state) => state?.customer?.threatAnalyist
  );
  if (threatAnalyist && threatAnalyist.length > 0 && assignTo == "") {
    setAssignTo(threatAnalyist[0]._id);
  }
  useEffect(() => {
    fetchCustomersNew(dispatch);
    fetchThreatAnalyst(dispatch);
  }, []);

  let clientList = [],
    msspList = [];
  for (let count = 0; count < customers?.length; count++) {
    if (customers[count].clientType == 1) {
      clientList.push(customers[count]);
    }
  }
  for (let count = 0; count < customers?.length; count++) {
    if (customers[count].clientType == 3) {
      msspList.push(customers[count]);
    }
  }

  let mapData =
    filterData === "all"
      ? customers
      : filterData === "client"
        ? clientList
        : filterData === "mssp"
          ? msspList
          : null;

  mapData =
    search == ""
      ? mapData
      : mapData?.filter(
        (row) =>
          row?.orgName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1 ||
          row?.orgContactName
            ?.toLowerCase()
            ?.indexOf(search?.toLowerCase()) != -1 ||
          row?.orgEmail?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
      );

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }
  const downloadCsvData = customers?.map((res) => ({
    Client: res?.orgName,
    PrimaryContactName: res?.orgContactName,
    Email: res?.orgEmail,
    Contact: res?.orgContact,
    Website: res?.website,

    // Severity: severity(res?.results?.cvss),
    // Ageing:
    //   moment().diff(moment(res?.results["last-modified"]), "days") + " days",
    // Description: res?.results?.summary,
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const handleClick = (id) => {
    console.log("handleClick");
    if (id == "keyword") {
      let val = $("#keyword").val();
      let _keyWord = keyWord || [];
      if (plan == "pro" && _keyWord.length >= 5) {
        toastr.error("Error", "Maximum keyword limit reached for this plan.");
        return false;
      }
      if (plan == "corporate" && _keyWord.length >= 50) {
        toastr.error("Error", "Maximum keyword limit reached for this plan.");
        return false;
      }
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#keyword").val("");
      // _keyWord.push(val);
      setKeyword([..._keyWord, val]);
    } else if (id == "facebook") {
      let val = $("#facebookUrl").val();
      let _keyWord = facebookUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#facebookUrl").val("");
      // _keyWord.push(val);
      setFacebookUrl([..._keyWord, val]);
    } else if (id == "linkedin") {
      let val = $("#liknedinUrl").val();
      let _keyWord = linkedinUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#liknedinUrl").val("");
      // _keyWord.push(val);
      setLinkedinUrl([..._keyWord, val]);
    } else if (id == "twitter") {
      let val = $("#twitterUrl").val();
      let _keyWord = twitterUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#twitterUrl").val("");
      // _keyWord.push(val);
      setTwitterUrl([..._keyWord, val]);
    } else if (id == "instagram") {
      let val = $("#instagramUrl").val();
      let _keyWord = instagramUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#instagramUrl").val("");
      // _keyWord.push(val);
      setInstagramUrl([..._keyWord, val]);
    } else {
      let val = $("#domain").val();
      let _domains = domains || [];
      if ((plan == "basic" || plan == "pro") && _domains.length >= 1) {
        toastr.error("Error", "Maximum domain limit reached for this plan.");
        return false;
      }
      // if (plan == "corporate" && _domains.length >= 3) {
      //   toastr.error("Error", "Maximum domain limit reached for this plan.");
      //   return false;
      // }
      if (_domains.indexOf(val) != -1) {
        toastr.error("Error", "This domain is already added.");
        return false;
      }
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      var valid = val.match(re);
      if (valid) {
        $("#domain").val("");
        // _domains.push(val);
        setDomain([..._domains, val]);
      } else {
        toastr.error("Error", "Please enter a valid domain.");
        return false;
      }
    }
  };

  const removeKeyword = (_id, _keyword) => {
    //console.log("removeKeyword");
    if (_id == "keyword") {
      let newkeyword = keyWord;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setKeyword([...newkeyword]);
    } else if (_id == "facebook") {
      let newkeyword = facebookUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setFacebookUrl([...newkeyword]);
    } else if (_id == "linkedin") {
      let newkeyword = linkedinUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setLinkedinUrl([...newkeyword]);
    } else if (_id == "twitter") {
      let newkeyword = twitterUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setTwitterUrl([...newkeyword]);
    } else if (_id == "instagram") {
      let newkeyword = instagramUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setInstagramUrl([...newkeyword]);
    } else {
      let _domain = domains;
      _domain.splice(_domain.indexOf(_keyword), 1);
      setDomain([..._domain]);
    }
  };

  const removeOrgLogo = () => {
    setOrgLogo("");
  };

  const handleLogoChange = (e) => {
    //console.log(e, e.target.files[0]);
    // Model.default.saveOrgLogo
    Model.default.saveOrgLogo(e.target.files[0]).then((response) => {
      //console.log({ response });
      if (!response.error) {
        //console.log(response.data, "data");
        let orgLogo = response.data;
        setOrgLogo(orgLogo);
      }
    });
  };

  const handleFileChange = (e) => {
    //console.log(e, e.target.files[0]);
    // Model.default.saveOrgLogo
    Model.default.uploadFile(e.target.files[0]).then((response) => {
      //console.log({ response });
      if (!response.error) {
        //console.log(response.data, "data");
        let orgLogo = response.data;
        setOrgLogo(orgLogo);
      }
    });
  };

  const submitForm = () => {
    if (scanType !== "weekly") {
      setscanScheduleDay("");
    }

    if (orgName == "") {
      toastr.error("Error", "Please enter organisation name.");
      return false;
    }
    if (orgContactName == "") {
      toastr.error("Error", "Please enter contact name.");
      return false;
    }
    var regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (!regex.test(website)) {
      toastr.error("Error", "Website address is incorrect.");
      return false;
    }
    if (country == "") {
      toastr.error("Error", "Please select the country.");
      return false;
    }
    if (industryType == "") {
      toastr.error("Error", "Please select the industry type.");
      return false;
    }
    if (orgEmail == "") {
      toastr.error("Error", "Please enter organisation email.");
      return false;
    }
    // if(emailAccess == ""){
    //   toastr.error("Error", "Please select email access.");
    //   return false;
    // }
    if (clientType == "") {
      toastr.error("Error", "Please select client type.");
      return false;
    }
    if (industryType == "") {
      toastr.error("Error", "Please select Industry.");
      return false;
    }
    if (subscriptionType == "") {
      toastr.error("Error", "Please select subscription level.");
      return false;
    }
    // if (orgLogo == "") {
    //   toastr.error("Error", "Please upload logo.");
    //   return false;
    // }
    if (keyWord == "") {
      toastr.error("Error", "Please add keywords.");
      return false;
    }

    if (isEdit) {
      updateCustomer(
        {
          orgName,
          orgEmail,
          orgContact,
          website,
          loginPage,
          emailAccess,
          plan,
          clientType,
          domains,
          authMethod,
          scanType,
          scanScheduleDay,
          // assignTo,
          orgLogo,
          subscriptionType,
          address,
          country,
          state,
          city,
          facebookUrl,
          twitterUrl,
          linkedinUrl,
          instagramUrl,
          keyWord,
          orgId,
          orgContactName,
          expiredAt,
          serverType,
          loginUrl,
          industryType,
          license,
          // services
        },
        dispatch
      )
        .then(() => {
          setModal("");
        })
        .catch(() => { });
    } else {
      createCustomer(
        {
          industryType,
          scanType,
          scanScheduleDay,
          orgName,
          orgEmail,
          orgContact,
          website,
          loginPage,
          emailAccess,
          plan,
          clientType,
          domains,
          authMethod,
          // assignTo,
          orgLogo,
          subscriptionType,
          address,
          country,
          state,
          city,
          facebookUrl,
          twitterUrl,
          linkedinUrl,
          instagramUrl,
          keyWord,
          orgContactName,
          expiredAt,
          serverType,
          loginUrl,
          license,
          // services
        },
        auth,
        dispatch
      )
        .then(() => {
          setModal("");
        })
        .catch(() => { });
    }
    // setModal("");
  };

  const addModal = () => {
    setIsEdit(false);
    setOrgName("");
    setOrgEmail("");
    setOrgContact("");
    setWebsite("");
    // setEmailAccess("")
    // setClientType("")
    // setSubscriptionType("")
    setDomain([]);
    setAuthMethod("");
    setAssignTo("");
    setOrgLogo("");
    // setSubscriptionType("")
    setAddress("");
    setCountry("");
    setState("");
    setCity("");
    setFacebookUrl("");
    setTwitterUrl("");
    setLinkedinUrl("");
    setInstagramUrl("");
    setKeyword("");
    setOrgContactName("");
    setscanScheduleDay("Sunday");
    // setServices(['AttackSurface','DarkWeb','BrandSecurity','TPSA'])
    setServices(["AttackSurface", "DarkWeb", "BrandSecurity"]);
    setscanType("No");
    setModal("Form Wizard modal");
  };

  const editModal = (row) => {
    //console.log(row, "row");
    setIsEdit(true);
    setindustryType(row?.industryType);
    setOrgId(row?._id);
    setOrgName(row?.orgName);
    setOrgEmail(row?.orgEmail);
    setOrgContact(row?.orgContact);
    setWebsite(row?.website);
    setLoginPage(row?.loginPage);
    setEmailAccess(row?.emailAccess);
    setClientType(row?.clientType);
    setSubscriptionType(row?.subscriptionType);
    setDomain(row?.domains);
    setAuthMethod(row?.authMethod);
    setAssignTo(row?.assignTo);
    setOrgLogo(row?.orgLogo);
    setscanScheduleDay(row?.scanScheduleDay);
    // setSubscriptionType(row?.subscriptionType)
    setAddress(row?.address);
    setCountry(row?.country);
    setState(row?.state);
    setCity(row?.city);
    setscanType(row?.scanType);
    setFacebookUrl(row?.facebookUrl);
    setTwitterUrl(row?.twitterUrl);
    setLinkedinUrl(row?.linkedinUrl);
    setInstagramUrl(row?.instagramUrl);
    setKeyword(row?.keyWord);
    setOrgContactName(row?.orgContactName);
    selectDate(row?.expiredAt ? new Date(row?.expiredAt) : new Date());
    setServerType(row?.serverType);
    setLoginUrl(row?.loginUrl);
    // setServices(row?.services || []);
    setPlan(row?.plan || "basic");
    setModal("Form Wizard modal");
  };

  const deleteCustomerOption = (id) => {
    const self = this;
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to delete this customer?",
      type: "red",
      buttons: {
        ok: {
          text: "Delete",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            deleteCustomerNew(id, dispatch);
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  };

  //console.log(threatAnalyist, domains.length, "domains && domains.length");
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Dashboard</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Clients
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Client Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-12">
                  {/* <span className="btn btn-sm btn-primary mr-1" title>
                    Search
                  </span> */}
                  {auth?.accessLevel != "Threat Analyst" && (
                    <button
                      type="button"
                      className="btn btn-primary mr-1"
                      style={{ fontWeight: "bold" }}
                      onClick={() => addModal()}
                    >
                      <i class="fa-solid fa-magnifying-glass mr-1"></i> Add New
                    </button>
                  )}
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  {customers?.length === 0 ? null : (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        exportToCSV(downloadCsvData, "Client List");
                      }}
                      style={{
                        float: "right",

                        fontWeight: "bold",
                      }}
                      href="/add-report"
                      className="btn btn-primary "
                    >
                      <i
                        className="fa fa-file-excel-o"
                        style={{}}
                      ></i>
                    </a>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">

                  <thead>
                    <div>
                      <select
                        className="custom-select custom-select-sm form-control form-control-sm"
                        style={{ width: "200px", marginTop: "10px" }}
                        value={filterData}
                        onChange={(e) => setfilterData(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="client">Clients</option>
                        <option value="mssp">MSSP</option>
                      </select>
                    </div>
                    <tr>
                      <th style={{ width: "100px" }}>#</th>
                      <th>Customer Name</th>
                      <th>Client Type</th>
                      <th>Subscription Type</th>
                      <th>Email Address</th>
                      <th>Websites</th>
                      <th>Registered on</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((item, index) => {
                      return (
                        <tr>
                          <td style={{ width: "100px" }}>
                            <span>{index + 1}</span>
                          </td>
                          <td>
                            <a
                              href={`/user/${item._id}`}
                              title=""
                              style={{}}
                            >
                              {item.orgName}
                            </a>
                            <p className="mb-0">{item.orgContactName}</p>
                          </td>
                          <td>
                            <span className="badge badge-warning">
                              {item.clientType == 1 ? "Client" : "MSSP"}
                            </span>
                          </td>
                          <td>

                            <span className="badge badge-success">
                              {item.subscriptionType}
                            </span>

                          </td>
                          <td>
                            <span>{item.orgEmail}</span>
                          </td>

                          <td>
                            <a
                              href={`${item?.website}`}
                              target="_blank"
                              style={{}}
                            >
                              {item?.website}
                            </a>
                          </td>
                          <td>
                            <span>
                              {" "}
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}{" "}
                            </span>
                          </td>
                          <td>
                            {auth?.accessLevel != "Threat Analyst" && (
                              <button
                                className="btn btn-primary btn-sm mr-1"
                                style={{ fontWeight: "bold" }}
                                onClick={() => editModal(item)}
                              >
                                Edit
                              </button>
                            )}
                            {auth?.accessLevel != "Threat Analyst" &&
                              !isMSSP && (
                                <button
                                  className="btn btn-primary btn-sm mr-1"
                                  style={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    setSelectedOrgId(item._id);
                                    setRefreshModal(true);
                                  }}
                                >
                                  Refresh
                                </button>
                              )}
                            {auth?.accessLevel != "Threat Analyst" && (
                              <button
                                className="btn btn-primary btn-secondary btn-sm  mr-1"
                                style={{ fontWeight: "bold" }}
                                onClick={() => {
                                  deleteCustomerOption(item._id);
                                }}
                              >
                                Delete
                              </button>
                            )}
                            <Link
                              className="btn btn-primary btn-brder-detail  btn-sm mr-1"
                              style={{ fontWeight: "bold" }}
                              to={`/show-details/${item._id}`}
                            >
                              Show Details
                            </Link>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg clientPageModal" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h6>Add New Client</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className=" ">
                <div className="card mb-0">
                  <div className="header">
                    <h2 style={{}}>Basic Information</h2>
                  </div>
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client Name *"
                          value={orgName}
                          onChange={(e) => setOrgName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email address *"
                          value={orgEmail}
                          onChange={(e) => setOrgEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Primary Contact Name"
                          value={orgContactName}
                          onChange={(e) => setOrgContactName(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Contact Number"
                            value={orgContact}
                            onChange={(e) => setOrgContact(e.target.value)}
                          />
                        </div>
                      </div> */}
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-globe"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="http://"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {auth?.accessLevel == "Admin" && (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Login page"
                              value={loginPage}
                              onChange={(e) => setLoginPage(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!isMSSP ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={emailAccess}
                            onChange={(e) => setEmailAccess(e.target.value)}
                          >
                            {/* <option value="">-- Email Access * --</option> */}
                            <option value={false}>
                              Email access to client?
                            </option>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {!isMSSP ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={clientType}
                            onChange={(e) => setClientType(e.target.value)}
                          >
                            {/* <option value="">-- Select Client Type * --</option> */}
                            {clientTypeData?.map((row) => {
                              return <option value={row.id}>{row.text}</option>;
                            })}
                            {/* <option value="client">Client</option>
                            <option value="mssp">MSSP</option> */}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-lg-6 col-md-12">
                      <select
                        className="form-control"
                        value={industryType}
                        onChange={(e) => {
                          setindustryType(e.target.value);
                        }}
                      >
                        <option value="Government">Government</option>
                        <option value="NGO">NGO</option>
                        <option value="Finance">Finance</option>
                        <option value="Technology">Technology</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Education">Education</option>
                        <option value="Telecommunications">
                          Telecommunications
                        </option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Construction">Construction</option>
                        <option value="Energy">Energy</option>
                        <option value="Chemical">Chemical</option>
                        <option value="Media">Media</option>
                        <option value="Aerospace">Aerospace</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Retail">Retail</option>
                      </select>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <select
                        className="form-control"
                        value={plan}
                        onChange={(e) => {
                          setPlan(e.target.value);
                          setDomain([]);
                        }}
                      >
                        <option value="basic">Basic plan</option>
                        <option value="pro">Pro plan</option>
                        <option value="corporate">Corporate plan</option>
                      </select>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 clientCalendar">
                        <div className="form-group">
                          <DatePicker
                            selected={expiredAt}
                            minDate={new Date()}
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={1}
                            onChange={(date) => selectDate(date)}
                            className="form-control datetimepicker"
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        </div>
                      </div> */}
                    {clientType == 3 && (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="License"
                            value={license}
                            onChange={(e) => setLicense(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={subscriptionType}
                          onChange={(e) => setSubscriptionType(e.target.value)}
                        >
                          {/* <option value="">-- Subscription Level * --</option> */}
                          {JSON.parse(localStorage.getItem("user"))
                            .accessLevel == "MSSP"
                            ? subscriptionValueMSSP?.map((val) => {
                              return (
                                <option value={val.val}>{val.text}</option>
                              );
                            })
                            : clientType == 3
                              ? subscriptionValueMSSP?.map((val) => {
                                return (
                                  <option value={val.val}>{val.text}</option>
                                );
                              })
                              : subscriptionValueClient?.map((val) => {
                                return (
                                  <option value={val.val}>{val.text}</option>
                                );
                              })}
                        </select>
                      </div>
                    </div>
                    {clientType != 3 && (
                      <div className="col-lg-6 col-md-12">
                        {/* <div className="form-group">
                          <select className="form-control">
                            <option value="">--Domains --</option>
                            <option value="trial">Domain1</option>
                            <option value="one-time">Domain2</option>
                            <option value="gold">Gold</option>
                            <option value="platinum">Platinum</option>
                          </select>
                        </div> */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Domains"
                            name="domain"
                            id="domain"
                            style={{ float: "left", width: "89%" }}
                          />
                          <button
                            type="button"
                            style={{ float: "left" }}
                            className="btn btn-primary"
                            onClick={() => handleClick("domain")}
                          >
                            <i
                              className="fa fa-plus"
                              style={{}}
                            ></i>
                          </button>
                        </div>
                      </div>
                    )}

                    {domains && domains.length > 0 && (
                      <div className="col-lg-6 col-md-12">
                        <div className="row">
                          {domains?.map((keyword) => {
                            return (
                              <div className="col-md-4">
                                <label
                                  style={{

                                    padding: "4px 6px",
                                    margin: "0px 5px",
                                  }}
                                >
                                  <span>
                                    {keyword}{" "}
                                    <span
                                      className="fa fa-times-circle"
                                      onClick={() =>
                                        removeKeyword("domain", keyword)
                                      }
                                    ></span>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={authMethod}
                            onChange={(e) => setAuthMethod(e.target.value)}
                          >
                            <option value="default">Default</option>
                            <option value="microsoft">Microsoft</option>
                            <option value="google">Google</option>
                          </select>
                        </div>
                      </div> */}
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={assignTo}
                            onChange={(e) => setAssignTo(e.target.value)}
                          >
                            {threatAnalyist?.map((row) => {
                              return (
                                <option value={row?._id}>
                                  {row?.fullName}
                                </option>
                              );
                            })}
                            <option value="default">Analyst 1</option>
                            <option value="microsoft">Analyst 2</option>
                          </select>
                        </div>
                      </div> */}

                    {plan !== "basic" && (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Key Words"
                            name="keyword"
                            id="keyword"
                            style={{ float: "left", width: "89%" }}
                          />
                          <button
                            type="button"
                            // style={{float:'left'}}
                            className="btn btn-primary"
                            onClick={() => handleClick("keyword")}
                          >
                            <i
                              className="fa fa-plus"
                              style={{}}
                            ></i>
                          </button>
                        </div>
                      </div>
                    )}

                    {!isMSSP ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            onChange={handleLogoChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile01"
                          >
                            Upload logo
                          </label>
                        </div>
                      </div>
                    ) : null}
                    {plan !== "basic" && (
                      <div className="col-lg-6 col-md-12">
                        {keyWord && keyWord.length > 0 && (
                          <div className="row">
                            {keyWord.map((keyword) => {
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{
                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("keyword", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="col-lg-6 col-md-12">
                      {orgLogo && orgLogo != "" && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            <img
                              style={{ width: "100px" }}
                              src={`${IMAGE_URL}${orgLogo}`}
                            />
                            <span
                              className="fa fa-times-circle"
                              style={{
                                position: "absolute",
                                marginTop: "-8px",
                                marginLeft: "-8px",
                                color: "red",
                              }}
                              onClick={() => removeOrgLogo()}
                            ></span>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr />
                  </div>
                  {/* <h6>Services</h6>
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12" id="clientServices">
                        <Autocomplete
                          style={{ width: "100%" }}
                          multiple
                          id="tags-standard"
                          // options={['AttackSurface','DarkWeb','BrandSecurity',
                          // 'TPSA']}
                          options={['AttackSurface','DarkWeb','BrandSecurity'
                          ]}
                          value={services}
                          onChange={(event, newValue) => {
                            setServices(newValue);
                          }}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              // label="Multiple values"
                              placeholder=""
                            />
                          )}
                        />
                      </div>
                    </div> */}
                  <div style={{}}>
                    <h6>License Duration</h6>

                    <div className="row clearfix">

                      <div className="form-group">
                        <div className="input-group">
                          <div className="col-lg-12 col-md-12 clientCalendar">
                            <div className="form-group">
                              <DatePicker
                                selected={expiredAt}
                                minDate={new Date()}
                                // showTimeSelect
                                timeFormat="p"
                                timeIntervals={1}
                                onChange={(date) => selectDate(date)}
                                className="form-control datetimepicker"
                                dateFormat="MMMM d, yyyy h:mm aa"
                              />

                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                  <div style={{}}>
                    <h6>Continuous Monitoring</h6>

                    <div className="row clearfix">
                      <div className=" ">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="col-lg-12 col-md-12">
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "baseline",
                                }}
                              >
                                <label style={{ marginRight: "10px" }}>
                                  Repeat Every
                                </label>
                                <select
                                  className="form-control form-control-sm"
                                  style={{ width: "200px", marginTop: "10px" }}
                                  value={scanType}
                                  onChange={(e) => setscanType(e.target.value)}
                                >
                                  <option value="No">No</option>
                                  <option value="daily">Daily</option>
                                  <option value="weekly">Weekly</option>
                                </select>
                              </div>
                              {scanType === "weekly" && (
                                <div
                                  className="form-group"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <label style={{ marginRight: "10px" }}>
                                    Repeat On
                                  </label>
                                  <select
                                    value={scanScheduleDay}
                                    onChange={(e) =>
                                      setscanScheduleDay(e.target.value)
                                    }
                                    style={{
                                      width: "200px",
                                      marginTop: "10px",
                                    }}
                                    className="form-control form-control-sm"
                                  >
                                    <option value="Sunday">Sunday</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  {/* <div className="header">
                      <h2>Email Server Information</h2>
                      &nbsp;&nbsp;
                      <div className="row clearfix">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={serverType}
                            onChange={(e) => setServerType(e.target.value)}
                          >
                            <option value="">--Server Type --</option>
                            <option value="google">Google</option>
                            <option value="microsoft">Microsoft</option>
                            <option value="other">others</option>
                          </select>
                        </div>
                      </div>

                      {serverType && serverType != "" && (
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email Login Url"
                                value={loginUrl}
                                onChange={(e) => setLoginUrl(e.target.value)}
                              />
                            </div>
                        </div>
                      )}
                      </div>
                    </div> */}
                  <div>
                    <h6>Social Media Information (Optional)</h6>

                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-facebook"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="facebookUrl"
                              // value={facebookUrl}
                              style={{ float: "left", width: "86%" }}
                            // onChange={(e) => setFacebookUrl(e.target.value)}
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("facebook")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{}}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-linkedin"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="liknedinUrl"
                              // value={linkedinUrl}
                              style={{ float: "left", width: "86%" }}
                            // onChange={(e) => setLinkedinUrl(e.target.value)}
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("linkedin")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{}}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {facebookUrl && facebookUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {facebookUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{

                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("facebook", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {linkedinUrl && linkedinUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {linkedinUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{

                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("linkedin", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-twitter"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="twitterUrl"
                              // value={twitterUrl}
                              style={{ float: "left", width: "86%" }}
                            // onChange={(e) => setTwitterUrl(e.target.value)}
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("twitter")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{}}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-instagram"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="instagramUrl"
                              // value={instagramUrl}
                              style={{ float: "left", width: "86%" }}
                            // onChange={(e) =>
                            //   setInstagramUrl(e.target.value)
                            // }
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("instagram")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{}}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {twitterUrl && twitterUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {twitterUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{

                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("twitter", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {instagramUrl && instagramUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {instagramUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{

                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("instagram", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      <hr />
                    </div>
                  </div>
                  {isEdit && (
                    <div className="header">
                      <h2>Vendor Details</h2>
                      &nbsp;&nbsp;
                      <div className="col-lg-6 col-md-12">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile02"
                            onChange={handleFileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile02"
                          >
                            Upload File
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr></hr>
                  <div>
                    <h6>Address information (Optional)</h6>

                    <div className="row clearfix">
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option value="">-- Select Country --</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AX">Ã…land Islands</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO">
                              Bolivia, Plurinational State of
                            </option>
                            <option value="BQ">
                              Bonaire, Sint Eustatius and Saba
                            </option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet Island</option>
                            <option value="BR">Brazil</option>
                            <option value="IO">
                              British Indian Ocean Territory
                            </option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CD">
                              Congo, the Democratic Republic of the
                            </option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">CÃ´te d'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">CuraÃ§ao</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK">
                              Falkland Islands (Malvinas)
                            </option>
                            <option value="FO">Faroe Islands</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GF">French Guiana</option>
                            <option value="PF">French Polynesia</option>
                            <option value="TF">
                              French Southern Territories
                            </option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="HM">
                              Heard Island and McDonald Islands
                            </option>
                            <option value="VA">
                              Holy See (Vatican City State)
                            </option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR">
                              Iran, Islamic Republic of
                            </option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP">
                              Korea, Democratic People's Republic of
                            </option>
                            <option value="KR">Korea, Republic of</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA">
                              Lao People's Democratic Republic
                            </option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK">
                              Macedonia, the former Yugoslav Republic of
                            </option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexico</option>
                            <option value="FM">
                              Micronesia, Federated States of
                            </option>
                            <option value="MD">Moldova, Republic of</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="NC">New Caledonia</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS">
                              Palestinian Territory, Occupied
                            </option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">RÃ©union</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint BarthÃ©lemy</option>
                            <option value="SH">
                              Saint Helena, Ascension and Tristan da Cunha
                            </option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF">
                              Saint Martin (French part)
                            </option>
                            <option value="PM">
                              Saint Pierre and Miquelon
                            </option>
                            <option value="VC">
                              Saint Vincent and the Grenadines
                            </option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SX">
                              Sint Maarten (Dutch part)
                            </option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="GS">
                              South Georgia and the South Sandwich Islands
                            </option>
                            <option value="SS">South Sudan</option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SJ">Svalbard and Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW">
                              Taiwan, Province of China
                            </option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ">
                              Tanzania, United Republic of
                            </option>
                            <option value="TH">Thailand</option>
                            <option value="TL">Timor-Leste</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                            <option value="UM">
                              United States Minor Outlying Islands
                            </option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">
                              Venezuela, Bolivarian Republic of
                            </option>
                            <option value="VN">Viet Nam</option>
                            <option value="VG">Virgin Islands, British</option>
                            <option value="VI">Virgin Islands, U.S.</option>
                            <option value="WF">Wallis and Futuna</option>
                            <option value="EH">Western Sahara</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State/Province"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            rows="4"
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <hr />
                    </div>
                  </div>
                  {/* <div className="header">
                      <h2>Keywords</h2>
                      &nbsp;&nbsp; */}
                  {/* <div className="row clearfix"> */}
                  {/* <div className="col-lg-6 col-md-12"> */}
                  {/* <div className="form-group">
                          <select className="form-control">
                            <option value="">--Domains --</option>
                            <option value="trial">Domain1</option>
                            <option value="one-time">Domain2</option>
                            <option value="gold">Gold</option>
                            <option value="platinum">Platinum</option>
                          </select>
                        </div> */}
                  {/* <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Key Words"
                            name="keyword"
                            id="keyword"
                            style={{float:'left', width:"86%"}}
                          />
                          <button
                            type="button"
                            // style={{float:'left'}}
                            className="btn btn-primary"
                            onClick={() => handleClick("keyword")}
                          >
                            <i className="fa fa-plus" style={{fontSize:"22px"}}></i>
                          </button>
                        </div> */}
                  {/* </div> */}
                  {/* {
                        keyWord && keyWord.length > 0 && (
                          <div className="col-lg-6 col-md-12">
                            <div className="row">
                            {keyWord.map((keyword) => {
                              return(
                                <div className="col-md-4">
                                  <label style={{color:"white",background:"#43a5f5",padding:"4px 6px"}}>
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() => removeKeyword("keyword",keyword)}
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              )})}
                              </div>
                          </div>
                        )
                      } */}
                  {/* <div className="col-lg-12 col-md-12">
                          <div className="card">
                            <div className="body">
                              <Tabs
                                defaultActiveKey="brand"
                                id="uncontrolled-tab-example"
                              >
                                <Tab
                                  eventKey="brand"
                                  title="Brand"
                                  tabClassName="custom_tab"
                                >
                                  <h6>Home</h6>
                                  <p>
                                    Raw denim you probably haven't heard of them
                                    jean shorts Austin. Nesciunt tofu stumptown
                                    aliqua, retro synth master cleanse. Mustache
                                    cliche tempor, williamsburg carles vegan
                                    helvetica. Reprehenderit butcher retro
                                    keffiyeh dreamcatcher synth. Cosby sweater
                                    eu banh mi, qui irure terry richardson ex
                                    squid. Aliquip placeat salvia cillum iphone.
                                    Seitan aliquip quis cardigan american
                                    apparel, butcher voluptate nisi qui.
                                  </p>
                                </Tab>
                                <Tab
                                  eventKey="phishing"
                                  title="Phishing"
                                  tabClassName="custom_tab"
                                >
                                  <h6>Profile</h6>
                                  <p>
                                    Raw denim you probably haven't heard of them
                                    jean shorts Austin. Nesciunt tofu stumptown
                                    aliqua, retro synth master cleanse. Mustache
                                    cliche tempor, williamsburg carles vegan
                                    helvetica. Reprehenderit butcher retro
                                    keffiyeh dreamcatcher synth. Cosby sweater
                                    eu banh mi, qui irure terry richardson ex
                                    squid. Aliquip placeat salvia cillum iphone.
                                    Seitan aliquip quis cardigan american
                                    apparel, butcher voluptate nisi qui.
                                  </p>
                                </Tab>
                                <Tab
                                  eventKey="social"
                                  title="Social"
                                  tabClassName="custom_tab"
                                >
                                  <h6>Contact</h6>
                                  <p>
                                    Raw denim you probably haven't heard of them
                                    jean shorts Austin. Nesciunt tofu stumptown
                                    aliqua, retro synth master cleanse. Mustache
                                    cliche tempor, williamsburg carles vegan
                                    helvetica. Reprehenderit butcher retro
                                    keffiyeh dreamcatcher synth. Cosby sweater
                                    eu banh mi, qui irure terry richardson ex
                                    squid. Aliquip placeat salvia cillum iphone.
                                    Seitan aliquip quis cardigan american
                                    apparel, butcher voluptate nisi qui.
                                  </p>
                                </Tab>
                                <Tab
                                  eventKey="code"
                                  title="Source Code"
                                  tabClassName="custom_tab"
                                >
                                  <h6>Contact</h6>
                                  <p>
                                    Raw denim you probably haven't heard of them
                                    jean shorts Austin. Nesciunt tofu stumptown
                                    aliqua, retro synth master cleanse. Mustache
                                    cliche tempor, williamsburg carles vegan
                                    helvetica. Reprehenderit butcher retro
                                    keffiyeh dreamcatcher synth. Cosby sweater
                                    eu banh mi, qui irure terry richardson ex
                                    squid. Aliquip placeat salvia cillum iphone.
                                    Seitan aliquip quis cardigan american
                                    apparel, butcher voluptate nisi qui.
                                  </p>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </div> */}
                  {/* </div>
                    </div> */}

                </div>
              </div>
            </div>


            <div className="modal-footer">



              <button type="button" className="btn  btn-brder-detail btn-primary ">
                <i class="fa-solid fa-xmark mr-1"></i> Cancel
              </button>

              <button
                type="button"
                style={{ fontWeight: "bold" }}
                className="btn   btn-primary "
                onClick={submitForm}
              >
                <i class="fa-solid fa-floppy-disk mr-1"></i> {isEdit ? "Update" : "Create"}
              </button>



            </div>

          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${refreshModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setRefreshModal(false)}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Refresh Client?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to refresh?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setRefreshModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  refreshCustomer(selectedOrgId);
                  setRefreshModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
