import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getScanDetails } from "../../actions/cloudAccount";
import Pagination from "../Pagination/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ScanResults = () => {
  const { scanId } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [pluginFilter, setPluginFilter] = useState([]);
  const [regionFilter, setRegionFilter] = useState([]);
  const [severityFilter, setSeverityFilter] = useState([]);

  useEffect(() => {
    getScanDetails(scanId, dispatch);
  }, [scanId]);

  const account = useSelector((state) => state?.cloudAccount?.scans);

  let category = [],
    plugin = [],
    region = [],
    severity = [];
  for (let counter = 0; counter < account?.scanResult?.length; counter++) {
    if (category.indexOf(account.scanResult[counter].category) == -1)
      category.push(account.scanResult[counter].category);
    if (plugin.indexOf(account.scanResult[counter].plugin) == -1)
      plugin.push(account.scanResult[counter].plugin);
    if (region.indexOf(account.scanResult[counter].region) == -1)
      region.push(account.scanResult[counter].region);
    if (severity.indexOf(account.scanResult[counter].status) == -1)
      severity.push(account.scanResult[counter].status);
  }

  let filterData = account?.scanResult;
  const FailSeverity = filterData?.filter((val) => val?.status == "FAIL");
  const WarningSeverity = filterData?.filter((val) => val?.status == "WARNING");
  const PassSeverity = filterData?.filter((val) => val?.status == "OK");
  const UnknownSeverity = filterData?.filter((val) => val?.status == "UNKNOWN");

  WarningSeverity?.map((row) => {
    FailSeverity.push(row);
  });
  PassSeverity?.map((row) => {
    FailSeverity.push(row);
  });
  UnknownSeverity?.map((row) => {
    FailSeverity.push(row);
  });
  filterData = FailSeverity;

  if (categoryFilter?.length > 0)
    filterData = filterData?.filter(
      (val) => categoryFilter.indexOf(val?.category) != -1
    );

  if (pluginFilter?.length > 0)
    filterData = filterData?.filter(
      (val) => pluginFilter.indexOf(val?.plugin) != -1
    );

  if (regionFilter?.length > 0)
    filterData = filterData?.filter(
      (val) => regionFilter.indexOf(val?.region) != -1
    );

  if (severityFilter?.length > 0)
    filterData = filterData?.filter(
      (val) => severityFilter.indexOf(val?.status) != -1
    );

  const downloadCsvData = account?.scanResult?.map((res) => ({
    Category: res?.category || "NA",
    Plugin: res?.plugin || "NA",
    Region: res?.region || "NA",
    Severity: res?.status || "NA",
    Information: res?.description || "NA",
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  let currentTableData = [];
  if (filterData && filterData?.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = filterData?.slice(firstPageIndex, lastPageIndex);
  }

  //console.log(categoryFilter, "category");

  const colorStyle = (val) => {
    //console.log("colorStyle");
    if (val == "FAIL") {
      return "#FF435E";
    } else if (val == "WARNING") {
      return "#FF8E50";
    } else if (val == "UNKNOWN") {
      return "#DADADB";
    } else if (val == "OK") {
      return "#20D047";
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Scan Results</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Results
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <Link
                to="/"
                className="btn btn-sm btn-success"
                title="Themeforest"
                onClick={(e) => {
                  e.preventDefault();
                  exportToCSV(downloadCsvData, "ScanResult");
                }}
              >
                <i className="fa fa-excel"></i> Export Results
              </Link>
            </div>
          </div>
        </div>

      
        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="input-group">
                      <Autocomplete
                        style={{ width: "100%" }}
                        multiple
                        id="tags-standard"
                        options={category || []}
                        value={categoryFilter}
                        onChange={(event, newValue) => {
                          setCategoryFilter(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            // label="Multiple values"
                            placeholder="Category"
                          />
                        )}
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Category"
                      /> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="input-group">
                      <Autocomplete
                        multiple
                        style={{ width: "100%" }}
                        id="tags-standard"
                        options={plugin || []}
                        value={pluginFilter}
                        onChange={(event, newValue) => {
                          setPluginFilter(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            // label="Multiple values"
                            placeholder="Plugin"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="input-group">
                      <Autocomplete
                        multiple
                        style={{ width: "100%" }}
                        id="tags-standard"
                        options={region || []}
                        value={regionFilter}
                        onChange={(event, newValue) => {
                          setRegionFilter(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            // label="Multiple values"
                            placeholder="Region"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="input-group">
                      <Autocomplete
                        multiple
                        style={{ width: "100%" }}
                        id="tags-standard"
                        options={severity || []}
                        value={severityFilter}
                        onChange={(event, newValue) => {
                          setSeverityFilter(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            // label="Multiple values"
                            placeholder="Severity"
                          />
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-2 col-md-4 col-sm-6"></div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Plugin</th>
                    <th>Region</th>
                    <th>Severity</th>
                    <th>Information</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentTableData?.map((row, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>

                        <td>{row?.category || "NA"}</td>

                        <td>{row?.plugin || "NA"}</td>
                        <td>
                          <span className="badge badge-warning">
                            {row?.region || "NA"}
                          </span>
                        </td>

                        <td>
                          <span
                            className="badge"
                            style={{
                              borderColor: colorStyle(row?.status),
                              color: colorStyle(row?.status),
                              background: "transparent",
                            }}
                          >
                            {row?.status || "NA"}
                          </span>
                        </td>
                        <td>{row?.description || "NA"}</td>
                        {/* <td>
                          <span className="text-warning">
                            {" "}
                            <button
                              type="button"
                              className="btn btn-sm btn-default"
                              title="Delete"
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              <i className="icon-trash"></i>
                            </button>
                          </span>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="dataTables_paginate">
              {filterData && filterData?.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={filterData?.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScanResults;
