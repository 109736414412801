import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";



const AccessPage = () => {
  const history = useHistory();
  const auth = useSelector(
    (state) => state?.auth?.user
  );
  if(auth?.organisationId?.clientType == 3){
    if(!auth?.organisationId?.canAccessPage){
      history.push("/clients")
    }
  }

  return(
    <></>
  )
}

export default AccessPage;