import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Dropzone from "../common/DropzoneExample";
import { createScan, getScan, startScan } from "../../actions/cloudAccount";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const CloudAccounts = () => {
  const dispatch = useDispatch();
  const [isModal, setModal] = useState("");
  const [scanModal, setScanModal] = useState(false);
  const [scanId, setScanId] = useState("");
  const [connectionMethod, setConnectionMethod] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [projectId, setProjectId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [cloudType, setCloudType] = useState("");
  const [activeMonitoring, setactiveMonitoring] = useState(false);
  const [subscriptionId, setsubscriptionId] = useState("");

  const id = useSelector(
    (state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  );

  const data = useSelector((state) => state?.cloudAccount?.data);
  useEffect(() => {
    getScan(id, dispatch);
  }, []);

  const downloadCsvData = data?.map((res) => ({
    Cloud: "Google",
    Account: res?.name,
    ConnectedOn: moment(res?.createdAt).format("YYYY-MM-DD"),
    LastScanned: res?.processedAt
    ? moment(res?.processedAt).format("YYYY-MM-DD")
    : "Pending"
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  //console.log(data, "jsonData");
  // if(jsonData != ""){
  //   setProjectId(jsonData?.project_id);
  //   setEmail(jsonData?.client_email);
  //   setPrivateKey(jsonData?.private_key);
  // }
  const handleSubmit = () => {
    if (projectId == "") {
      toastr.error("Errpr", "Please enter project id.");
      return false;
    }
    if (name == "") {
      toastr.error("Errpr", "Please enter name.");
      return false;
    }
    if (email == "") {
      toastr.error("Errpr", "Please enter email.");
      return false;
    }
    if (privateKey == "") {
      toastr.error("Errpr", "Please enter private key");
      return false;
    }

    let obj = {};
    obj.name = name;
    obj.cloud = "google";
    obj.config = {
      client_email: email,
      private_key: privateKey,
      project_id: projectId,
      type: "service_account",
    };
    if(activeMonitoring)
    obj.subscriptionId = subscriptionId;
    obj.org_account = true;
    obj.organisationId = id;

    createScan(obj, dispatch);
    setJsonData("");
    setProjectId("");
    setEmail("");
    setPrivateKey("");
    setName("");
    setModal("");
  };

  const startScanFunction = () => {
    startScan(scanId, dispatch);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Cloud Accounts</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Accounts
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <Link onClick={(e) => {
                  e.preventDefault();
                  exportToCSV(downloadCsvData, "CloudAccount");
              }} to="/" className="btn btn-sm btn-primary mr-1" title="">
                Export
              </Link>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-2 col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6"></div>
                  <div className="col-lg-4 col-md-6"></div>

                  <div className="col-lg-2 col-md-6">
                    {/* <a href="/" className="btn btn-sm btn-primary btn-block" title="">Connect Account</a> */}
                    <button
                      type="button"
                      className="btn btn-round btn-success"
                      onClick={() => setModal("Connect Account")}
                    >
                      Connect Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
          <div className="table-responsive">
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>Cloud</th>
                    <th>Account</th>
                    <th>Connected on</th>
                    <th>Last Scanned</th>
                    <th>Scan Now</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((row) => {
                    return (
                      <tr>
                        <td>
                          <Link to={`/account-details/${row?._id}`}>
                            {" "}
                            <i className="fa fa-google"></i>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/account-details/${row?._id}`}>
                            {row?.name}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/account-details/${row?._id}`}>
                            <span className="badge badge-success">
                              {moment(row?.createdAt).format("YYYY-MM-DD")}
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/account-details/${row?._id}`}>
                            <span className="badge badge-warning">
                              {row?.processedAt
                                ? moment(row?.processedAt).format("YYYY-MM-DD")
                                : "Pending"}
                            </span>
                          </Link>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm mr-1"
                            onClick={() => {
                              setScanId(row?._id);
                              setScanModal(true);
                            }}
                          >
                            Start Scan
                          </button>
                        </td>
                        {/* <td>
                          <span className="text-warning">
                            {" "}
                            <button
                              type="button"
                              className="btn btn-sm btn-default"
                              title="Delete"
                              data-toggle="tooltip"
                              data-placement="top"
                            >
                              <i className="icon-trash"></i>
                            </button>
                          </span>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Connect Account" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Connect account
              </h5>
              <button
                type="button"
                className="close"
                data-diInputsmiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              ></button>
              <span aria-hidden="true">×</span>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="header">
                        <h2>Enter Cloud Account Details</h2>
                      </div>
                      <div className="body">
                        <div id="wizard_horizontal">
                          <Tabs
                            defaultActiveKey="First Step"
                            id="uncontrolled-tab-example"
                          >
                            <Tab
                              eventKey="First Step"
                              title="Enter Details"
                              tabClassName="form_wizard_tab form_wizard_width"
                            >
                              <div className="col-lg-8 col-md-12">
                                <div className="form-group">
                                  <select className="form-control" value={cloudType} onChange={(e) => setCloudType(e.target.value)}>
                                    <option value="">
                                      -- Cloud Account Type --
                                    </option>
                                    <option value="google">Google</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-12">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    value={connectionMethod}
                                    onChange={(e) =>
                                      setConnectionMethod(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      -- Connection Method --
                                    </option>
                                    <option value="drag">
                                      Drag &amp; Drop
                                    </option>
                                    <option value="manual">Manual</option>
                                  </select>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Second Step"
                              title="Setup connectivity"
                              tabClassName="form_wizard_tab form_wizard_width"
                              disabled={(cloudType == "" || connectionMethod == "")}
                            >
                              <div className="header">
                                <h2>Setup Connectivity</h2>
                              </div>
                              <ul>
                                <li>
                                  Log into your Google Cloud console and
                                  navigate to IAM Admin &amp; Service Accounts.
                                </li>
                                <li>Click on "Create Service Account".</li>
                                <li>
                                  Enter "BrandSek" in the "Service account
                                  name", then enter "BrandSek API Access" in the
                                  description.
                                </li>
                                <li>Click on Continue.</li>
                                <li>
                                  Select the role: Project and Viewer and click
                                  Continue.
                                </li>
                                <li>Click on "Create Key".</li>
                                <li>
                                  Leave the default JSON selected and click
                                  "Create".
                                </li>
                                <li>Save the provided JSON file</li>
                                <li>
                                  For Organization setup, please select Org
                                  Project and follow the instructions below
                                </li>
                                <li>
                                  Log into your Google Cloud console and
                                  navigate to Cloud Resource Manager
                                </li>
                                <li>Select your organization</li>
                                <li>Click on "Add Member"</li>
                                <li>Enter the "BrandSek" Service Account</li>
                                <li>Select the Viewer Role</li>
                                <li>Click on "Save"</li>
                              </ul>
                              {connectionMethod == "drag" && (
                                <div className="card">
                                  <div className="header"></div>
                                  <div className="body">
                                    <Dropzone
                                      variable={setJsonData}
                                      isUpload={"false"}
                                      setProjectId={setProjectId}
                                      setEmail={setEmail}
                                      setPrivateKey={setPrivateKey}
                                      setName={setName}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={projectId}
                                    onChange={(e) =>
                                      setProjectId(e.target.value)
                                    }
                                    placeholder="* Enter your GCP Project ID"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="* Enter name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="* Enter email"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="comments"
                                    className="form-control"
                                    value={privateKey}
                                    onChange={(e) =>
                                      setPrivateKey(e.target.value)
                                    }
                                    placeholder="* Enter Private Key"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label className="col-sm-3 control-label">Active Monitoring</label>
                                  <label className="switch col-sm-9" style={{top:"6px"}}>
                                      <input type="checkbox" value={activeMonitoring} onChange={(e) => setactiveMonitoring(e.target.checked)}/>
                                      <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              {activeMonitoring && (
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={subscriptionId}
                                      onChange={(e) =>
                                        setsubscriptionId(e.target.value)
                                      }
                                      placeholder="* Enter Subscription Id"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-4 col-md-12">
                                {/* <a
                                  href="/"
                                  className="btn btn-sm btn-primary btn-block"
                                  title=""
                                  onClick={handleSubmit}
                                >
                                  Connect
                                </a> */}
                                <button
                                  type="button"
                                  className="btn btn-round btn-primary mr-1"
                                  onClick={handleSubmit}
                                >
                                  Connect
                                </button>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${scanModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setScanModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Start scan
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Ã—</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to start the scan?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setScanModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  startScanFunction();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CloudAccounts;
