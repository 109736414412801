import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link  , useParams} from "react-router-dom";
import { getSubDomainDetails } from "../../actions/sub-domain";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";

const Domain = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(20);
  const [category, setCategory] = useState("all");

  const [filterData, setfilterData] = useState("Dangling");
  const [search, setSearch] = useState("");

  const [riskScore, setRiskScore] = useState("");
  const [fisrtLoad, setFirstLoad] = useState(true);

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});  let dnsDetailsArray = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.data?.data
  );
  let oldDnsDetails = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.scanDetailArray?.data
  );
  let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

  // //console.log(oldDnsDetails, "result value");

  useEffect(() => {
    getSubDomainDetails(id, dispatch , page ,category);
  }, [id ,page, category]);


  let distinctFilter = ["All", 'Active', 'InActive', 'Dangling'];
  // distinctFilter?.unshift("Last 30 days");
  // distinctFilter?.unshift("Warnings");
  // distinctFilter?.unshift("Issues");
  // distinctFilter?.unshift("all");


  // const compliantData = dnsDetailsArray?.filter((row) => row?.valid);

  // let compliantDataPercentage = compliantData?.length / dnsDetailsArray?.length;

  let openPortArray = [];
  let ipArray = [];
  for (let ip = 0; ip < oldDnsDetails?.scanDetails?.length; ip++) {
    ipArray.push(oldDnsDetails?.scanDetails?.[ip]?.length || 0);
  }
  if (oldDnsDetails?.scanDetails) {
    const compliantDataOld = oldDnsDetails?.scanDetails?.filter(
      (row) => row?.valid
    );
    let compliantDataOldPercentage =
      (compliantDataOld?.length / oldDnsDetails?.scanDetails?.length) * 100;
    // ipArray.push(oldDnsDetails?.scanDetails?.[0]?.length || 0);
    openPortArray.push(compliantDataOldPercentage?.toFixed(0));
  }
  // if (dnsDetailsArray) {
  //   ipArray.push(dnsDetailsArray?.length);
  //   openPortArray.push((compliantDataPercentage * 100).toFixed(0));
  // }

  // //console.log(oldDnsDetails, "dnsDetails",ipArray);

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const downloadCsvData = dnsDetailsArray?.map((row) => ({
    Status: row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") == "NA") ? "Dangling" : ((row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") == "NA") ? "InActive" : "Active",
    DomainName: row?._doc?.subDomainName,
    IPAddress: row?.dnsData?.ip,
    Hosting: row?.dnsData?.isp,
    LastUpdated: moment(row?._doc?.createdAt).format("DD-MM-YYYY"),
  }));

  const active = dnsDetailsArray?.filter(
    (row) =>
    ((row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") != "404" && (row?._doc?.techStack?.urls?.[
      `https://${row?._doc?.subDomainName}/`
    ]?.status || "NA") != "NA")
  );

  const inActive = dnsDetailsArray?.filter(
    (row) =>
      !row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "NA")
  );

  const oldDanglingData = oldDnsDetails?.oldScanData?.filter(
    (row) =>
      row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "NA")
  )

  //console.log(oldDanglingData, 'oldDanglingData',oldDnsDetails?.oldScanData[oldDnsDetails?.oldScanData?.length - 1]);

  const dangling = dnsDetailsArray?.filter(
    (row) =>
      row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
        `https://${row?._doc?.subDomainName}/`
      ]?.status || "NA") == "NA") && row?._doc?.subDomainName.indexOf("www") == -1
  );

  let mapData =
    filterData === "All"
      ? dnsDetailsArray
      : filterData === "Active"
        ? active
        : filterData === "InActive"
          ? inActive
          : filterData == "Dangling"
            ? dangling
            : dnsDetailsArray?.filter((row) => row?.recordType == filterData);


  mapData = mapData?.filter((res) => {
    if (search === "") {
      return res;
    } else if (
      res?._doc?.subDomainName?.toLowerCase()?.indexOf(search.toLowerCase()) != -1
    ) {
      // (res?.records?.map((row) => row.indexOf(search) != -1)) )
      return res;
    }
  });

  // rating system calculation
  let nonCompliantValue = ((dangling?.length) / dnsDetailsArray?.length) * 100;
  let squareRootOfComplaint = Math.sqrt((dnsDetailsArray?.length - dangling?.length)?.toFixed(2));
  let squareRootOfBase = Math.sqrt(dnsDetailsArray?.length?.toFixed(2));

  let riskScoreValue = parseFloat(
    ((squareRootOfComplaint / squareRootOfBase) * 100)?.toFixed(2)
  );

  //console.log(riskScoreValue,'riskScoreValue');

  if (fisrtLoad && !isNaN(riskScoreValue)) {
    // setRiskScoreValue(riskScoreValue);
    if (riskScoreValue == 0) {
      setRiskScore("A1");
    } else if (riskScoreValue < 20) {
      setRiskScore("A2");
    } else if (riskScoreValue < 35) {
      setRiskScore("B1");
    } else if (riskScoreValue < 55) {
      setRiskScore("B2");
    } else if (riskScoreValue < 75) {
      setRiskScore("C1");
    } else {
      setRiskScore("C2");
    }
    setFirstLoad(false);
  }

  // end of rating system calculation

  let currentTableData = [];
  // //console.log(mapData, "dnsDetailsArray");
  if (mapData && mapData.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  // console.log(currentTableData,'currentTabledata')
  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Dangling Subdomains</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
              <p>Grade : {riskScore || "Calculating..."}</p>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <span>
              {downloadCsvData?.length === 0 ? null : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    exportToCSV(downloadCsvData, "Dangling Subdomains");
                  }}
                  href="/add-report"
                  style={{ float: "right" }}
                  className="btn btn-primary mr-1"
                >
                  <i className="fa fa-file-excel-o mr-1"></i> Download Details
                </a>
              )}
              </span>
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-4 col-md-12">
            <div className="card w_card3">
              <div className="body" style={{ minHeight: "460px" }}>
                <div className="row text-center">
                  <div className="col-12 pb-4 pt-4">
                    <label className="mb-0">Records Found</label>
                    <h4 className="font-30 font-weight-bold text-col-blue">
                      {dnsDetailsArray?.length || 0}
                    </h4>
                  </div>
                </div>



                <div className="form-group">
                  <label className="d-block">
                    Dangling Subdomains <span className="float-right">
                      {dangling?.length?.toFixed(0)}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-warning"
                      role="progressbar"
                      // aria-valuenow={}
                      aria-valuemin="10"
                      aria-valuemax="100"
                      style={{}}
                    ></div>
                  </div>
                </div>

                {/* {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )} */}
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="card">
              <div className="body" style={{ minHeight: "460px" }}>
                <div>Previous scan details</div>
                {oldDnsDetails && (
                  <BasicLineColumn
                    dates={oldDnsDetails?.dates}
                    ipArray={ipArray}
                    color={["#6574cd"]}
                    yAxis="Records Found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-12 col-sm-12">
            <div className="card">
              <div className="body">

                <div className="row" ref={myRef}>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      {distinctFilter?.map((row) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-4"></div>
                  <div className="col-sm-4">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>


                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>#No</th>
                      {/* <th>Status</th> */}
                      <th>Status</th>
                      <th style={{ width: "100px" }}>Domain Name</th>
                      {/* <th>Record Type</th> */}
                      <th style={{ width: "200" }}>IP Address</th>
                      <th>Hosting</th>
                      <th>Last modified on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      //console.log(row?._doc?.techStack?.urls?.[
                      //   `https://${row?._doc?.subDomainName}/`
                      // ]?.status, "rowaa",row?._doc);
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: "unset" }}>
                            <span
                              className={
                                row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
                                  `https://${row?._doc?.subDomainName}/`
                                ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
                                  `https://${row?._doc?.subDomainName}/`
                                ]?.status || "NA") == "NA") && row?._doc?.subDomainName.indexOf("www") == -1 ? "badge badge-danger" :
                                  ((row?._doc?.techStack?.urls?.[
                                    `https://${row?._doc?.subDomainName}/`
                                  ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
                                    `https://${row?._doc?.subDomainName}/`
                                  ]?.status || "NA") == "NA") ? "badge badge-warning" : "badge badge-success"
                              }
                            >
                              {row?.dnsData?.ip && ((row?._doc?.techStack?.urls?.[
                                `https://${row?._doc?.subDomainName}/`
                              ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
                                `https://${row?._doc?.subDomainName}/`
                              ]?.status || "NA") == "NA") && row?._doc?.subDomainName.indexOf("www") == -1
                                ? "Dangling"
                                : ((row?._doc?.techStack?.urls?.[
                                  `https://${row?._doc?.subDomainName}/`
                                ]?.status || "NA") == "404" || (row?._doc?.techStack?.urls?.[
                                  `https://${row?._doc?.subDomainName}/`
                                ]?.status || "NA") == "NA") ? "InActive" : "Active"}
                            </span>
                          </td>
                          {/* <td>
                            {row?._doc?.techStack?.urls?.[
                                  `https://${row?._doc?.subDomainName}/`
                                ]?.status || "NA"}
                          </td> */}

                          <td style={{ whiteSpace: "unset" }}>
                            <a href={`https://${row?._doc?.subDomainName}`} target="_blank">
                              {row?._doc?.subDomainName}
                            </a>
                          </td>
                          {/* <td>{row?.recordType}</td> */}
                          <td style={{ whiteSpace: "unset" }}>
                            <a href={`http://${row?.dnsData?.ip}`} target="_blank">
                              {row?.dnsData?.ip}
                            </a>
                          </td>
                          <td style={{ whiteSpace: "unset" }}>
                            {row?.dnsData?.isp}
                          </td>
                          <td style={{ whiteSpace: "unset" }}>
                            <span className="badge badge-success">
                              {moment(row?._doc?.updatedAt || row?._doc?.createdAt).format("DD-MM-YYYY")}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domain;
