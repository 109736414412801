import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  ALERTS_FETCHED,
  CREATE_INTEGRATION,
  FETCHING_ALERTS,
  FETCH_INTEGRATION,
} from "./index";
import { toastr } from "react-redux-toastr";

export const createIntegration = (data, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: FETCH_INTEGRATION,
  //   payload: null,
  // });
  axios
    .post(`${API_URL}/createIntegration` ,data)
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error) toastr.error("Error", response.data.userMessage);
      else {
        toastr.success("Success", response.data.userMessage);
      }
      // if (!response.data.error){
      //   dispatch({
      //     type: FETCH_INTEGRATION,
      //     payload: response.data.data,
      //   });
      // }
      getIntegration(data?.organisationId,dispatch)
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCH_INTEGRATION,
        payload: { error: true, userMessage: message },
      });
    });
};

export const createIntegrationjira = (data,dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: FETCH_INTEGRATION,
  //   payload: null,
  // });
  axios
    .post(`${API_URL}/createIntegration`, data )
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error){
        toastr.error("Error", response.data.userMessage);
      }
      else 
      {
        toastr.success("Success", response.data.userMessage);
      }
      getIntegration(data?.organisationId , dispatch)
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCH_INTEGRATION,
        payload: { error: true, userMessage: message },
      });
    });
};

export const getIntegration = (props, dispatch) => {
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: FETCH_INTEGRATION,
      payload: null,
    });
    axios
      .get(`${API_URL}/getAllIntegration/${props}`, {})
      .then((response) => {
        dispatch({
          type: FETCH_INTEGRATION,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_INTEGRATION,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const updateIntegrationById = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.patch(`${API_URL}/createIntegration`, { ...props }).then((response) => {
    if (!response.data.error) {
      // dispatch({
      //   type: FETCH_INTEGRATION,
      //   payload: response.data.data,
      // });
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
    getIntegration(props,dispatch)
  });
};

export const updateIntegrationByJira = (data, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.patch(`${API_URL}/createIntegration`, data).then((response) => {
    if (!response.data.error) {
      // dispatch({
      //   type: FETCH_INTEGRATION,
      //   payload: response.data.data,
      // });
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
    getIntegration(data?.organisationId,dispatch)
  });
};

export const deleteIntegration = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: FETCH_INTEGRATION,
  //   payload: null,
  // });
  axios
    .delete(`${API_URL}/integration/${props}`, {})
    .then((response) => {
      toastr.success("Success", response.data.userMessage);
      dispatch({
        type: FETCH_INTEGRATION,
        payload: response.data.data,
      });
      
      getIntegration(props,dispatch)
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCH_INTEGRATION,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchAlerts = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_ALERTS,
    payload: null,
  });
  axios.get(`${API_URL}/security/alerts?organisationId=${props.organisationId}`, { }).then((response) => {
    if (!response.data.error) {
      dispatch({
        type: ALERTS_FETCHED,
        payload: response.data.data,
      });
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};

export const fetchAlertDetails = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_ALERTS,
    payload: null,
  });
  axios.get(`${API_URL}/security/alerts?alertId=${props.alertId}`, { }).then((response) => {
    if (!response.data.error) {
      dispatch({
        type: ALERTS_FETCHED,
        payload: response.data.data,
      });
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};



export const fetchProjectList = (props)=>{
  return new Promise((resolve, reject)=>{
    axios.get(`${API_URL}/jira/projects`, { params: props}).then((response) => {
      if (!response.data.error) {
        resolve(response.data);
      } else {
        toastr.error("Error", response.data.userMessage);
        reject();
      }
    }).catch(err=>{
      toastr.error("Error", err);
      reject();
    })
  })
 
}

export const fetchJiraIssueTypes = (props)=>{
  return new Promise((resolve, reject)=>{
    axios.get(`${API_URL}/jira/issues`, { params: props}).then((response) => {
      if (!response.data.error) {
        resolve(response.data);
      } else {
        toastr.error("Error", response.data.userMessage);
        reject();
      }
    }).catch(err=>{
      toastr.error("Error", err);
      reject();
    })
  })
 
}