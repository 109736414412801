import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class FinancialChart extends Component {
    constructor(props) {
        super(props);
        let highRisk = [],medRisk = [], lowRisk = [],label=[];
        // console.log(props,'props')
        Object?.keys(props.value || {})?.map((key) => {
            let value = props.value[key];
            if (value?.length > 0) {
                label.push(key)
            }else return false;
        });
        Object?.keys(props.value || {})?.map((key) => {
            let value = props.value[key];
            if (value?.length > 0) {
                let high = 0, med = 0, low = 0;
                for (let i = 0; i < value?.length / 2; i++) {
                    if (parseInt(value[i].rating.breach_detection_score / 2) > 80) {
                        high = high + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                    } else if (parseInt(value[i].rating.breach_detection_score / 2) < 80 && (parseInt(value[i].rating.breach_detection_score / 2) > 40)) {
                        med = med + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                    } else {
                        low = low + parseInt((value[i].rating.breach_detection_score || 0) / 2)
                    }
                }
                highRisk.push(high);
                medRisk.push(med);
                lowRisk.push(low);
            }
        })
        // console.log(highRisk,medRisk,lowRisk,'highRisk')
        this.state = {
          
            series: [{
              name: 'High Risk (>80)',
              data: highRisk
            }, {
              name: 'Med Risk (<80 & >40)',
              data: medRisk
            }, {
              name: 'Low Risk (<40)',
              data: lowRisk
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
            //   title: {
            //     text: 'Fiction Books Sales'
            //   },
              xaxis: {
                categories: label,
                // labels: {
                //   formatter: function (val) {
                //     return val + "K"
                //   }
                // }
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                // y: {
                //   formatter: function (val) {
                //     return val + "K"
                //   }
                // }
              },
              colors: ['#FF5A65', '#3C89DA', '#05C168'],
              fill: {
                opacity: 1,
                colors: ['#FF5A65', '#3C89DA', '#05C168']
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            },
          
          
          };
        
    }
    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
        );
    }
}

export default FinancialChart;
