import $ from "jquery";
import {
    API_URL
} from "../../utils/constant";
import {
    toastr
} from "react-redux-toastr";
import axios from "axios";


var Actions = {
    getAuthMethod: email => {
        return new Promise((resolve, reject) => {
            // const cookie = new Cookies();
            // const session = cookie.get("session");
            const session = localStorage.getItem("session");
            axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
            axios
                .post(`${API_URL}/auth/method`, { email: email })
                .then((response) => {
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response)
                    }
                })
                .catch((err) => {
                    var message = "Some server error has occurred.";
                    if (err.response) {
                        message = err.response.data.userMessage;
                        toastr.error("Error", message);
                    }
                });
        });
    },
    encryptEmail: email => {
        return new Promise((resolve, reject) => {
            // const cookie = new Cookies();
            // const session = cookie.get("session");
            const session = localStorage.getItem("session");
            axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
            axios
                .get(`${API_URL}/encryptEmail?email=${email}`)
                .then((response) => {
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response)
                    }
                })
                .catch((err) => {
                    var message = "Some server error has occurred.";
                    if (err.response) {
                        message = err.response.data.userMessage;
                        toastr.error("Error", message);
                    }
                });
        });
    },
    updateProfile: (props) => {
        if (props) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_URL}/updateProfile`, { ...props })
                    .then((response) => {
                        if (response.data.error) {
                            reject(response);
                            // toastr.error("Error", response.data.userMessage);
                        } else {
                            resolve(response);
                            // toastr.success("Success", response.data.otpMessage);
                        }
                    })
                    .catch((err) => {
                        var message = "Some server error has occurred.";
                        if (err.response) {
                            message = err.response.data.userMessage;
                        }
                        toastr.error("Error", message);
                    });
            })
        }
    },
    emailVerify: (props) => {
        if (props) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${API_URL}/emailUpdate`, { ...props })
                    .then((response) => {
                        if (response.data.error) {
                            resolve(response);
                            // toastr.error("Error", response.data.userMessage);
                        } else {
                            resolve(response);
                            // toastr.success("Success", response.data.otpMessage);
                        }
                    })
                    .catch((err) => {
                        var message = "Some server error has occurred.";
                        if (err.response) {
                            message = err.response.data.userMessage;
                        }
                        toastr.error("Error", message);
                    });
            })
        }
    }
}

export default Actions;