import React from "react";


class Logout extends React.Component {
    componentDidMount(){
        //console.log("Logout")
        localStorage.clear();
        window.location.replace("/login");
    }
    render(){
        return(
            <div></div>
        )
    }

}

export default Logout;