import $ from "jquery";
import {
    API_URL
} from "../../utils/constant";
import {
    toastr
} from "react-redux-toastr";
import axios from "axios";

var Actions = {
    generateReport: val => {
        return new Promise((resolve, reject) => {
            // const cookie = new Cookies();
            // const session = cookie.get("session");
            const session = localStorage.getItem("session");
            axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
            axios
                .get(`${API_URL}/downloadSummaryReport/${val.id}?reportType=${val.asm}`)
                .then((response) => {
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response)
                    }
                })
                .catch((err) => {
                var message = "Some server error has occurred.";
                    if (err.response) {
                        message = err.response.data.userMessage;
                        toastr.error("Error", message);
                    }
                });
        });
    },

    executiveReport: val => {
        return new Promise((resolve, reject) => {
            // const cookie = new Cookies();
            // const session = cookie.get("session");
            const session = localStorage.getItem("session");
            axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
            axios
                .get(`${API_URL}/downloadPptReport/${val}`)
                .then((response) => {
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response)
                    }
                })
                .catch((err) => {
                var message = "Some server error has occurred.";
                    if (err.response) {
                        message = err.response.data.userMessage;
                        toastr.error("Error", message);
                    }
                });
        });
    }
}

export default Actions;