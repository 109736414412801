import React, { Component } from "react";
import brandsek from "../../assets/images/brandsek-logo.png";
import { toastr } from "react-redux-toastr";
import $ from "jquery";

var Model = require("./model");
class EmailVerfiy extends Component {
  state = {
    email: ''
  };

  componentDidMount() {
    if (!this?.props?.match?.params?.email) {
      window.location.replace("/login");
    } else {
      this.setState({
        email: atob(this.props.match.params.email)
      })
    }
  }

  emailVerfiy = (event) => {
    event.preventDefault();
    let email = $("#email").val();
    Model.default.emailVerify({
      email: this.state.email,
      updatedemail: email
    }).then((response) => {
      // console.log(response, 'updateProfile')
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        $("#email").val("");
        toastr.success("Success", response.data.userMessage);

      }
    });
  }

  render() {
    return (
      <>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>

        <div className="page-wrapper page-wrapper_login">
          <div className="section bg-default wf-section">
            <div className="container-default position-relative w-container">
              <div className="inner-container _625px center">
                <div
                  id="w-node-_111d0742-1c68-c3f8-2732-86d768991d13-42a43325"
                  data-w-id="111d0742-1c68-c3f8-2732-86d768991d13"
                  class="card _24px add-to-cart-card email_verfiy"
                  style={{ border: "1px solid #2f2f2f" }}
                >
                  <div class="contact-form-block w-form contact-form-block-new" style={{ minHeight: "auto" }}>
                    <form
                      id="wf-form-Contact-Form"
                      name="wf-form-Contact-Form"
                      data-name="Contact Form"
                      method="get"
                    >
                      <div class="inner-container center _100---tablet">
                        <div class="inner-container _600px---tablet">
                          <h2 class="display-4 mg-bottom-12px email-text-verfiy">
                            <span class="color-accent-1">Update</span> email
                            address
                          </h2>

                          <input
                            type="email"
                            className="input input-form top-right w-input"
                            name="Email"
                            data-name="Email"
                            placeholder="you@company-domain.com"
                            id="email"
                          ></input>
                        </div>
                        <div class="add-cart---main" style={{ minHeight: "auto" }}>
                          <form
                            data-node-type="commerce-add-to-cart-form"
                            class="w-commerce-commerceaddtocartform mg-bottom-0"
                          >
                            <div class="mg-bottom-32px">
                            </div>
                            <div class="buttons-row add-to-cart-btns">
                              <input
                                type="submit"
                                value="Get started"
                                style={{ color: "black" }}
                                onClick={this.emailVerfiy}
                                class="w-commerce-commerceaddtocartbutton btn-primary width-100 add-to-cart"
                              ></input>
                            </div>
                          </form>
                        </div>
                      </div>
                    </form>
                    <div class="success-message contact-form w-form-done">
                      <div class="success-contact-form">
                        <div class="line-rounded-icon success-message-check large">
                          î “
                        </div>
                        <div>
                          Your message has been submitted. <br /> We will get
                          back to you within{" "}
                          <span class="text-no-wrap">24-48 hours.</span>
                        </div>
                      </div>
                    </div>
                    <div class="error-message w-form-fail">
                      <div>Oops! Something went wrong.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EmailVerfiy;
