import {
  INVENTORY_FETCHED,
  SINGLE_INVENTORY_FETCHED,
  INVENTORY_FETCHED_GRAPH,
  INVENTORY_FETCHED_TOTALS,
  FETCHING_SINGLE_INVENTORY,
  ALL_INVENTORY_FETCHED,
  SINGLE_INVENTORY_DATA_FETCHED,
  INVENTORY_FETCHED_DISCOVERY,
  LEAKED_SESSION_FETCHED_GRAPH,
  INVENTORY_FETCHED_DISCOVERY_TOTAL,
  INVENTORY_FETCHED_DISCOVERY_GRAPH,
  ALL_PORT,
  ALL_COUNTRY,
  ALL_CLOUD
} from "../actions/index";

export default function (
  state = {
    loading: true,
    response: null,
    list: null,
    brand:null,
    singleInventory: null,
    allInventory: [],
    singleInventoryData: null,
    scanCompleted: true,
    iptotals:[],
    ipgraph:[],
    ipdiscovery:[],
    assetstotal:[],
    assetsgraph:[],
    allport:[],
    allcountry:[],
    allcloud:[]
  },
  action
) {
  switch (action.type) {
    case ALL_CLOUD:{
      return {
        ...state,
        allcloud:action.payload,
      }
    }
    case ALL_COUNTRY:
      return{
        ...state,
        allcountry:action.payload,
      }
    case ALL_PORT:
      return{
        ...state,
        allport:action.payload,
      }
    case INVENTORY_FETCHED_DISCOVERY_GRAPH:
      return {
        ...state,
        assetsgraph:action.payload,
      } 
    case INVENTORY_FETCHED_DISCOVERY_TOTAL:
      return {
        ...state,
        assetstotal:action.payload,
      } 
    case INVENTORY_FETCHED_DISCOVERY:
      return {
        ...state,
        ipdiscovery:action.payload,
      }
    case INVENTORY_FETCHED_GRAPH:
      return {
        ...state,
        ipgraph:action.payload
      };
      case INVENTORY_FETCHED_TOTALS:
        return {
          ...state,
          iptotals:action.payload
        };
    case INVENTORY_FETCHED:
      return {
        ...state,
        response: action.payload,
        brand:action.payload?.data || [],
        list: action?.payload?.data || [],
        loading: false,
        scanCompleted: action?.payload?.scanCompleted,
      };
    case FETCHING_SINGLE_INVENTORY:
      return {
        ...state,
        singleInventory: null,
        loading: true,
      };
    case SINGLE_INVENTORY_FETCHED:
      return {
        ...state,
        singleInventory: action.singleInventory,
        loading: false,
      };
    case ALL_INVENTORY_FETCHED:
      return {
        ...state,
        allInventory: action.payload,
        loading: false,
      };
    case SINGLE_INVENTORY_DATA_FETCHED:
      return {
        ...state,
        singleInventoryData: action.singleInventory,
        loading: false,
      };
    default:
      return state;
  }
}
