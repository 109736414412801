import { FETCHING_USERS , USERS_CREATED ,CREATE_ENTERPRISE } from "../actions";

const initialState = {
  loading: true,
  response: null,
  rescreated:null,
  list: null,
  data: null,
  fetchedData: {},
  formSubmit:null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_USERS:
        return {
            ...state,
            response: action.payload,
            fetchedData: null,
            user: null,
            loading: true,
          };
          case USERS_CREATED:
            return {
              ...state,
              rescreated: action.payload,
              loading: true,
            };
            case CREATE_ENTERPRISE:
            return {
              ...state,
              formSubmit: action.payload,
              loading: true,
            };
    default:
      return state;
  }
}
