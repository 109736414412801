import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

class Splinechart extends Component {
    constructor(props) {
        super(props);
        console.log(props, "propsseee")
        let largest = 0;
        for (let i = 0; i < props?.value.length; i++) {
            if (parseFloat(props?.value[i]) > largest) {
                largest = parseFloat(props?.value[i]);
            }
        }
        console.log(largest, 'largest')
        this.state = {

            series: [{
                name: "Attack Surface",
                // data: [212, 182, 254, 284, 291, 380]
                data: props?.value?.map((val) => val)
            },
            {
                name: 'Data Exposure',
                // data: [172, 172, 154, 174, 191, 227]
                data: props?.data?.map((row) => row)
            },
            {
                name: 'Brand Security',
                // data: [52, 22, 64, 14, 51, 87]
                data: props?.brand?.map((row) => row)
            }
            ],
            options: {
                chart: {
                    height: 285,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: [props?.color, 'red', 'yellow'],
                markers: {
                    size: 3,
                    strokeColor: [props?.color, 'red', 'yellow'],
                },
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                xaxis: {
                    type: 'category',
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                    categories: props?.date?.map((val) => val)
                },
                yaxis: {
                    tickAmount: 8,
                    min: 0,
                    max: parseInt(largest) + 50
                },
                legend: {
                    show: false
                }
                // tooltip: {
                //     x: {
                //         format: 'dd/MM/yy HH:mm'
                //     },
                // },
            },
        };
    }
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height="285px"
                />
            </div>
        );
    }
}

export default Splinechart;
