import {
  FETCH_SUBDOMAIN_DETAIL,
  SUBDOMAIN_FETCHED_GRAPH,
  SUBDOMAIN_FETCHED,
  SUBDOMAIN_FETCHED_SSL,
  SUBDOMAIN_SCAN_STARTED,
  SCAN_LIST,
  FETCH_DNS_DETAILS,
  FETCH_SUCCESS_DNS_DETAILS,
  SUBDOMAIN_FETCHED_TOTAL,
  SECURITY_HEADER,
  FETCH_SUCCESS_DNS_DETAILS_TOTAL,
  FETCH_SUCCESS_DNS_DETAILS_GRAPH,
  SUBDOMAIN_FETCHED_SSL_TOTAL,
  SUBDOMAIN_FETCHED_SSL_GRAPH,
  FETCH_VULNERABLE_SSL,
  FETCH_VULNERABLE_SSL_TOTAL,
  FETCH_VULNERABLE_SSL_GRAPH
} from "../actions";

const initialState = {
  loading: true,
  response: { lookalike: [] },
  responseSsl: { lookalike: [] },
  list: null,
  subDomainDetail: {},
  subDomainDetailTotal: {},
  subDomainSslTotal: {},
  subDomainDetailGraph: {},
  expiredGraph: {},
  securityHeader: [],
  scanList: [],
  dnsDetails: [],
  subdomainDnsDetails: [],
  garphData: [],
  totalCount:[],
  vulnerableCertificate:[],
  vulnerableCertificatetotal:[],
  vulnerableGraph:[],
  scanCompleted: true,
  subDomainScanCompleted: true,
};

const subDomain = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VULNERABLE_SSL_GRAPH:
      return {
        ...state,
        vulnerableGraph: action.payload,
        loading: false,
      };
    case FETCH_VULNERABLE_SSL:
      return {
        ...state,
        vulnerableCertificate: action.payload,
        loading: false,
      };
      case FETCH_VULNERABLE_SSL_TOTAL:
      return {
        ...state,
        vulnerableCertificatetotal: action.payload,
        loading: false,
      };
    case SUBDOMAIN_FETCHED_SSL:
      return {
        ...state,
        responseSsl: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        subDomainScanCompleted: action.payload.scanCompleted,
      };
    case SUBDOMAIN_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        subDomainScanCompleted: action.payload.scanCompleted,
      };
    case SUBDOMAIN_FETCHED_TOTAL:
      return {
        ...state,
        totalCount: action
      };
    case FETCH_SUBDOMAIN_DETAIL:
      return {
        ...state,
        subDomainDetail: action.subDomainDetail,
      };
      case FETCH_SUCCESS_DNS_DETAILS_GRAPH:
        return {
          ...state,
          subDomainDetailGraph: action.payload,
        };
        case SUBDOMAIN_FETCHED_SSL_GRAPH:
          return {
            ...state,
            expiredGraph: action.payload,
          };
    case  FETCH_SUCCESS_DNS_DETAILS_TOTAL:
      return {
        ...state,
        subDomainDetailTotal: action.payload,
      };
      case SUBDOMAIN_FETCHED_SSL_TOTAL:
        return{
          ...state,
          subDomainSslTotal: action.payload,
        }
    case SUBDOMAIN_SCAN_STARTED:
      return {
        ...state,
        loading: false,
        scanStarted: true,
      };
    case SUBDOMAIN_FETCHED_GRAPH:
      return {
        ...state,
        garphData: action,
      };
    case SCAN_LIST:
      return {
        ...state,
        scanList: action.payload,
        loading: false,
      };
    case FETCH_DNS_DETAILS:
      return {
        ...state,
        dnsDetails: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case FETCH_SUCCESS_DNS_DETAILS:
      return {
        ...state,
        subdomainDnsDetails: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case SECURITY_HEADER:
      return {
        ...state,
        securityHeader: action.payload.data,
      };
    default:
      return state;
  }
};

export default subDomain;
