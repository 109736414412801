import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

class BasicLineColumn extends Component {
    constructor(props) {
        super(props);
        // console.log(props, 'basic line top',props?.ipArray?.map((row) => row))
        // let largest = props?.ipArray.sort((a,b)=>a-b)[props?.ipArray.length - 1];
        // //console.log(props, 'basic line',largest)
        this.state = {
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                colors: props?.color,
                stroke: {
                    width: [0, 4]
                },

                dataLabels: {
                    enabled: false
                },
                // tooltip: {
                //     y: {
                //         formatter: function (value, { dataPointIndex, w }) {
                //             return w.config.series[0].data[dataPointIndex]
                //             //or you can judt add 'lac' to value
                //             //return `${value} lac`
                //         }
                //     }
                // },
                yaxis: {
                    min: 0,
                    // max: largest + 10,
                    tickAmount: 4,
                    title: {
                        text: props?.yAxis || 'Total Scan',
                    },
                },

                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        columnWidth: "10%"
                    },
                },
                // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                // labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                labels: props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                xaxis: {
                    // type: 'datetime'
                },
                // yaxis: [{
                //     title: {
                //         text: props?.yAxis || 'Total Scan',
                //     },
                // }]
            },
            series: [{
                name: props?.yAxis || 'Total Scan',
                type: 'column',
                data: props?.ipArray
                // data: props?.ipArray?.map((row) => toString(row || 0)),
                // data: [10]
            }]
        };
    }
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height="350px"
                />
            </div>
        );
    }
}

export default BasicLineColumn;
