import React from "react";

const message = () => {
    return(
        <div className="body text-success">
            <p className="card-text">
                BrandSek is  working in background to present you the awesomeness. We will notify you once the scan is complete.
            </p>
        </div>
    )
}

export default message;