import React, { useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import DynamicInput from "../Projects/DynamicInput";
import { EditDes } from "../../actions/cveInformation";
import { useDispatch } from "react-redux";


const Edit = ({ cveId, model, _id, field, value, fieldType, onEditCancel }) => {
  const { cveId: cveID } = useParams();
  // const navigate = useHistory();
  const dispatch = useDispatch();
  const [editedValue, setEditedValue] = useState(value);


  const saveEdits = () => {
    EditDes( dispatch ,  cveId ,_id, editedValue ,field)
  };

  return (
    <div className="h-full w-full absolute flex items-center justify-center backdrop-blur-md z-10">
      <div className="flex block-header flex-col gap-2 w-1/2 p-4 bg-black-800 rounded-md modalCustom">
        <h1>
          Edit {field}
        </h1>
        <DynamicInput
          type={fieldType}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          className="p-2 h-32 rounded-md focus:outline-none"
        />

        <div className="flex flex-row gap-3 justify-end mt-3">
          <button
            onClick={onEditCancel}
            className="btn btn-round btn-default pull-right"
          >
            Cancel
          </button>
          <button
           onClick={(e) => {
            saveEdits();
          }}
            className="btn btn-round btn-primary mr-1 pull-right"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
