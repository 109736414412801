import {
  SCHEDULER_FETCHED,
  SCHEDULER_SUBDOMAIN_FETCHED
} from "../actions/";

const initialState = {
  loading: true,
  response: null,
  list: null,
  data: null,
  scheduler: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULER_SUBDOMAIN_FETCHED:
      return {
        ...state,
        schedulerSubDomain: action.payload,
        loading: true
      };
    case SCHEDULER_FETCHED:
      return {
        ...state,
        scheduler: action.payload,
        loading: true,
      };

    default:
      return state;
  }
}
