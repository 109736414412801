import { INVENTORY_DETAILS_FETCHED,INVENTORY_DETAILS_FETCHEDV1 } from "../actions";

const initialState = {
  data: {},
  inventoryDetails:[]
};

const inventoryDetails = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_DETAILS_FETCHED:
      return {
        ...state,
        data: action.data,
      };
    case INVENTORY_DETAILS_FETCHEDV1:
      return {
        ...state,
        inventoryDetails: action.data,
      };
    default:
      return state;
  }
};

export default inventoryDetails;
