import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
import { DARK_WEB} from "./index";
import { toastr } from 'react-redux-toastr';




export const fetchDarkWebResult = (props, dispatch , page , category) => {
    if(props){
      axios
        .get(`${API_URL}/darkwebForum/${props}?page=${page}&&limit=10&category=${category}`, {})
        .then((res) => {
            //console.log(res,'pastebin response')
          dispatch({
            type: DARK_WEB,
            payload: res.data,
          });
        });
    }
};

export const fetchDarkWebAction = (props, dispatch, page , data,category) => {
  if(props){
    axios
      .post(`${API_URL}/darkwebForum/${props}/action?page=${page}&category=${category}`, data)
      .then((res) => {
          //console.log(res,'pastebin response')
        dispatch({
          type: DARK_WEB,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

