import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMasterScan,
  getMasterScanData,
} from "../../actions/vulnerability";
import { Link  , useParams} from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import BasicLineColumn from "../common/apexChart/basicLineColumn";
import { Donut } from "react-dial-knob";
import GaugeChart from "react-gauge-chart";
import AccessPage from "../Shared/accessPage";

const KnownVulnerability = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [scanId, setScanId] = useState("");

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const id = useSelector(
    (state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  );
  const [filterData, setfilterData] = useState("all");
  let table = useSelector((state) => state.vulnerability?.cveDetails?.data);
  //console.log("App_VA", table);
  const masterScan = useSelector(
    (state) => state.vulnerability?.masterScan?.data
  );
  const masterArray = useSelector(
    (state) => state.vulnerability?.masterScanData?.data
  );

  const scanDetailArray = useSelector(
    (state) => state.vulnerability?.masterScanData?.scanDetailArray
  );

  useEffect(() => {
    // fetchCVEDetails(id, dispatch);
    fetchMasterScan(id, "Subdomain", dispatch);
  }, [id]);

  if (firstLoad && masterScan?.length > 0) {
    setFirstLoad(false);
    setScanId(masterScan[0]._id);
  }

  const criticalRecord = masterArray?.filter(
    (row) => row?.vulnerabilities?.severity >= 9
  );
  const highRecord = masterArray?.filter(
    (row) =>
      row?.vulnerabilities?.severity >= 7 && row?.vulnerabilities?.severity < 9
  );
  const mediumRecord = masterArray?.filter(
    (row) =>
      row?.vulnerabilities?.severity >= 4 && row?.vulnerabilities?.severity < 7
  );
  const lowRecord = masterArray?.filter(
    (row) => row?.vulnerabilities?.severity < 4
  );

  useEffect(() => {
    getMasterScanData(scanId, id, dispatch);
  }, [scanId]);

  let currentTableData = [];

  let mapData =
    filterData === "all"
      ? masterArray
      : filterData === "critical"
      ? criticalRecord
      : filterData === "high"
      ? highRecord
      : filterData === "medium"
      ? mediumRecord
      : filterData === "low"
      ? lowRecord
      : null;

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  let ipArray = [],
    compliantArray = [],
    dates = scanDetailArray?.masterScan?.map((row) => row.createdAt);
  let newObj = [];
  Object.keys(scanDetailArray?.masterScanResults || {}).map((key) => {
    let obj = {},
      domainObj = {};
    // obj.id = key;
    let record = scanDetailArray?.masterScanResults[key];
    record?.map((row) => {
      if (domainObj[row.subDomain]) {
        domainObj[row.subDomain].push(row);
      } else {
        domainObj[row.subDomain] = [row];
      }
    });
    obj[key] = domainObj;
    newObj.push(obj);
  });

  for (let count = 0; count < newObj?.length; count++) {
    // //console.log(newObj[count],'newObj[count]')
    let ip = 0,
      compliant = 0;
    Object.keys(newObj[count]).map((row) => {
      // //console.log(newObj[count][row],'rowwwwww upper')
      Object.keys(newObj[count][row]).map((record) => {
        ip++;
        // //console.log(record,'rowwwwww')
        for (
          let inner = 0;
          inner < newObj[count][row][record].length;
          inner++
        ) {
          if (typeof newObj[count][row][record][inner] == "object") {
            // //console.log(newObj[count][row][record][inner],'rowwwwww',row)
            if (
              newObj[count][row][record][inner]?.vulnerabilities?.severity > 0
            ) {
              //console.log(
              //   newObj[count][row][record][inner],
              //   "newObj[count][row][record][inner]"
              // );
              compliant++;
              break;
            }
          }
        }
      });
    });
    ipArray.push(ip);
    let compPercent = ((ip - compliant) / ip) * 100;
    compliantArray.push(compPercent.toFixed(0));
  }

  const severity = (val) => {
    //console.log(val, "severity val");
    if (val < 4) {
      return "Low";
    }
    if (val >= 4 && val < 7) {
      return "Medium";
    }
    if (val >= 7 && val < 9) {
      return "High";
    }
    if (val >= 9) {
      return "Critical";
    }
  };

  const severityBatch = (val) => {
    if (val < 4) {
      return "badge badge-low";
    }
    if (val >= 4 && val < 7) {
      return "badge badge-med";
    }
    if (val >= 7 && val < 9) {
      return "badge badge-high";
    }
    if (val >= 9) {
      return "badge badge-critical";
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Application Vulnerabilities</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <select
                className="form-control"
                value={scanId}
                onChange={(e) => setScanId(e.target.value)}
              >
                {masterScan?.map((row) => {
                  return <option value={row?._id}>{row?.scanName}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-8"></div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="vulerabilityHeader">
                <div style={{ color: "#b0f127" }}>Vulnerabilities</div>
              </div>
              <div className="column">
                <div className="card w_card3">
                  <div className="vulerabilityHeader top_counter VulnerabilitiesBox">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={2}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#DC2B2B",
                                  centerColor: "#141414",
                                  bgrColor: "#DC2B2B",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              {/* <h4 className="mb-0">34.80%</h4> */}
                              <h4 className="mb-0">Critical</h4>
                              <span>Vulnerabilities</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={3}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#EE4B2B",
                                  centerColor: "#141414",
                                  bgrColor: "#EE4B2B",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">High</h4>
                              <span>Vulnerabilities</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={10}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#FF8C00",
                                  centerColor: "#141414",
                                  bgrColor: "#FF8C00",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">Medium</h4>
                              <span>Vulnerabilities</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={24}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#48B461",
                                  centerColor: "#141414",
                                  bgrColor: "#48B461",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">Low</h4>
                              <span>Vulnerabilities</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="vulerabilityHeader">
                {/* <i className="fa  fa-laptop"></i> */}
                <div style={{ color: "#b0f127" }}>Assets</div>
              </div>
              <div className="column">
                <div className="card w_card3">
                  <div className="vulerabilityHeader top_counter VulnerabilitiesBox">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={5}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#DC2B2B",
                                  centerColor: "#DC2B2B",
                                  bgrColor: "#DC2B2B",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">Critical</h4>
                              <span>Risk Assets</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={7}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#EE4B2B",
                                  centerColor: "#EE4B2B",
                                  bgrColor: "#EE4B2B",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">High</h4>
                              <span>Risk Assets</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={10}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#FF8C00",
                                  centerColor: "#141414",
                                  bgrColor: "#383b40",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">Medium</h4>
                              <span>Risk Assets</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card w_card3">
                          <div
                            className="body w_summary"
                            style={{
                              backgroundColor: "#23252A",
                            }}
                          >
                            <div className="c_know mt-1 mr-3">
                              <Donut
                                className="knob"
                                diameter={45}
                                min={0}
                                max={100}
                                step={1}
                                value={12}
                                theme={{
                                  donutThickness: 2,
                                  donutColor: "#48B461",
                                  centerColor: "#141414",
                                  bgrColor: "#48B461",
                                }}
                              />
                            </div>
                            <div className="s_detail">
                              <h4 className="mb-0">Low</h4>
                              <span>Risk Assets</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="card">
              <div className="body">
                <div style={{ color: "#b0f127" }}>Compliance</div>
                <div id="apex-circle-gradient">
                  {!isNaN(.1) && (
                    // <CircleGradient percent={compliantDataPercentage} />
                    <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={30}
                      colors={["#008ffb", "#00e396"]}
                      arcWidth={0.3}
                      percent={0.3}
                    />
                  )}
                </div>
                {/* <h3 className="mb-0 mt-3 font300">
                  12 of 32{" "}
                  <span className="text-red font-15">
                    <br />
                    machines are non compliant.
                  </span>
                </h3> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-4 col-md-4">
            <div className="card">
              <div className="body" style={{ minHeight: "410px" }}>
                <div className="header">
                  <h2>Top Found Vulnerabilities</h2>
                </div>
                <small className="text-muted">10 Issues</small>
                <p>SQL Injection</p>
                <small className="text-muted">9 Issues</small>
                <p>Open SSH Access</p>
                <small className="text-muted">Weak Password </small>
                <p>5 Issues</p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Previous scan details</h2>
                </div>
                {/* <BasicLineColumn /> */}
                {ipArray && ipArray?.length > 0 && (
                  <BasicLineColumn
                    dates={dates}
                    openPortArray={compliantArray}
                    ipArray={ipArray}
                    color={["#008ffb", "#00e396"]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="header">
                <h2>Vulnerability Details</h2>
                <div className="row" style={{ marginTop: "20px" }}>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="critical">Critical</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </select>
                  </div>
                  <div className="col-sm-4"></div>
                  <div className="col-sm-4">
                    {/* {downloadCsvData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          exportToCSV(downloadCsvData, "KnownVulnerability");
                        }}
                        style={{ float: "right" }}
                        href="/add-report"
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className="table-responsive invoice_list mb-4">
                    <table className="table table-hover table-custom spacing8">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>IP Address</th>
                          {/* <th style={{ width: "150px" }}>Name</th> */}

                          <th style={{ width: "140px" }}>CVE Name</th>
                          <th style={{ width: "140px" }}>Severity</th>
                          <th style={{ width: "140px" }}>Ageing</th>
                          <th style={{ width: "200" }}>Description</th>
                          {/* <th style={{ width: "500px" }}>Solutions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData?.map((row) => {
                          return (
                            <tr>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.subDomain}
                              </td>
                              {/* <td style={{ whiteSpace: "unset" }}> SQL Injection</td> */}

                              <td style={{ whiteSpace: "unset" }}>
                                {row?.cve}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <span
                                  className={severityBatch(
                                    row?.vulnerabilities?.severity
                                  )}
                                >
                                  {severity(row?.vulnerabilities?.severity)}
                                </span>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {/* {moment().diff(
                                  moment(row?.results["last-modified"]),
                                  "days"
                                )}{" "} */}
                                days
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.attribute?.info?.plugindescription
                                  ?.pluginattributes?.description
                                  ? row?.attribute?.info?.plugindescription
                                      ?.pluginattributes?.description
                                  : "NA"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="dataTables_paginate">
                    {mapData && mapData.length > 0 && (
                      <Pagination
                        className="pagination-bar"
                        currentPage={page}
                        totalCount={mapData?.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setPage(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnownVulnerability;
