function DynamicInput({ type, value, onChange, className }) {
    switch (type) {
      case "text":
        return (
          <textarea value={value} onChange={onChange} className={className} />
        );
  
      case "date":
        return (
          <input
            type="date"
            value={value}
            onChange={onChange}
            className={className}
          />
        );
  
      default:
        break;
    }
  }
  
  export default DynamicInput;
  