import { USER_LOGGED_IN, USER_LOGGED_OUT, DASHBOARD_FETCHED, PASSWORD_RESET_REQUESTED,PASSWORD_RESET_DONE, TWO_FACTOR_ENABLED } from "../actions/";

export default function(state = { loading: true, response: null }, action) {
  //console.log(action,'type')
  switch (action.type) {
    case USER_LOGGED_IN:
      return { user: action.payload};
    case TWO_FACTOR_ENABLED:
      return {twoFactor: action.payload};
    case USER_LOGGED_OUT:
      return { user: null, loading: false };
    case PASSWORD_RESET_REQUESTED:
      return { response: action.payload, loading: false };
    case DASHBOARD_FETCHED:
      return {...state,response: action.payload, loading: false};
    case PASSWORD_RESET_DONE:
      return { user: action.payload, loading: false };
    default:
      return state;
  }
}
