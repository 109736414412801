import React, { Component } from 'react';
import C3Chart from 'react-c3js';
// import ReactApexChart from "react-apexcharts";

class DonutChart extends Component {
    constructor(props) {
        super(props);
        //console.log(props,'donut chart')
        this.state = {
            data:{
                columns: [
                    // each columns data
                    ['data1', ( props.total - (props.issues + props.warnings))],
                    ['data2', props.issues],
                    ['data3', props.warnings]
                ],
                type: 'donut', // default type of chart
                colors: {
                    'data1': '#D5691B',
                    'data2': '#ffd861',
                    'data3': '#ffd396'
                },
                names: {
                    // name of each serie
                    'data1': 'No Issues',
                    'data2': 'Issues',
                    'data3': 'Warnings'
                }
        }
        }
    }
    render() {
        
        return (
            <C3Chart
                data={this.state.data}
                height="268px"
                axis={{}}
                padding={{
                    bottom: 20,
                    top: 0
                }}
                legend={
                    { show: false }
                } />
        );
    }
}

export default DonutChart;
