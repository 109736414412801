import React, { useEffect, useState } from "react";
import { axios } from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { cveMonitoringSearch, cveMonitoringTech, cveMonitoringTechDelete, cveMonitoringTechSubsUpdate, cveMonitoringSubscribed, cveMonitoringTechSubscribeUnSubscribe, cveMonitoringTechSubs } from "../../actions/cveInformation";
import Pagination from "../Pagination/Pagination";
import NoData from "../common/noDataComponetnt";
import moment from "moment";
import { DatePicker } from '@mui/lab';
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import 'react-datepicker/dist/react-datepicker.css';
import { capitalize } from "@material-ui/core";


const animatedComponents = makeAnimated();
// import { colourOptions } from './docs/data';
const CveMonitoring = () => {
    const dispatch = useDispatch();
    const [tech_slug, setTech_slug] = useState("");
    const [pageAlerts, setPageAlerts] = useState(1);
    const [version, setVersion] = useState('');
    const [editId, setEditId] = useState('');
    const [cpe, setCpe] = useState('');
    const [cveInfo, setCveInfo] = useState('');
    const [subDomain, setSubDomain] = useState('');
    const [isSubscribed, setIsSubscribed] = useState('');
    const [needCvesInfoUpdate, setNeedCvesInfoUpdate] = useState(1);
    const [totalItemsalerts, setTotalItemsAlerts] = useState(0);
    const [pageTech, setPageTech] = useState(1);
    const [totalItemsTech, setTotalItemsTech] = useState(0);
    const [PageSize, setPageSize] = useState(10);
    const [tableFilter, setTableFilter] = useState("tech");
    const [search, setSearch] = useState("");
    const [searchTech, setSearchTech] = useState("");
    const [filterData, setfilterData] = useState(true);
    const vendorName = sessionStorage.getItem('vendorName');
    const vendorId = sessionStorage.getItem('vendorId');
    const [isModal, setModal] = useState("");
    const id = useSelector(state => {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    });

    const client = useSelector(
        (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
    );

    const handleChange = (v) => {
        if (v) {
            setTimeout(() => {
                cveMonitoringSearch(dispatch, v);
            }, 1000);
        }
    }



    const cveRecord = useSelector((state) => state?.cveInformation?.techmonitoring?.data);
    const subRecord = useSelector((state) => state?.cveInformation?.techsubsmonitoring?.data);
    const dataSelectBox = useSelector((state) => state?.cveInformation?.searchmonitoring?.data?.products);
    const selectOptions = dataSelectBox?.map(item => ({
        value: item.productName,
        label: item.productName,
        color: '#FF8B00',
        cpe: item.cpe,
        tech_slug: item.productName,
        isSubscribed: true
    }));

    let currentTableCveRecord = cveRecord?.cveRecords;
    let currentTableSubRecord = subRecord?.data;

    const handleButtonClick = (v, _id) => {
        $.confirm({
            title: "Are you sure?",
            content: `Do you really want to ${v} this ?`,
            type: `${v == 'unsubscribe' ? 'red' : 'green'}`,
            buttons: {
                ok: {
                    text: `${capitalize(v)}`,
                    btnClass: `${v == 'unsubscribe' ? 'btn-danger' : 'btn-success'}`,
                    keys: ["enter"],
                    action: function () {
                        let data = {
                            "action": v,
                            "techCveSubsIds": [_id]
                        }
                        cveMonitoringTechSubscribeUnSubscribe(dispatch, id, data)
                    },
                },
                cancel: function () {
                    //console.log("the user clicked cancel");
                },
            },
        });
    }

    let arraySelected;
    const handleChangeSelected = (selectedOptions) => {
        arraySelected = selectedOptions
    };

    const subscribe = () => {
        if (arraySelected == '') {
            toastr.error('error', 'Please Select the value')
        } else {
            let techs = arraySelected?.map(obj => {
                return {
                    "cpe": obj.cpe,
                    "tech_slug": obj.tech_slug,
                    "isSubscribed": obj.isSubscribed
                };
            });
            let data = {
                "organisationId": id,
                techs
            }
            cveMonitoringSubscribed(data)
        }
    }

    useEffect(() => {
        if (id) {
            cveMonitoringTech(dispatch, id, pageAlerts, filterData, search , searchTech)
        }
    }, [id, pageAlerts, filterData, search , searchTech])

    useEffect(() => {
        if (id) {
            cveMonitoringTechSubs(dispatch, id, pageTech)
        }
    }, [id, pageTech])

    useEffect(() => {
        setTotalItemsTech(subRecord?.totalIps)
    }, [subRecord?.totalIps])

    useEffect(() => {
        setTotalItemsAlerts(cveRecord?.totalRecords)
    }, [cveRecord?.totalRecords])
    const modalOpen = (res) => {
        setModal('update Tech');
        setCpe(res?.cpe);
        setCveInfo(res?.cveInfo);
        setIsSubscribed(res?.isSubscribed);
        setNeedCvesInfoUpdate(res?.needCvesInfoUpdate);
        setTech_slug(res?.tech_slug);
        setSubDomain(res?.subDomain)
        setVersion(res?.version)
        setEditId(res?._id)
    }

    const deleteBtn = (id) =>{
        $.confirm({
            title: "Are you sure?",
            content: `Do you really want to delete this ?`,
            type: `red`,
            buttons: {
                ok: {
                    text: `Delete`,
                    btnClass: `btn-danger`,
                    keys: ["enter"],
                    action: function () {
                        cveMonitoringTechDelete(dispatch, id)
                    },
                },
                cancel: function () {
                    //console.log("the user clicked cancel");
                },
            },
        });
    }

    const handleSubmit = () => {
        let data = {
            "tech_slug": tech_slug,
            "isSubscribed": isSubscribed,
            "version": version,
            "cpe": cpe,
            "cveInfo": cveInfo,
            "subDomain": subDomain,
            "needCvesInfoUpdate": needCvesInfoUpdate
        }
        cveMonitoringTechSubsUpdate(dispatch, editId, data , id)
        setModal('')
    }


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    return (
        <div className="container-fluid">
            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <h2 style={{  }}>Cve Monitoring</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    {vendorId ? <>
                                        <Link to="/vendor">Forest View</Link>
                                    </> : <Link to="/">Home</Link>}
                                </li>
                                {
                                    vendorName ?
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                            style={{ }}
                                        >
                                            {vendorName}
                                        </li> : ''
                                }
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    style={{  }}
                                >
                                    cve-monitoring
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row justify-content-start align-items-center">
                <div className="col-md-8">
                    <div className="w-100" style={{ position: 'relative' }}>
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            onInputChange={(inputValue) => {
                                handleChange(inputValue)
                            }}
                            onChange={handleChangeSelected}
                            options={selectOptions}
                            className="form-conrtol"
                        />
                    </div>
                </div>
                <div className="col-lg-2 col-md-6">
                    <button
                        type="button"
                        onClick={(() => {
                            subscribe()
                        })}
                        style={{  fontWeight: "bold" }}
                        className="btn  btn-primary"
                    >
                       <i class="fa-regular fa-envelope mr-1"></i> Subscribe
                    </button>
                </div>
            </div>
            <ul className="nav nav-tabs mt-3" id="myTabs" role="tablist">
                <li className="nav-item">
                    <a
                        className={`nav-table-tab nav-link ${tableFilter === "tech" ? 'active' : ''}`}
                        onClick={() => {
                            setTableFilter('tech')
                        }}
                    >
                        Technology
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link nav-table-tab ${tableFilter === "alerts" ? 'active' : ''}`}
                        onClick={() => {
                            setTableFilter('alerts')
                        }}
                    >
                        Alerts
                    </a>
                </li>
            </ul>
            {tableFilter == 'alerts' ? <>
                <div className="card mb-2 mt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-4">
                            <div
                                data-test="datatable-input"
                                class="mdb-datatable-filter flex-row"
                            >
                                <input
                                    class="form-control form-control-sm ml-0 my-1"
                                    type="number"
                                    placeholder="MinCvssScore"
                                    aria-label="Search"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value); setPageAlerts(1); }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div
                                data-test="datatable-input"
                                class="mdb-datatable-filter flex-row"
                            >
                                <input
                                    class="form-control form-control-sm ml-0 my-1"
                                    type="text"
                                    placeholder="Tech Search"
                                    aria-label="Search"
                                    value={searchTech}
                                    onChange={(e) => { setSearchTech(e.target.value); setPageAlerts(1); }}
                                />
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className="input-group">
                                <DatePicker
                                    views={['year', 'month', 'day']}
                                    label="Select dates"
                                    value={[startDate, endDate]}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <input {...params} />}
                                    inputFormat="MM/dd/yyyy"
                                />
                            </div>
                        </div> */}
                        <div className="col-sm-3">
                            <button
                                type="button"
                                style={{ fontWeight: "bold" }}
                                className={`btn  ${filterData == true ? 'btn-primary' : 'btn-brder-detail'}`}
                                onClick={(() => {
                                    setfilterData(filterData == true ? false : true);
                                    setPageAlerts(1);
                                })}
                            >
                                <i class="fa-solid fa-skull mr-1"></i> {filterData == true ? 'Has Known Exploits True' : 'Has Known Exploits False'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-custom spacing5 mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Cve Id</th>
                                <th style={{ width: "300" }}>Cve Description</th>
                                <th>Technology name</th>
                                <th>Has Known Exploits</th>
                                <th>Published At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {client?.subscriptionType == "trial" ? (
                                <tr>
                                    <td colSpan={8}>
                                        <NoData />
                                    </td>
                                </tr>
                            ) : currentTableCveRecord?.map((res, index) => {
                                const pageCount = (pageAlerts - 1) * 10 + 1;
                                return (
                                    <tr>
                                        <td>
                                            <span>{pageCount + index}</span>
                                        </td>
                                        <td>{res?.cveId}</td>
                                        <td style={{ whiteSpace: "unset" }}>{res?.cveDescription}</td>
                                        <td>{res?.tech_slug}</td>
                                        <td>{res?.hasKnownExploits == true ? 'True' : 'False'}</td>
                                        <td> {res?.publishedAt
                                            ? moment(res?.publishedAt).format(
                                                "DD/MM/YYYY hh:mm a"
                                            )
                                            : "NA"}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div><div className="dataTables_paginate">
                    {totalItemsalerts > 0 && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={pageAlerts}
                            totalCount={totalItemsalerts}
                            pageSize={PageSize}
                            onPageChange={(page) => setPageAlerts(page)} />
                    )}
                </div></> : null}
            {tableFilter == 'tech' ? <>
                <div className="table-responsive">
                    <table className="table table-custom spacing5 mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tech Name</th>
                                <th>Version</th>
                                <th>Subscribe/Unsubscribed</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {client?.subscriptionType == "trial" ? (
                                <tr>
                                    <td colSpan={8}>
                                        <NoData />
                                    </td>
                                </tr>
                            ) : currentTableSubRecord?.map((res, index) => {
                                const pageCount = (pageTech - 1) * 10 + 1;
                                return (
                                    <tr>
                                        <td>
                                            <span>{pageCount + index}</span>
                                        </td>
                                        <td>{res?.tech_slug}</td>
                                        <td>{res?.version}</td>
                                        <td>
                                            {res?.isSubscribed == true ? (
                                                <button className="btn btn-danger" onClick={() => handleButtonClick('unsubscribe', res?._id)}>
                                                    unsubscribe
                                                </button>
                                            ) : (
                                                <button className="btn btn-success" onClick={() => handleButtonClick('subscribe', res?._id)}>
                                                    subscribe
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                style={{ backgroundColor: "#b0f127", fontWeight: "bold", color: "#000" }}
                                                className="btn btn-sm btn-success"
                                                onClick={() => modalOpen(res)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                type="button"
                                                style={{ fontWeight: "bold", color: "#fff" }}
                                                className="btn btn-sm btn-danger ml-2"
                                                onClick={() => deleteBtn(res?._id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div><div className="dataTables_paginate">
                    {totalItemsTech > 0 && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={pageTech}
                            totalCount={totalItemsTech}
                            pageSize={PageSize}
                            onPageChange={(page) => setPageTech(page)} />
                    )}
                </div></> : null}
            <div
                className={`modal fade ${isModal === "update Tech" ? "d-block show" : ""
                    }`}
            // onClick={() => setModal("")}
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">
                                Update Technology
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setModal("")}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card">
                                        <div className="body">
                                            <div id="wizard_horizontal">
                                                <div className="row clearfix">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Tech Slug"
                                                                value={tech_slug}
                                                                onChange={(e) =>
                                                                    setTech_slug(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="version"
                                                                value={version}
                                                                onChange={(e) =>
                                                                    setVersion(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="cpe"
                                                                value={cpe}
                                                                onChange={(e) =>
                                                                    setCpe(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Cve Info"
                                                                value={cveInfo}
                                                                onChange={(e) =>
                                                                    setCveInfo(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Sub Domain"
                                                                value={subDomain}
                                                                onChange={(e) =>
                                                                    setSubDomain(e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 ml-10">
                                                        <div className="form-group">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={needCvesInfoUpdate}
                                                                onChange={(e) => setNeedCvesInfoUpdate(e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Need Cves Info Update</label>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 ml-10">
                                                        <div className="form-group">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={isSubscribed}
                                                                onChange={(e) => setIsSubscribed(e.target.checked)}
                                                            />
                                                            <label className="form-check-label">Is Subscribed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-round btn-default"
                                                        data-dismiss="modal"
                                                        onClick={() => setModal("")}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-round btn-primary"
                                                        onClick={handleSubmit}
                                                    >
                                                        Update changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CveMonitoring;
