function EditButton(props) {
    return (
      <button
        {...props}
        className="btn ml-auto flex-shrink-0 btn-primary"
      />
    );
  }
  
  export default EditButton;
  