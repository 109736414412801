import {SEARCH_CVE ,CVE_DATA, CVE_SEARCH_MONITORING , CVE_MONITORING_TECH , CVE_MONITORING_TECH_SUBS } from "../actions";

export default function (
  state = { loading: true, response: null,searchmonitoring:[], techmonitoring:[], techsubsmonitoring:[], },
  action
) {
  switch (action.type) {
    case CVE_MONITORING_TECH_SUBS:
      return{
        ...state,
        techsubsmonitoring: action.payload,
        loading: false,
      }
    case CVE_SEARCH_MONITORING:
      return{
        ...state,
        searchmonitoring: action.payload,
        loading: false,
      }
      case CVE_MONITORING_TECH:
        return {
          ...state,
          techmonitoring: action.payload,
          loading: false,
        };
    case SEARCH_CVE:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };
      case CVE_DATA:
        return {
          ...state,
          cveDetails: action.payload,
          loading: false,
        };
    default:
      return state;
  }
}
