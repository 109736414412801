import React, { useEffect, useState } from "react";
import { Link  , useParams} from "react-router-dom";
import { Donut } from "react-dial-knob";
import { useSelector, useDispatch } from "react-redux";
import FinancialChart from "../common/dashboard6/financialChart";
import PredictorChart from "../common/dashboard6/breachPredictorChart";
import ApexChart from "../common/dashboard6/pieChart";
import Pagination from "../Pagination/Pagination";
import {
  fetchCustomersNew
} from "../../actions/customer";
const Dashboard6 = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomersNew(dispatch);
  }, []);

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [totalBreachScore, setTotalBreachScore] = useState(0);
  const [totalPredictorScore, setTotalPredictorScore] = useState(0);
  const [_obj, setObj] = useState({
    "Government": [],
    "NGO": [],
    "Finance": [],
    "Technology": [],
    "Healthcare": [],
    "Education": [],
    "Telecommunications": [],
    "Manufacturing": [],
    "Construction": [],
    "Energy": [],
    "Chemical": [],
    "Media": [],
    "Aerospace": [],
    "Transportation": [],
    "Agriculture": [],
    "Retail": []
  })


  let customers = useSelector((state) => state?.customer?.response?.data);

  customers = customers?.filter((data) => data.clientType != 3)
  let totalRating = customers?.filter((row) => row.ratingCalculated);

  let _totalBreachScore = 0, _totalPredictorScore = 0, _totalCount = 0;
  if (loading && customers?.length > 0) {
    setLoading(false);
    totalRating?.forEach((row) => {
      if (row?.industryType) {
        let tempObj = {}
        tempObj.name = row.orgName;
        tempObj.rating = row.currentRating;
        let _tempVal = _obj;
        _tempVal[row?.industryType].push(tempObj)
        setObj(_tempVal);
      }

      if (row?.currentRating?.breach_detection_score) {
        _totalCount++;
        _totalBreachScore = parseInt(_totalBreachScore) + parseInt(row?.currentRating?.breach_detection_score)
      }
      if (row?.currentRating?.breach_predeiction_score) {
        // console.log(row?.currentRating?.breach_predeiction_score,'row?.currentRating?.breach_predeiction_score')
        _totalPredictorScore = parseInt(_totalPredictorScore) + parseInt(row?.currentRating?.breach_predeiction_score)
      }
    })

    setTotalCount(_totalCount);
    setTotalBreachScore(_totalBreachScore);
    setTotalPredictorScore(_totalPredictorScore)
    
  }
  // console.log(typeof _obj,"totalRating",_obj)



  let mapData =  search == ""
    ? customers
    : customers?.filter(
        (row) =>
          row?.orgName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1 ||
          row?.orgContactName
            ?.toLowerCase()
            ?.indexOf(search?.toLowerCase()) != -1 ||
          row?.orgEmail?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
      );;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  // console.log(currentTableData, 'currentTableData')

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Breach Summary</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">BrandSek</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Breach Summary
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <a href="/" className="btn btn-sm btn-primary mr-1" title="">Create Campaign</a>
                            <a href="https://themeforest.net/item/BrandSek-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507" className="btn btn-sm btn-success" title="Themeforest"><i className="icon-basket"></i> Buy Now</a>
                        </div> */}
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-indigo text-white rounded-circle">
                    <i className="fa fa-unlock-alt"></i>
                  </div>
                  <div className="ml-4">
                    <span>Breach Detector Score</span>
                    <h4 className="mb-0 font-weight-medium">{(totalBreachScore / totalCount)?.toFixed(0)}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-azura text-white rounded-circle">
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="ml-4">
                    <span>Breach Predictor Score</span>
                    <h4 className="mb-0 font-weight-medium">{(totalPredictorScore / totalCount)?.toFixed(0)}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-orange text-white rounded-circle">
                    <i className="fa fa-thumbs-down"></i>
                  </div>
                  <div className="ml-4">
                    <span>Worst BP Sector</span>
                    <h4 className="mb-0 font-weight-medium">Finance</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-pink text-white rounded-circle">
                    <i className="fa fa-thumbs-down"></i>
                  </div>
                  <div className="ml-4">
                    <span>Worst BD Sector</span>
                    <h4 className="mb-0 font-weight-medium">Healthcare</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Breach Detector</h2>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-8 col-md-12">
                    {!loading && (
                      <FinancialChart value={_obj} key="breach"/>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="mt-4 text-center">
                      {!loading && (
                        <ApexChart value={_obj} type="breach"/>
                      )}
                      {/* <Donut
                        className="knob"
                        diameter={147}
                        min={0}
                        max={100}
                        step={1}
                        value={36}
                        theme={{
                          donutThickness: 5,
                          donutColor: "#9367B4",
                          centerColor: "#141414",
                          bgrColor: "#383b40",
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Breach Predictor</h2>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-8 col-md-12">
                  {!loading && (
                    <PredictorChart value={_obj} key="breach"/>
                  )}
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="mt-4 text-center">
                    {!loading && (
                        <ApexChart value={_obj} type="predictor"/>
                      )}
                      {/* <Donut
                        className="knob"
                        diameter={147}
                        min={0}
                        max={100}
                        step={1}
                        value={36}
                        theme={{
                          donutThickness: 5,
                          donutColor: "#9367B4",
                          centerColor: "#141414",
                          bgrColor: "#383b40",
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="header">
                <h2 style={{width:'200px',float:"left"}}>Details</h2>
                <div className="input-group" style={{float:"right",width:'300px',marginBottom:"10px"}}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Client Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
              </div>

              <div className="table-responsive">
                <table className="table table-custom spacing5">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Sector</th>
                      <th>Region</th>
                      <th className="w100">BP Score</th>
                      <th>BD Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((item, index) => {
                      let rating = item?.rating?.filter((row) => row?.scanCounter == item?.scanCounter)?.pop()
                      return (
                        <tr>
                          <td>{item.orgName}</td>
                          <td>{item.industryType || "NA"}</td>
                          <td>{item.country || "NA"}</td>
                          <td>{parseInt(rating?.breach_detection_score/2 || 0)?.toFixed(0)}</td>
                          <td>{parseInt(rating?.breach_predeiction_score/2 || 0)?.toFixed(0)}</td>
                        </tr>
                      )
                    })
                    }
                    {/* <tr>
                      <td>DBS Bank Singapore</td>
                      <td>Banking</td>
                      <td>South East Asia</td>

                      <td>
                        {" "}
                        <span className="text-green">8</span>
                      </td>
                      <td>
                        <span className="text-red">25</span>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard6;
