import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { forgotPassword } from "../../actions/auth";
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import brandsek from "../../assets/images/invinsense_oxdr_logo.png";
class ForgotPassword extends Component {
  resetPassword = (e) => {
    e.preventDefault();
    let email = $("#signup-password").val();
    if (email == "") {
      toastr.error("Error", "Please enter email");
      return false;
    }

    this.props.forgotPassword({
      email,
    });
  };

  render() {
    let brandsek = '../../assets/images/image-gallery/invinsense_oxdr_logo.png';
    let browser = window.location.href.split("/")
    if (browser[2].split(".")[0] && browser[2].split(".")[0] == "brandshield") {
      brandsek = '../../assets/images/image-gallery/invinsense_oxdr_logo.png'
    } else if (browser[2].split(".")[0] && browser[2].split(".")[0] == "invinsense") {
      brandsek = '../../assets/images/image-gallery/invinsense_oxdr_logo.png?v=1'
    }
    return (
      <>
        {/* <div className="pattern">
          <span className="red"></span>
          <span className="indigo"></span>
          <span className="blue"></span>
          <span className="green"></span>
          <span className="orange"></span>
        </div> */}
        <div className="page-wrapper page-wrapper_login">
          <div className="section bg-default wf-section w-34">
            <div className="container-default position-relative w-container">
              <div className="inner-container _625px center">
                <div
                  data-w-id="af797e45-9721-3e02-9bca-78d7b063fc49"
                  className="card _24px sign-in-card"
                >
                  <div className="sign-form w-form">
                    <div
                      data-w-id="742728b9-6335-1041-77e8-4ea5e5785154"
                      data-animation="default"
                      data-collapse="medium"
                      data-duration="400"
                      data-easing="ease"
                      data-easing2="ease"
                      role="banner"
                      className="header-wrapper w-nav"
                      style={{ paddingTop: "0px" }}
                    >
                      <div className="container-default w-container">
                        <div className="header-content-wrapper">
                          <div
                            data-w-id="742728b9-6335-1041-77e8-4ea5e5785157"
                            className="header-middle"
                          >
                            <a className="header-logo-link center w-nav-brand text-center">
                              <img
                                src={brandsek}
                                style={{ maxWidth: "200px" }}
                                loading="eager"
                                className="header-logo"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form id="wf-form-Sign-In-Form" className="form-auth-small m-t-20">
                      <div className="sign-card-content">
                        <h4 className="text-center keep" style={{  }}>
                          Forgot Password.
                        </h4>
                        <div className="keep">
                          <div className="w-layout-grid grid-1-column gap-row-0">
                            <div className="position-relative">
                              <input
                                type="email"
                                className="input input-form w-input"
                                id="signup-password"
                                name="Email"
                                data-name="Email"
                                placeholder="Email  address"
                              ></input>
                              <span className="icon-user icon-login-page"></span>
                            </div>
                          </div>
                        </div>
                        <div className="keep">
                          {/* <div className="text-200 medium color-neutral-100">
                            <a href="../contact.html">Forgot password?</a>
                          </div> */}
                        </div>
                        <input
                          type="submit"
                          onClick={this.resetPassword}
                          value="Reset"
                          data-wait="Please wait..."
                          id="w-node-f1bc498d-290c-3270-f633-c82f5b0ae8f3-42a43387"
                          className="btn-primary width-100 w-button"
                          style={{ color: "black" }}
                        ></input>
                      </div>
                      <div className="divider _24px"></div>
                      <div className="sign-card-content">
                        <div className="text-center">
                          <div className="text-200 medium">
                            Know your password? 
                            <Link to="/login"> Login</Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="powered-by">
              <p className="text-centered">Powerd by</p>
              <img
                className="powered-logo"
                src="../../assets/images/image-gallery/invinsense-black.png"
                alt="invinsence Logo"
              />
            </div>
          </div><div className="bg-login-right"></div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      forgotPassword,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
