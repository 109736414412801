import axios from "axios";
import { API_URL } from "../utils/constant";
import { toastr } from "react-redux-toastr";
import { FETCHING_USERS , USERS_CREATED ,CREATE_ENTERPRISE} from "./index";
// import useRazorpay from "react-razorpay";
import $ from 'jquery';
import 'jquery-confirm';

export const getPlan = (dispatch) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: FETCHING_USERS,
      payload: null,
    });
    axios
      .get(`${API_URL}/getPlan`, {})
      .then((response) => {
        dispatch({
          type: FETCHING_USERS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCHING_USERS,
          payload: { error: true, userMessage: message },
        });
      });
};

export const createOrder = (data,dispatch) => {
  //  const [Razorpay] = useRazorpay();
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/createOrder`, {
      planid:data?.planid,
      currency:data?.currency
    })
    .then((response) => {
      dispatch({
        type: USERS_CREATED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: USERS_CREATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const CreateEnterPrise = (data,dispatch) => {
  //  const [Razorpay] = useRazorpay();
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/enterprise-inquiry`, {
      name: data.Name,
      email: data.Email,
      contact: data.CountryCode + ' ' + data.Phone,
      comments:data.Message
    })
    .then((response) => {
      if (response.data.error == false) {
        $.alert({
          title: 'Message Received',
          content: response.data.userMessage,
          type: 'green'
        });
      } else if (response.data.error === true) {
        $.alert({
          title: 'Error',
          content: response.data.userMessage,
          type: 'red'
        });
      }
      dispatch({
        type: CREATE_ENTERPRISE,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: CREATE_ENTERPRISE,
        payload: { error: true, userMessage: message },
      });
    });
};

export const payment = (data,dispatch) => {
  axios
.post(
  `${API_URL}/create-checkout-session`,
  data,
  {
    headers: { "Content-Type": "application/json" },
  }
)
.then((response) => {
  dispatch({
    type: USERS_CREATED,
    payload: response.data,
  });
})
.catch((err) => {
  var message = "Some server error has occurred.";
  if (err.response) {
    message = err.response.data.userMessage;
  }
  dispatch({
    type: USERS_CREATED,
    payload: { error: true, userMessage: message },
  });
});
}

