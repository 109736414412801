import {
  CREATING_CUSTOMER,
  CUSTOMERS_FETCHED,
  CUSTOMER_CREATED,
  CUSTOMER_DELETED,
  CUSTOMER_UPDATED,
  DELETING_CUSTOMER,
  EDIT_CUSTOMER_FETCHED,
  FETCHING_CUSTOMERS,
  UPDATING_CUSTOMER,
  FETCHING_THREAT_ANALYIST,
  FETCHING_VENDORS,
  VENDORS_FETCHED,
  GET_TRIAL_REQUEST,
  CREATING_FOREST_VIEW,
  GET_FOREST_VIEW,
  FOREST_GRAPH_ATTACKSURFACE,
  FOREST_GRAPH_DATAEXPOSURE,
  FOREST_GRAPH_BRANDSECURITY
} from "../actions/";

const initialState = {
  loading: true,
  response: null,
  list: null,
  data: null,
  fetchedData: {},
  threatAnalyist: [],
  forestview:[],
  forestlist:[],
  forestgraphattacksurface:[],
  forestgraphbrandsecurity:[],
  forestgraphdataexposure:[]
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FOREST_GRAPH_DATAEXPOSURE:
      return{
        ...state,
        forestgraphdataexposure: action.payload,        
      }
      case FOREST_GRAPH_BRANDSECURITY:
        return{
          ...state,
          forestgraphbrandsecurity: action.payload,        
        }
    case FOREST_GRAPH_ATTACKSURFACE:
      return{
        ...state,
        forestgraphattacksurface: action.payload,        
      }
    case CREATING_FOREST_VIEW:
      return{
        ...state,
        forestview: action.payload,        
      }
      case GET_FOREST_VIEW:
      return{
        ...state,
        forestlist: action.payload,        
      }
    case FETCHING_CUSTOMERS:
      return {
        ...state,
        response: action.payload,
        fetchedData: null,
        customer: null,
        loading: true
      };
    case FETCHING_VENDORS:
      return {
        ...state,
        response: action.payload,
        fetchedData: null,
        vendor: null,
        loading: true
      };
    // case CREATING_CUSTOMER:
    //   return {
    //     ...state,
    //     response: action.payload,
    //     loading: true,
    //   };

    case CUSTOMER_CREATED:
      return {
        ...state,
        // response: action.payload,
        data: !action.payload.error ? action.payload.data : null,
        loading: false,
      };

    case CUSTOMERS_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: !action.payload.error ? action.payload.data : [],
        loading: false,
      };
    case VENDORS_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: !action.payload.error ? action.payload.data : [],
        loading: false,
      };
    case EDIT_CUSTOMER_FETCHED:
      return {
        ...state,
        fetchedData: action.payload,
        customer: !action.payload.error ? action.payload.data : null
      };

    case UPDATING_CUSTOMER:
      return {
        ...state,
        fetchedData: action.payload,
        loading: true,
      };
    case CUSTOMER_UPDATED:
      return {
        ...state,
        fetchedData: action.payload,
        loading: false,
      };

    case DELETING_CUSTOMER:
      return {
        ...state,
        response: action.payload,
        loading: true,
      };
    case CUSTOMER_DELETED:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };
    case FETCHING_THREAT_ANALYIST:
      return {
        ...state,
        threatAnalyist: action.payload,
        loading: false,
      };
    case GET_TRIAL_REQUEST:
      return {
        ...state,
        trialRequest: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
