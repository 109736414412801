import {  REPORT_REQUESTS_FETCHED } from "../actions/";

export default function(state = { loading: true, response: null, requests: null }, action) {
  switch (action.type) { 
    // case REPORT_REQUESTS_FETCHED:
    //   return {...state,response: action.payload, requests: !action.payload.error ? action.payload.data : [], loading: false};
    default:
      return state;
  }
}
