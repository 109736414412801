import { PASTEBIN_RECORD_FETCHED  , PASTEBIN_RECORD_FETCHED_GRAPH , PASTEBIN_RECORD_FETCHED_TOTAL} from "../actions";

export default function (
  state = { loading: true, response: null, pasteGraph:null, pasteTotal:null, list: null, scanCompleted: true },
  action
) {
    //console.log(action,'PASTEBIN_RECORD_FETCHED');
  switch (action.type) {
    case PASTEBIN_RECORD_FETCHED:
      return {
        ...state,
        response: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted
      };
      case PASTEBIN_RECORD_FETCHED_GRAPH:
        return {
          ...state,
          pasteGraph: action.payload,
        };
        case PASTEBIN_RECORD_FETCHED_TOTAL:
        return {
          ...state,
          pasteTotal: action.payload,
        };
    default:
      return state;
  }
}
