const initialState = {
  data: [],
  accounts: [],
  scans: {},
};

const cloudAccount = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_CLOUD_ACCOUNT":
      return {
        ...state,
        data: action.payload,
      };
    case "GET_SCAN_BY_ACCOUNT_ID":
      return {
        ...state,
        accounts: action.payload,
      };
    case "GET_RESULT_BY_ACCOUNT_ID":
      return {
        ...state,
        results: action.payload,
      };
    case "GET_SCAN_DETAILS":
      return {
        ...state,
        scans: action.payload,
      };
    default:
      return state;
  }
};

export default cloudAccount;
