import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchWebTech,
  fetchWebTechGraph,
  fetchWebTotal,
  fetchWebTechAction,
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
// import Tooltip from "../common/toolTip";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import ScanMessage from "../common/scanningMessage";
import { techstack } from "../../utils/webstack_version";
import AccessPage from "../Shared/accessPage";
import { data } from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, useParams } from "react-router-dom";

const WebTechnology = () => {
  let subdomains = useSelector(
    (state) => state?.subDomain?.response?.data?.domains
  );
  let totalData = useSelector((state) => state?.subDomain?.response?.data);

  let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

  let scanDetails = useSelector(
    (state) => state?.subDomain?.garphData?.payload?.data
  );

  let totalCount = useSelector(
    (state) => state?.subDomain?.totalCount?.payload?.data
  );
  const [filterData, setfilterData] = useState("all");
  const [searchBy, setSearchBy] = useState("subDomainName");
  subdomains = subdomains?.filter(
    (row) => row?.sslDetails?.[`${row?.subDomainName}`]?.cert_sn
  );

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [name, setName] = useState("");
  const [isModalTwo, setModalTwo] = useState("");
  const [cve, setCve] = useState([]);
  const [category, setCategory] = useState("all");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const myRef = useRef(null);
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let distinctFilter = ["all", "Vulnerable", "OK"];

  // First useEffect for fetching total data
  useEffect(() => {
    fetchWebTotal(id, dispatch);
  }, [id, dispatch]);

  // Second useEffect for fetching tech graph
  useEffect(() => {
    fetchWebTechGraph(id, dispatch);
  }, [id, dispatch]);

  // Third useEffect for setting total items
  useEffect(() => {
    setTotalItems(totalData?.totalTechs);
  }, [totalData?.totalTechs]);

  // Fourth useEffect for fetching tech data
  useEffect(() => {
    if (orgId) {
      fetchWebTech(
        orgId,
        page,
        dispatch,
        category,
        filterData,
        searchBy,
        search
      );
    } else {
      fetchWebTech(id, page, dispatch, category, filterData, searchBy, search);
    }
  }, [id, page, dispatch, category, filterData, searchBy, search, category]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event, name) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      const data = {
        action: "markAsRemidiated",
        techIds: selectedIds == 0 ? _id : selectedIds,
        actionNote: "",
        for: "forever",
        techName: name,
      };
      fetchWebTechAction(
        id,
        page,
        dispatch,
        category,
        filterData,
        searchBy,
        search,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    const data = {
      action: "resetAction",
      techIds: selectedIds == 0 ? _id : selectedIds,
    };
    fetchWebTechAction(
      id,
      page,
      dispatch,
      category,
      filterData,
      searchBy,
      search,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      techIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
      techName: name,
    };
    fetchWebTechAction(
      id,
      page,
      dispatch,
      category,
      filterData,
      searchBy,
      search,
      data
    );
    setModalTwo("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTwo("Form Wizard modal two");
  };

  let currentTableData = [];
  const subdomainsdData = useSelector(
    (state) => state?.subDomain?.response?.data?.technologies
  );

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/webtech_analysis/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Web Technology Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    clearTimeout(handleSearchChange.timeoutId);
    handleSearchChange.timeoutId = setTimeout(() => {
      setSearch(inputValue);
      setPage(1);
    }, 1000);
  };

  const outdatedTechPercent =
    ((totalCount?.totalOutdatedTechs || 0) /
      (totalCount?.totalUsedUniqueTechs + totalCount?.totalOutdatedTechs ||
        0)) *
    100;
  const latesTechPercent =
    ((totalCount?.totalUsedUniqueTechs || 0) /
      (totalCount?.totalUsedUniqueTechs + totalCount?.totalOutdatedTechs ||
        0)) *
    100;
  let mapData = subdomainsdData;

  mapData = mapData;

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  console.log(currentTableData, "currentTableData");

  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const editModal = (row) => {
    setCve(row);
    setModal("Form Wizard modal");
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <ReactTooltip className="reactTooltipClass" multiline={true} />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Outdated Web Technologies</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalCount?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {mapData?.length === 0 ? null : (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                      // exportToCSV(downloadCsvData, "Sub-Domain Details");
                    }}
                    href="/add-report"
                    style={{ fontWeight: "bold" }}
                    className="btn btn-primary mr-1"
                  >
                    <i className="fa fa-file-excel-o mr-1" style={{}}></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-4 col-md-12">
            <div className="white-box-dashboard">
              <div className="body" style={{ minHeight: "400px" }}>
                <h4>Details</h4>
                <div className="row text-center">
                  <div className="col-12">
                    <div className="card-box mr-0">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-window-maximize icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalCount?.totalUsedUniqueTechs}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Web Technologies
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card-box mr-0">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-map-marker icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalCount?.totalVulnerableSubdomains}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Vulnerable Subdomains
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="card user_statistics white-box-dashboard">
              <div
                className="body"
                id="graphBody"
                style={{ minHeight: "400px" }}
              >
                <h4>Previous scan details</h4>

                {scanDetails && (
                  <BasicLineColumn
                    dates={scanDetails?.dates}
                    ipArray={scanDetails?.scanDetails}
                    color={["#DD3731"]}
                    yAxis="Records Found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${
                        category === "all" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setfilterData("all");
                        setSearchBy("subDomainName");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "risk-accepted" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setfilterData("all");
                        setSearchBy("subDomainName");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "false-positive" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setfilterData("all");
                        setSearchBy("subDomainName");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "remidiated" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setfilterData("all");
                        setSearchBy("subDomainName");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul>
                <div className="card" ref={myRef}>
                  <div className="header">
                    <div className="body">
                      <div className="row">
                        <div className="col-sm-3">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            value={filterData}
                            onChange={(e) => {
                              setfilterData(e.target.value);
                              if (e.target.value.toLowerCase() === "all") {
                                setSearchBy("");
                                setSearch("");
                              }
                              setPage(1);
                            }}
                          >
                            {distinctFilter?.map((row) => {
                              return (
                                <option value={row?.toLowerCase()}>
                                  {row?.toUpperCase()}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            value={searchBy}
                            onChange={(e) => {
                              setSearchBy(e.target.value);
                              setPage(1);
                            }}
                          >
                            <option value="subDomainName">Sub Domain</option>
                            <option value="techStack">Tech Stack</option>
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <div
                            data-test="datatable-input"
                            class="mdb-datatable-filter flex-row"
                          >
                            <input
                              class="form-control form-control-sm ml-0 my-1"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={handleSearchChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          {selectedIds?.length > 1 && category === "all" && (
                            <Box sx={{ width: 200 }}>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedValue}
                                  onChange={(event) => {
                                    setAction(event.target.value);
                                    snooze("", event.target.value);
                                  }}
                                >
                                  <MenuItem value="pleaseSelect">
                                    Snooze Options
                                  </MenuItem>
                                  <MenuItem
                                    value="riskAccepted"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    Risk Accepted
                                  </MenuItem>
                                  <MenuItem
                                    value="markAsFalsePositive"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    False Positive
                                  </MenuItem>
                                  <MenuItem value="markAsRemidiated">
                                    Remediated
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}
                          {selectedIds?.length > 1 &&
                            (category == "false-positive" ||
                              category == "risk-accepted") && (
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed("")}
                              >
                                Unsnooze
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-custom spacing5 mb-0">
                          <thead>
                            <tr>
                              {(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedIds == 0 ? false : multiCheckTrue
                                    }
                                    onClick={() => {
                                      multicheck(currentTableData);
                                    }}
                                    className="checkUncheck"
                                  />
                                </th>
                              )}
                              <th>Status</th>
                              <th>Sub Domain</th>
                              <th>Tech Stack</th>
                              <th>Current Version</th>
                              <th>Latest Version</th>
                              <th>Cves Detail</th>
                              <th>Exploitable</th>
                              {(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th style={{ width: "80" }}>Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData?.map((res, index) => {
                              return (
                                <tr key={index}>
                                  {(category === "all" ||
                                    category === "risk-accepted" ||
                                    category === "false-positive") && (
                                    <td>
                                      <input
                                        onClick={() =>
                                          singleClick(
                                            res?._id,
                                            res?.name,
                                            res?.uuid
                                          )
                                        }
                                        checked={selectedIds?.some(
                                          (item) => item?.uuid == res?.uuid
                                        )}
                                        type="checkbox"
                                        className="checkUncheck"
                                      />
                                    </td>
                                  )}
                                  <td>
                                    {res?.hasVulnerabilities ? (
                                      <span className="badge badge-danger">
                                        Vulnerable
                                      </span>
                                    ) : (
                                      <span className="badge badge-success">
                                        Ok
                                      </span>
                                    )}
                                  </td>
                                  <td id="info">{res?.subDomainName}</td>
                                  <td>{res?.name}</td>
                                  <td>{res?.version}</td>
                                  <td>{res?.latestVersion}</td>
                                  <td
                                    onClick={() =>
                                      res?.exploits &&
                                      Object.values(res?.exploits).some(
                                        (exploit) => exploit?.length > 0
                                      ) > 0 &&
                                      editModal(res)
                                    }
                                    style={{
                                      cursor:
                                        res?.exploits &&
                                        Object.values(res?.exploits).some(
                                          (exploit) => exploit?.length > 0
                                        )
                                          ? "pointer"
                                          : "auto",
                                    }}
                                  >
                                    {res?.exploits &&
                                    Object.values(res?.exploits).some(
                                      (exploit) => exploit?.length > 0
                                    )
                                      ? "Vulnerable (Detail)"
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {res?.exploits &&
                                    Object.values(res?.exploits).some(
                                      (exploit) => exploit?.length > 0
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  {/* <td
                                    onClick={() =>
                                      res?.exploits &&
                                      Object?.keys(res?.exploits)?.length > 0 &&
                                      editModal(res)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {Object?.keys(res?.exploits)?.length === 0
                                      ? "N/A"
                                      : "Vulnerable (Detail)"}
                                  </td>
                                  <td>
                                    {res?.exploits &&
                                    Object.values(res?.exploits).some(
                                      (exploit) => exploit?.length > 0
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td> */}
                                  {category === "all" && (
                                    <td>
                                      <Box sx={{ width: 200 }}>
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={(event) => {
                                              setAction(event.target.value);
                                              snooze(
                                                res?._id,
                                                event.target.value,
                                                res?.name
                                              );
                                            }}
                                          >
                                            <MenuItem value="pleaseSelect">
                                              Snooze Options
                                            </MenuItem>
                                            <MenuItem
                                              value="riskAccepted"
                                              onClick={() => {
                                                addModal();
                                                setName(res?.name);
                                                setLookalikeAppId([res?._id]);
                                              }}
                                            >
                                              Risk Accepted
                                            </MenuItem>
                                            <MenuItem
                                              value="markAsFalsePositive"
                                              onClick={() => {
                                                addModal();
                                                setName(res?.name);
                                                setLookalikeAppId([res?._id]);
                                              }}
                                            >
                                              False Positive
                                            </MenuItem>
                                            <MenuItem value="markAsRemidiated">
                                              Remediated
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </td>
                                  )}
                                  {(category === "risk-accepted" ||
                                    category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() =>
                                          Unsnoozed(
                                            res?._id,
                                            res?.name,
                                            res?.uuid
                                          )
                                        }
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {totalItems > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={totalItems}
                            pageSize={PageSize}
                            onPageChange={(page) => {
                              setPage(page);
                              // fetchWebTech(
                              //   id,
                              //   page,
                              //   dispatch,
                              //   filterData,
                              //   searchBy,
                              //   search
                              // );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Cve Details
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModal("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12">
                        <div className="col-lg-12 col-md-12">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>CVE ID</th>
                                <th>Known Exploit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cve?.exploits &&
                                Object.entries(cve?.exploits).map(
                                  ([cveId, links], index) => (
                                    <tr key={index}>
                                      <td>
                                        <strong>
                                          <Link
                                            to={`/cve/${cveId}`}
                                            className="p-2 rounded-md bg-slate-200"
                                          >
                                            {cveId}
                                          </Link>
                                        </strong>
                                      </td>
                                      <td>
                                        <ul className="m-0">
                                          {links.map((link, linkIndex) => (
                                            <li className="m-0" key={linkIndex}>
                                              <a
                                                href={link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {link ? link : "N/A"}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModalTwo === "Form Wizard modal two" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalTwo("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
              <div className="">
              <div className="card mb-0">
                    <div className="row clearfix">
                       
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                       
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      
                    </div>
                     
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModalTwo("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebTechnology;
