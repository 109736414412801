import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import { PASTEBIN_RECORD_FETCHED , PASTEBIN_RECORD_FETCHED_GRAPH , PASTEBIN_RECORD_FETCHED_TOTAL} from "./index";
import { toastr } from 'react-redux-toastr';



export const fetchPastebinResult = (props, dispatch, page ,category) => {
    if(props){
      axios
        .get(`${API_URL}/pastebin/${props}/delta?page=${page}&category=${category}`, {})
        .then((res) => {
            //console.log(res,'pastebin response')
          dispatch({
            type: PASTEBIN_RECORD_FETCHED,
            payload: res.data,
          });
          
        });
    }
};

export const fetchPastebinResultAction = (props, dispatch, page , data,category) => {
  if(props){
    axios
      .post(`${API_URL}/pastebin/${props}/action?page=${page}&category=${category}`, data)
      .then((res) => {
          //console.log(res,'pastebin response')
        dispatch({
          type: PASTEBIN_RECORD_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.success("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchPastebinResultGraph = (props, dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/pastebin/${props}/graph`, {})
      .then((res) => {
          //console.log(res,'pastebin response')
        dispatch({
          type: PASTEBIN_RECORD_FETCHED_GRAPH,
          payload: res.data,
        });
        
      });
  }
};

export const fetchPastebinResultTotal = (props, dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/pastebin/${props}/totals`, {})
      .then((res) => {
          //console.log(res,'pastebin response')
        dispatch({
          type: PASTEBIN_RECORD_FETCHED_TOTAL,
          payload: res.data,
        });
        
      });
  }
};
