import React,{useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { checkToken } from "../../actions/auth";


const CheckToken = (props) => {
//   const { token, refreshToken } = useParams();

//   console.log(token, refreshToken, 'token, refreshToken')
    // const dispatch = useDispatch();
    // const query = new URLSearchParams(props.location.search);
    // const token = query.get('token')
    // const refreshToken = query.get('refreshToken')
    // console.log(token, refreshToken, 'token, refreshToken')

// debugger;
//   if (!token || !refreshToken) {
//     window.location.replace("/login");
//   }else{
      // checkToken

    //   useEffect(() => {
        // checkToken({token:token,refreshToken:refreshToken}, dispatch);
    //   },[token])
//   }

  return(
    <>
    </>
  )

}

export default CheckToken;

// class ResetPassword extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { userMessage: "", email: "" };

    
//   }

//   componentDidMount() {
//     console.log("check token")
//     if (!this?.props?.match?.params?.token || !this?.props?.match?.params?.refreshToken) {
//       window.location.replace("/login");
//     }else{
//         let token = this?.props?.match?.params?.token;
//         let refreshToken = this?.props?.match?.params?.refreshToken
//         // checkToken
// 		this.props.checkToken({token:token,refreshToken:refreshToken});
//     }
//   }

//   componentDidUpdate() {
//     if (
//       this.state.loading &&
//       this.props.auth.user &&
//       this.props.auth.user.passwordReset
//     ) {
//       this.setState({
//         loading: false,
//       });

//       this.props.history.push("/");
//     }
//   }

 

//   render() {
    
//     return (
//       <>
       
//       </>
//     );
//   }
// }

// function mapStateToProps({ auth }) {
//   return {
//     auth,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     {
//         checkToken,
//     },
//     dispatch
//   );
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
