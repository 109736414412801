import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  getTrialRequest,
  updateTrialRequest,
  sendUserInvite,
} from "../../actions/customer";
import { Link, useParams } from "react-router-dom";

const Clients = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.auth?.user);
  var date = new Date();
  date = moment(date).add(1, "year").toDate();
  const [isModal, setModal] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [approvalId, setApprovalId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [expiredAt, selectDate] = useState(date);
  const [status, setStatus] = useState("all");
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");

  // console.log(auth, "auth");
  let customers = useSelector((state) => state?.customer?.trialRequest?.data);

  useEffect(() => {
    getTrialRequest(dispatch);
  }, []);

  let mapData =
    filterData === "all"
      ? customers
      : filterData === "client"
      ? null
      : filterData === "mssp"
      ? null
      : null;

  mapData =
    search == ""
      ? mapData
      : mapData?.filter(
          (row) =>
            row?.orgName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1 ||
            row?.orgContactName
              ?.toLowerCase()
              ?.indexOf(search?.toLowerCase()) != -1 ||
            row?.orgEmail?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
        );

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }
  const downloadCsvData = customers?.map((res) => ({
    Client: res?.orgName,
    PrimaryContactName: res?.orgContactName,
    Email: res?.orgEmail,
    Contact: res?.orgContact,
    Website: res?.website,
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const approveRequest = () => {
    setModal(false);
    updateTrialRequest(
      {
        id: approvalId,
        status: approvalStatus,
      },
      dispatch
    );
  };

  const sendReminder = () => {
    setReminderModal(false);
    sendUserInvite({
      email: emailId,
    });
  };

  //console.log(threatAnalyist, domains.length, "domains && domains.length");
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Dashboard</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Trial Request
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Client Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  {customers?.length === 0 ? null : (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        exportToCSV(downloadCsvData, "Client List");
                      }}
                      style={{ float: "right" }}
                      href="/add-report"
                      className="btn btn-primary mr-1"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </a>
                  )}
                </div>
              </div>
              <div className="table-responsive">
              <div>
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      style={{ width: "200px", marginTop: "10px" }}
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="client">Clients</option>
                      <option value="mssp">MSSP</option>
                    </select>
                  </div>
                <table className="table table-custom spacing5 mb-0">
                <thead>
                  
                 
                    <tr>
                      <th>#</th>
                      <th>Customer Name</th>
                      <th>Subscription Type</th>
                      <th>Email Address</th>
                      <th>Websites</th>
                      <th>Lead Source</th>
                      <th>Registered on</th>
                      <th>Last Scan Date</th>
                      <th>Password Reset Date</th>
                      <th>Actions</th>
                    </tr></thead>
                    <tbody>
                    {currentTableData?.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <span>{index + 1}</span>
                          </td>
                          <td>
                            <a href={`/user/${item._id}`} title="">
                              {item.orgName}
                            </a>
                            <p className="mb-0">{item.orgContactName}</p>
                          </td>
                          <td>
                            <td>
                              <span className="badge badge-success">
                                {item.subscriptionType}
                              </span>
                            </td>
                          </td>
                          <td>
                            <span>{item.orgEmail}</span>
                          </td>

                          <td>
                            <a href={`${item?.website}`} target="_blank">
                              {item?.website}
                            </a>
                          </td>
                          <td>{item.leadSource || ""}</td>
                          <td>
                            <span>
                              {" "}
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY hh:mm a"
                              )}{" "}
                            </span>
                          </td>
                          <td>
                            {item?.targets?.[0]?.ratingCalculated
                              ? moment(
                                  item?.targets?.[0]?.lastUserScan ||
                                    item?.targets?.[0]?.createdAt
                                ).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            {item?.users?.[0]?.passwordReset
                              ? moment(
                                  item?.users?.[0]?.passwordResetTime
                                ).format("DD-MM-YYYY")
                              : "NA"}
                          </td>
                          <td>
                            {!item.isUpdated && (
                              <span>Profile Update Pending</span>
                            )}
                            {item.isUpdated &&
                              item.trialRequestStatus == "Pending" && (
                                <button
                                  className="btn btn-success btn-sm mr-1"
                                  onClick={() => {
                                    setApprovalStatus("Approve");
                                    setApprovalId(item._id);
                                    setModal(true);
                                  }}
                                >
                                  Approve
                                </button>
                              )}
                            {item.isUpdated &&
                              item.trialRequestStatus == "Pending" && (
                                <button
                                  className="btn btn-danger btn-sm mr-1"
                                  onClick={() => {
                                    setApprovalStatus("Pending");
                                    setApprovalId(item._id);
                                    setModal(true);
                                  }}
                                >
                                  Reject
                                </button>
                              )}
                            {item.trialRequestStatus == "Approve" && (
                              <span>Trial Approved</span>
                            )}
                            {item.trialRequestStatus == "Reject" && (
                              <span>Reject</span>
                            )}
                            {item.trialRequestStatus == "Approve" &&
                              item?.targets?.[0]?.ratingCalculated &&
                              item?.users?.[0] &&
                              !item?.users?.[0]?.passwordReset && (
                                <i
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setReminderModal(true);
                                    setEmailId(item.orgEmail);
                                  }}
                                  class="fas fa-bell"
                                ></i>
                              )}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${
          isModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {approvalStatus == "Approve" ? "Approve?" : "Reject?"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                {approvalStatus == "Approve"
                  ? "Are you sure to approve the request."
                  : "Are you sure ro reject the request."}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                style={{  fontWeight: "bold" }}
                onClick={() => {
                  approveRequest();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${
          reminderModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Reminder
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Do you really want to send reminder to this user?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setReminderModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                style={{  fontWeight: "bold" }}
                onClick={() => {
                  sendReminder();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
