import { DARK_WEB } from "../actions";

export default function (
  state = {
    loading: true,
    response: null,
  },
  action
) {
    
  switch (action.type) {
    case DARK_WEB:
      return {
        ...state,
        response: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    default:
      return state;
  }
}
