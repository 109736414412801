import { CREATING_CODE_RECORD ,LEAKED_SESSION_FETCHED_GRAPH ,LEAKED_SESSION_FETCHED_TOTAL } from "../actions";

const initialState = {
  data: [],
  scanCompleted: true,
  sourceCodeGraph:[],
  sourceCodeTotal:[]
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_LEAKED_CODE":
      return {
        ...state,
        data: action.data,
        scanCompleted: action.data.scanCompleted
      };
    case LEAKED_SESSION_FETCHED_GRAPH:
      return {
        ...state,
        sourceCodeGraph: action.data,
      };
      case LEAKED_SESSION_FETCHED_TOTAL:
        return {
          ...state,
          sourceCodeTotal: action.data,
        };
      case CREATING_CODE_RECORD:
        return {
          ...state,
          response: action.payload,
          loading: true,
        };
  

    default:
      return state;
  }
}
