import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Piechart extends Component {
  constructor(props) {
    super(props);
    //console.log(props,'propsssssssss')
    this.state = {
      series: props.series,
      options: {
        chart: {
          width: 10,
          type: "pie",
        },
        plotOptions: {
          pie: {
            // donut: {
            size: "100%",
            // }
          },
        },
        colors: ["#f3b4b4", "#f5c3c2", "#ffe3f2", "#d95252"],
        labels: props.label,
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          markers: {
            radius: 0,
          },
        },
      },
    };
  }
  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="pie"
        height={430}
      />
    );
  }
}

export default Piechart;
