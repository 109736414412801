import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

class LineChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
          
            series: [{
                name: "IPs Found",
                data: props.ipArray
            }],
            options: {
              chart: {
                height: 350,
                type: 'line',
                zoom: {
                  enabled: false
                }
              },
              dataLabels: {
                enabled: false
              },
              colors: ["#DD3731"],
              stroke: {
                curve: 'straight'
              },
              title: {
                text: '',
                align: 'left',
                style: {
                    color: '#4C444D'
                }
              },
            //   grid: {
            //     row: {
            //       colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            //       opacity: 0.5
            //     },
            //   },
              xaxis: {
                categories: props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
              }
            },
          
          
        };
    }
    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
        );
    }
}

export default LineChart;
