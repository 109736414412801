import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchLeakedSession,
  fetchLeakedSessionGraph,
  fetchLeakedSessionAction,
} from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import NoData from "../common/noDataComponetnt";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";

const LeakedSession = () => {
  //console.log('leakedsessions'
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState("");
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const state = useSelector(
    (state) => state?.leakedCredential?.sessions?.data?.data
  );

  const totalCount = useSelector(
    (state) => state?.leakedCredential?.sessions?.data?.totalLeakedSessions
  );

  const liveSession = state?.filter(
    (row) =>
      row?.expires && moment(new Date(row.expires * 1000)).isAfter(moment())
  );

  let oldDnsDetails = useSelector(
    (state) => state?.leakedCredential?.leakedGraph?.data
  );

  useEffect(() => {
    if (orgId) {
      fetchLeakedSession(orgId, dispatch, page, filterData, search, category);
    } else {
      fetchLeakedSession(id, dispatch, page, filterData, search, category);
    }
  }, [id, dispatch, page, filterData, search, category]);

  useEffect(() => {
    setTotalItems(totalCount);
  }, [totalCount]);

  useEffect(() => {
    fetchLeakedSessionGraph(id, dispatch);
  }, [id, dispatch]);

  let response = state;

  // response = response?.sort((a, b) => (new Date(a.fnd * 1000).getTime() > new Date(b.fnd * 1000).getTime()) ? -1 : ((new Date(b.fnd * 1000).getTime() > new Date(a.fnd * 1000).getTime()) ? 1 : 0))

  let mapData = response;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/leaked-sessions/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LeakedSession.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedSessionIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchLeakedSessionAction(
        id,
        dispatch,
        page,
        filterData,
        search,
        category,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedSessionIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchLeakedSessionAction(
      id,
      dispatch,
      page,
      filterData,
      search,
      category,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedSessionIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchLeakedSessionAction(
      id,
      dispatch,
      page,
      filterData,
      search,
      category,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Leaked Sessions (Stealer Logs)</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Leaked Sessions
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {oldDnsDetails?.inddividualRating}</b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
              <span>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                  }}
                  href="/add-report"
                  className="btn btn-primary mr-1"
                  style={{   fontWeight: "bold" }}
                >
                  <i
                    className="fa fa-file-excel-o mr-1"
                    style={{   }}
                  ></i>{" "}
                  Download Details
                </a>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "460px" }}>
              <h6>Previous scan details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={oldDnsDetails?.scanDetails}
                  color={["#DD3731"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${
              category === "all" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setfilterData("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "risk-accepted" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setfilterData("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "false-positive" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setfilterData("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "remidiated" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setfilterData("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card mb-1">
        <div className="row">
          <div className="col-sm-4">
            <select
              className="custom-select custom-select-sm form-control form-control-sm"
              value={filterData}
              onChange={(e) => setfilterData(e.target.value)}
            >
              <option value="all">All</option>
              <option value="live-session">Live Sessions</option>
            </select>
          </div>
          <div className="col-sm-4">
            <div
              data-test="datatable-input"
              class="mdb-datatable-filter flex-row"
            >
              <input
                class="form-control form-control-sm ml-0 my-1"
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-4">
            {selectedIds.length > 1 && category === "all" && (
              <Box sx={{ width: 200 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    onChange={(event) => {
                      setAction(event.target.value);
                      snooze("", event.target.value);
                    }}
                  >
                    <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                    <MenuItem
                      value="riskAccepted"
                      onClick={() => {
                        addModal();
                      }}
                    >
                      Risk Accepted
                    </MenuItem>
                    <MenuItem
                      value="markAsFalsePositive"
                      onClick={() => {
                        addModal();
                      }}
                    >
                      False Positive
                    </MenuItem>
                    <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {selectedIds.length > 1 &&
              (category == "false-positive" || category == "risk-accepted") && (
                <button
                  className="btn btn-primary btn-sm mr-1 Unsnooze"
                  onClick={() => Unsnoozed("")}
                >
                  Unsnooze
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-12 col-sm-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-custom spacing5">
                <thead>
                  <tr>
                    {(category === "all" ||
                      category === "risk-accepted" ||
                      category === "false-positive") && (
                      <th>
                        <input
                          type="checkbox"
                          checked={selectedIds == 0 ? false : multiCheckTrue}
                          onClick={() => {
                            multicheck(currentTableData);
                          }}
                          className="checkUncheck"
                        />
                      </th>
                    )}
                    <th>Name</th>
                    <th>Domain</th>
                    <th>Value</th>
                    <th>Path</th>
                    <th>Session Expiry</th>
                    <th>Found On</th>
                    {(category === "all" ||
                      category === "risk-accepted" ||
                      category === "false-positive") && (
                      <th style={{ width: "80" }}>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {client?.subscriptionType == "trial" ? (
                    <tr>
                      <td colSpan={6}>
                        <NoData />
                      </td>
                    </tr>
                  ) : (
                    currentTableData?.map((row, index) => {
                      //console.log(new Date(row.expires * 1000), 'new Date(row.expires)')
                      // let value = row?.val?.split("") || ['N', 'A'];
                      let date = row?.fnd
                        ? row?.fnd?.toString().substr(0, 4) +
                          "-" +
                          row?.fnd?.toString().substr(4, 2) +
                          "-" +
                          row?.fnd?.toString().substr(6, 2)
                        : "NA";
                      return (
                        <tr key={`leaked${index}`}>
                          {(category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <input
                                onClick={() => singleClick(row?._id)}
                                checked={selectedIds?.includes(row?._id)}
                                type="checkbox"
                                className="checkUncheck"
                              />
                            </td>
                          )}
                          <td>{row?.name || "NA"}</td>
                          <td style={{ width: "100px" }}>{row?.dom || "NA"}</td>
                          <td
                            style={{
                              width: "100px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              cursor: "pointer", // Add cursor style to indicate it's clickable
                            }}
                          >
                            <span title={row?.val}>
                              {(row?.val || "NA").slice(0, 50)}
                            </span>
                          </td>

                          <td style={{ width: "100px" }}>
                            {row?.path || "NA"}
                          </td>
                          <td style={{ whiteSpace: "unset" }}>
                            {row?.expires
                              ? moment(row?.expires).format("DD-MM-YYYY HH:MM")
                              : "NA"}
                          </td>

                          <td style={{ whiteSpace: "unset" }}>
                            {row?.createdAt
                              ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : "NA"}
                          </td>
                          {category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId(row._id);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId(row._id);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {(category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed(row?._id)}
                              >
                                Unsnooze
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="dataTables_paginate">
              {totalItems > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={totalItems}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="">
                <div className="card mb-0">
                  <div className="row clearfix">
                     
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      
                    </div>
                  </div>
                   
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                          setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeakedSession;
