import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditDescription from "../Projects/EditDescription";
import Edit from "../Projects/Edit";
import EditButton from "../Projects/EditButton";
import NewReference from "../Projects/NewReference";
import { CVEDetail, deleteReferenceApi } from "../../actions/cveInformation";
import { useSelector, useDispatch } from "react-redux";

const EditCve = () => {
  const cveData = useSelector(
    (state) => state?.cveInformation?.cveDetails?.data
  );
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { cveId: cveID } = useParams();
  const [cveId, setCveId] = useState(cveID); // Use cveID instead of useParams()["cveId"]
  // const [cveData, setCveData] = useState("");
  const [editingDescriptionStatus, setEditingDescriptionStatus] =
    useState(false);
  const [editing, setEditing] = useState(false);
  const [addingNewReference, setNewReferenceFor] = useState(false);

  useEffect(() => {
    CVEDetail(dispatch, cveId);
  }, [dispatch, cveId]);

  function deleteReference(referenceId) {
    deleteReferenceApi(dispatch, cveData?._id, referenceId);
  }

  return (
    <>
      {addingNewReference && (
        <NewReference
          _id={cveData?._id}
          cveId={cveId}
          onCancel={() => {
            setNewReferenceFor(false);
          }}
        />
      )}
      {editing && (
        <Edit
          cveId={cveId}
          model={editing["model"]}
          _id={editing["_id"]}
          field={editing["field"]}
          value={editing["value"]}
          fieldType={editing["fieldType"]}
          onEditCancel={() => {
            setEditing(false);
          }}
        />
      )}
      {editingDescriptionStatus && (
        <EditDescription
          fieldType="cveDescription"
          cveId={cveData["cveId"]}
          description={cveData["cveDescription"]}
          onEditCancel={() => {
            setEditingDescriptionStatus(false);
          }}
        />
      )}
      <div className="flex flex-col p-2">
        <div className="flex flex-row gap-3 p-2 items-center rounded-md">
          <label className="font-bold">Cve Id</label>
          <input
            value={cveId}
            onChange={(e) => {
              setCveId(e.target.value);
            }}
            className="pl-2 pb-2 focus:outline-none rounded-md"
          />
        </div>

        {cveData && (
          <>
            <div className="row m-0 justify-content-between">
              <div
                className="flex col-md-12 flex-col borderCVE"
                style={{
                  
                  padding: "10px !important",
                }}
              >
                <div>
                  <div className="flex  block-header flex-row justify-start mb-0">
                    <h2>Description</h2>
                    <EditButton className="btn btn-primary"
                      onClick={() => {
                        setEditing({
                          model: cveData["model"],
                          _id: cveData?._id,
                          field: "cveDescription",
                          value: cveData["cveDescription"],
                          fieldType: "text",
                          
                        });
                      }}
                    >
                    <i class="fa-solid fa-pencil mr-1"></i>  Edit
                    </EditButton>
                  </div>
                  <div>{cveData["cveDescription"]}</div>
                </div>
                <div>
                  <div className="flex  block-header flex-row justify-start mt-4">
                    <h2>Published date</h2>
                    <EditButton
                      onClick={() => {
                        setEditing({
                          model: cveData["model"],
                          _id: cveData?._id,
                          field: "publishedDate",
                          value: cveData["publishedDate"],
                          fieldType: "date",
                        });
                      }}
                    >
                     <i class="fa-solid fa-pencil mr-1"></i> Edit
                    </EditButton>
                  </div>
                  <div>{cveData["publishedDate"]}</div>
                </div>
                <div>
                  <div className="flex flex-row block-header justify-start mt-4">
                    <h2>Last modigied date</h2>
                    <EditButton
                      onClick={() => {
                        setEditing({
                          model: cveData["model"],
                          _id: cveData?._id,
                          field: "lastModifiedDate",
                          value: cveData["lastModifiedDate"],
                          fieldType: "date",
                        });
                      }}
                    >
                     <i class="fa-solid fa-pencil mr-1"></i> Edit
                    </EditButton>
                  </div>
                  <div>{cveData["lastModifiedDate"]}</div>
                </div>
              </div>
            </div>
            <div
              className="flex col-md-12 flex-col borderCVE"
              style={{ padding: "10px !important" }}
            >
              <div className="flex block-header flex-row justify-start">
                <h2>CVSS score</h2>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex block-header flex-col mt-0">
                  {cveData?.impact?.baseMetricV3  && (
                    <>
                      <div className="col-12 p-0">
                        <div className="table-responsive">
                          <table className="table table-custom spacing0 mb-0">
                            <thead>
                              <tr>
                                <th>Base score</th>
                                <th>Base severity</th>
                                <th>CVSS Vector</th>
                                <th>Exploitability Score</th>
                                <th>Impact Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    cveData["impact"]["baseMetricV3"]["cvssV3"]
                                      ?.baseScore
                                  }
                                </td>
                                <td>
                                  {
                                    cveData["impact"]["baseMetricV3"]["cvssV3"]
                                      ?.baseSeverity
                                  }
                                </td>
                                <td>
                                  {
                                    cveData["impact"]["baseMetricV3"]["cvssV3"]
                                      ?.vectorString
                                  }
                                </td>
                                <td>
                                  {
                                    cveData["impact"]["baseMetricV3"]
                                      ?.exploitabilityScore
                                  }
                                </td>
                                <td>
                                  {
                                    cveData["impact"]["baseMetricV3"]
                                      ?.impactScore
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                </div>
            </div>
            {cveData?.targetedCountries == '' || cveData?.industries?.length == '' || cveData?.malwareFamilies?.length == ''(
            <div
              className="flex col-md-12 flex-col borderCVE"
              style={{  padding: "10px !important" }}
            >
              <div className="flex block-header flex-row justify-start">
                <h2>
                  {cveData?.cveId} is in the CISA known exploits (Optional)
                </h2>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex block-header flex-col mt-0">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-custom spacing5 mb-0">
                        <tbody>
                          <tr>
                            <td>Targeted Countries : </td>
                            {cveData?.targetedCountries?.map((res, index) => (
                              <td key={index}>
                                <div className="table_div">{res}</div>
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>Targeted Industries : </td>
                            {cveData?.industries?.map((res, index) => (
                              <td key={index}>
                                <div className="table_div">{res}</div>
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>Malware Families : </td>
                            {cveData?.malwareFamilies?.map((res, index) => (
                              <td key={index}>
                                <div className="table_div">{res}</div>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            {cveData.exploits.length > 0 && (
            <div
              className="flex col-md-12 flex-col borderCVE"
              style={{  padding: "10px !important" }}
            >
              <div className="flex block-header flex-row justify-start">
                <h2>Exploits</h2>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex block-header flex-col mt-0">
                  <div className="col-12 p-0">
                    <div className="table-responsive">
                      <table className="table table-custom spacing0 mb-0">
                        <thead>
                          <tr>
                            {/* <th>Model</th>
                            <th>CVE ID</th> */}
                            <th>Description</th>
                            <th>Type</th>
                            <th>Platform</th>
                            <th>Author</th>
                            <th>Download URL</th>
                            <th>Published Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cveData.exploits.map((cveItem) => (
                            <tr key={cveItem._id}>
                              <td>{cveItem.description}</td>
                              <td>{cveItem.type}</td>
                              <td>{cveItem.platform}</td>
                              <td>{cveItem.author}</td>
                              <td>
                                <a
                                  href={cveItem.downloadUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {cveItem.downloadUrl}
                                </a>
                              </td>
                              <td>{cveItem.publishedDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            <div className="flex col-md-12 flex-col borderCVE">
              <div className="flex block-header flex-row justify-start">
                <h2>References</h2>
                <button
                  onClick={() => {
                    setNewReferenceFor({ cveId: cveId });
                  }}
                  className="btn ml-auto flex-shrink-0 btn-primary"
                >
                 <i class="fa-solid fa-plus mr-1"></i> Add reference
                </button>
              </div>
              <div
                className="flex flex-col gap-3"
                style={{
                  
                  padding: "10px !important",
                }}
              >
                {cveData["references"]?.map((reference, i) => {
                  console.log(reference);
                  return (
                    <div key={i} className="flex flex-col p-2 ref-box">
                      <div className="flex flex-row gap-4 items-center flex-shrink-0 w-full border-cve-btm">
                        <EditButton
                          onClick={() => {
                            deleteReference(reference["_id"]);
                          }}
                        >
                         <i class="fa-solid fa-trash mr-1"></i> Delete
                        </EditButton>
                      </div>
                      <div className="flex flex-row gap-4 items-center flex-shrink-0 w-full border-cve-btm">
                        <p className="font-bold flex-shrink-0">Name</p>
                        <p className="whitespace-normal overflow-auto">
                          {reference["name"]}
                        </p>
                        <EditButton
                          onClick={() => {
                            setEditing({
                              model: reference["model"],
                              _id: reference["_id"],
                              field: "name",
                              value: reference["name"],
                              fieldType: "text",
                            });
                          }}
                        >
                         <i class="fa-solid fa-pencil mr-1"></i> Edit
                        </EditButton>
                      </div>
                      <div className="flex flex-row gap-4 items-center flex-shrink-0 w-full border-cve-btm">
                        <p className="font-bold flex-shrink-0">Refsource</p>
                        <p className="whitespace-normal overflow-auto">
                          {reference["refsource"]}
                        </p>
                        <EditButton
                          onClick={() => {
                            setEditing({
                              model: reference["model"],
                              _id: reference["_id"],
                              field: "refsource",
                              value: reference["refsource"],
                              fieldType: "text",
                            });
                          }}
                        >
                         <i class="fa-solid fa-pencil mr-1"></i> Edit
                        </EditButton>
                      </div>
                      <div className="flex flex-row gap-4 items-center flex-shrink-0 w-full border-cve-btm">
                        <p className="font-bold flex-shrink-0">Url</p>
                        <p className="whitespace-normal overflow-auto">
                          {reference["url"]}
                        </p>
                        <EditButton
                          onClick={() => {
                            setEditing({
                              model: reference["model"],
                              _id: reference["_id"],
                              field: "url",
                              value: reference["url"],
                              fieldType: "text",
                            });
                          }}
                        >
                         <i class="fa-solid fa-pencil mr-1"></i> Edit
                        </EditButton>
                      </div>
                      <div className="flex flex-row gap-2 items-center p-2 ">
                        <p className="font-bold mb-0">Tags</p>
                        <div className="flex flex-row gap-3 flex-wrap">
                          {reference["tags"].map((tag, i) => {
                            return <div key={i}>{tag}</div>;
                          })}
                        </div>
                        {/* <button className="ml-auto">Edit</button> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditCve;
